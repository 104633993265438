import Container from '@mui/material/Container'
import React from 'react'
import ProductForm from '../../components/employee/ProductForm'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * Represents a screen that allows the user to
 * create a new package.
 * @returns {JSX.Element}
 */
const CreatePackageScreen = () => {
  return ( <View>
      <Container maxWidth='lg'>
        <Header title='Create Product'
        breadcrumbs={[
          {
              title: 'Home',
              path: '/'
          },
          {
              title: 'Manage Products',
              path: '/employee/products'
          },
          {
            title: 'New Product',
            path: '/employee/products/create'
          }
      ]}
        />
        <br/>
        <ProductForm/>
      </Container>
    </View>
  )
}


export default CreatePackageScreen