import React from 'react'
import View from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import ProductCategoryForm from "../../components/developer/ProductCategoryForm";
import {getGlobalConfig} from "../../api/Api";
import {
    HttpErrorFromJSON,
    ResponseCategoryFromJSON,
    Category,
    CategoriesApi
} from "ftm-api-client";
import {useDialog} from "../../components/global/DialogProvider";
import {useNavigate} from "react-router-dom";

const CategoryAddScreen = () => {

    const [categoryNameToCategory, setCategoryNameToCategory] = React.useState<any>({})

    const dialogue = useDialog();

    const navigate = useNavigate();

    const handleSubmit = async (newCategory: Category) => {
        try {
            const response = await categoriesApi.addCategory({
                category: newCategory
            })
            if (response && response.pid) {
                dialogue.openConfirm({
                    title: 'Category Created Successfully',
                    message: 'The category has been successfully created.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/categories');
                    }
                })
            }
        } catch (error: any) {

            const er = await error.response.json()
            const errResponse = HttpErrorFromJSON(er);

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: errResponse ? errResponse.userMessage : "An unknown error occurred. Please wait 5 minutes and re-attempt your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    const categoriesApi = new CategoriesApi(getGlobalConfig())

    const fetchCategories = async () => {
        const data = await categoriesApi.getCategories({})
        if (data) {
            const categoryResponse = ResponseCategoryFromJSON(data);
            if (categoryResponse && categoryResponse.data) {
                let newCategoryNameToCategory: any = {}
                categoryResponse.data.map((category: any) => {
                    newCategoryNameToCategory[category.name] = category
                })
                setCategoryNameToCategory(newCategoryNameToCategory)
            }
        }
    }

    React.useEffect(() => {
        fetchCategories();
    }, [])

    return (<View>
        <Container maxWidth={'lg'}>
            <Header
                title={'Add Category'}
                breadcrumbs={[
                    {
                        "title": "Home",
                        "path": "/"
                    },
                    {
                        "title": "Product Directory",
                        "path": "/categories"
                    },
                    {
                        "title": "Add Category",
                        "path": "/categories/add"
                    }
                ]}
            />
            <br/>
            <ProductCategoryForm
                productCategoryNameToProductCategory={categoryNameToCategory}
                onSubmit={handleSubmit}
            />
        </Container>
    </View>)

}

export default CategoryAddScreen;