import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useEffect } from 'react'
import View from '../../components/global/Page'
import Header from '../../components/global/Header'
import { useLocation } from 'react-router-dom'
import CloudDownload from '@mui/icons-material/CloudDownload'
import Download from '@mui/icons-material/Download'
import Info from '@mui/icons-material/Info'
import ErrorOutlineTwoTone from '@mui/icons-material/ErrorOutlineTwoTone'

/**
 * Represents a view that displays photos within
 * a gallery.
 * @returns {JSX.Element}
 */
const UserGalleryViewScreen = () => {

    /**
     * Represents the current path.
     */
    const location = useLocation();

    const [gallery, setGallery] = React.useState<any | undefined>(undefined);

    const mobileResponsive = useMediaQuery('(max-width:875px)');

    /**
     * Fetches the data from the server instance.
     */
    const fetchData = async () => {
        
    }

    useEffect(() => {
    }, [])

      const renderEmpty = () => (
        <Box textAlign='center' sx={{opacity: 0.5}}>
        <ErrorOutlineTwoTone fontSize='large'/>
        <Typography variant='h5' fontWeight={600}>Nothing to see here...</Typography>
        <Typography color='text.secondary'>This gallery appears to be empty. Check back later or ask the owner to share the photos!</Typography>
        </Box>
      )

  return (
      <View>
          <Container maxWidth='lg'>
              <Header
                title="Viewing Gallery"
                breadcrumbs={[
                    {
                        title: 'Home',
                        path: '/'
                    },
                    {
                        title: 'Galleries',
                        path: '/galleries'
                    },
                    {
                        title: 'Gallery',
                        path: `/galleries/${location.pathname.split('/')[2]}`
                    }
                ]}
                action={
                    <Button variant='contained' startIcon={<CloudDownload/>}>
                        Download
                    </Button>
                }
                />
                <br/>
                {!gallery?.photos?.length ? renderEmpty() : <ImageList cols={mobileResponsive ? 1 : 3} gap={50}>
                {gallery.photos!.map((photo: any) => (
                    <ImageListItem key={photo.title}>
                    <img
                        src={`${photo.url}`}
                        srcSet={`${photo.url}`}
                        alt={photo.title}
                        loading="lazy"
                    />
                    <ImageListItemBar
                      title={photo.title}
                      subtitle={'6 September, 2022'}
                      actionIcon={
                        <Tooltip title='Download this photo'>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`info about photo`}
                        >
                          <Download />
                        </IconButton>
                        </Tooltip>
                      }
                    />
                    </ImageListItem>
                ))}
                </ImageList>}
          </Container>
      </View>
  )
}


export default UserGalleryViewScreen