import React, {ReactNode} from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import ApiAdapter from '../../api/ApiAdapter';
import debounce from '@mui/material/utils/debounce';
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LanguageTwoTone from '@mui/icons-material/LanguageTwoTone'

/**
 * This is an auto-completing text field that allows users to search data
 * sets to fetch data on-demand versus retrieving all data and storing
 * in state. When the user inputs a search query, requests will be fired
 * off at a limited rate (to avoid flooding the backend) using the
 * API instance provided and results displayed in the autocomplete.
 * @param props
 * @author chrisrinaldi
 * @date 22 October, 2022
 * @return {JSX.Element}
 */
const AutocompleteSearch = (props: AutocompleteSearchProps): JSX.Element => {

    /**
     * Checks the API client provided has been instantiated.
     */
    const apiClient = props.apiInstance instanceof ApiAdapter ? props.apiInstance : null;

    /**
     * The maximum number of results retrieveable at a time.
     */
    const queryLimit = props.limit ? props.limit : 25;

    /**
     * Represents the value currently selected.
     */
    const [value, setValue] = React.useState<any | undefined>(props.multiple ? [] : null)

    /**
     * Represents the options available.
     */
    const [options, setOptions] = React.useState<any[]>([]);

    /**
     * The value stored for the user's search query.
     */
    const [inputValue, setInputValue] = React.useState<string>('')

    /**
     * Represents whether data is being loaded from the API or not.
     */
    const [loading, setLoading] = React.useState<boolean>(false);

    /**
     * Memo-izes and throttles the request being sent to the API client to avoid
     * flooding the backend with requests.
     */
    const fetch = React.useMemo(
        () =>
          debounce(
            (
              request: { input: string },
              callback: (results?: any) => void,
            ) => {
              requestData(request.input)
            },
            400,
          ),
        [],
    );

    React.useEffect(() => {
        let active = true;
    
        if (inputValue === '') {
          setOptions(value ? [value] : []);
          return undefined;
        }
    
        fetch({ input: inputValue }, (results?: any) => {
          if (active) {
            let newOptions: any[] = [];
    
            if (value) {
              newOptions = [value];
            }
    
            if (results) {
              newOptions = [...newOptions, ...results];
            }
    
            setOptions(newOptions);
          }
        });
    
        return () => {
          active = false;
        };
      }, [value, inputValue, fetch]);

    /**
     * Sends data to the api client instance.
     * @param searchText 
     */
    const requestData = async (searchText: string) => {
        setLoading(true);
        const searchField = props.field ? props.field : "name"
        const data = await apiClient?.find({
            q: `{"${searchField}": {"$regex": "${searchText}", "$options": "i"}}`,
            limit: queryLimit,
            sort: `^${searchField}`
        })
        if (data) setOptions(data);
        else setOptions([]);
        setLoading(false);
    }


    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option: any) =>
                option.name
            }
            multiple={props.multiple ? props.multiple : false}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            loading={loading}
            noOptionsText={'No results found'}
            isOptionEqualToValue={(option: any, value: any) => option.pid === value.pid}
            value={value}
            onChange={(event: any, newValue: any | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if (props.onValueChange) props.onValueChange(newValue)
            }}
            onInputChange={(event: any, newInputValue: any) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder="Type a search..." fullWidth {...props.textFieldProps} />
            )}
            renderOption={(props, option: any) => {
        
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <LanguageTwoTone sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant='body2'>
                            <strong>{option.name}</strong>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {option.description ? (option.description.length > 100 ? option.description.substring(0, 100) + '...' : option.description) : "No description provided."}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            {...props.props}
        />
    );
}

interface AutocompleteSearchProps {


    /**
     * Represents the API instance which should control the responses
     * retrieved.
     */
    apiInstance: ApiAdapter,

    /**
     * Represents the field being used to search.
     */
    field?: string,

    /**
     * Dictates what the label should be for the specified result set.
     * @param option 
     * @returns 
     */
    getOptionLabel?: (option: any) => string,

    /**
     * A callback to be invoked when the value of the selected item
     * changes.
     * @param newValue 
     * @returns 
     */
    onValueChange?: (newValue: any) => any,

    /**
     * Represents the maximum amount of results that can be returned.
     */
    limit?: number,

    /**
     * Whether to allow multiple select values.
     */
    multiple?: boolean,

    /**
     * The props to be passed to the MUI Autocomplete component.
     */
    props?: any,

    /**
     * Props to be passed to the TextField input.
     * @param params 
     * @returns 
     */
    textFieldProps?: TextFieldProps,
}

export default AutocompleteSearch;