import React, {useState} from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LoadingButton from '@mui/lab/LoadingButton'
import InputField from './InputField'
import FilePicker from './FilePicker'
import DialogActions from '@mui/material/DialogActions'
import MenuItem from '@mui/material/MenuItem'
import {UsersApiAdapter} from "../../api/impl/adapters";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

/**
 * Allows the user to report a bug.
 * @returns {JSX.Element}
 */
const BugReportForm = () => {

    /**
     * The types of bugs that the user can select from.
     */
    const bugReportTypeOptions = [
        "Graphical/UI Error",
        "Functional Error",
        "Server Error",
        "Other"
    ]

    /**
     * Represents the hook into the formik functionality.
     */
    const formik = useFormik({
        isInitialValid: false,
        initialValues: {
            subject: '',
            bugReportType: "none",
            message: "",
            submit: null
        },
        validationSchema: Yup.object().shape({
            subject: Yup
                .string()
                .min(5, 'Subject must be > 5 characters in length')
                .max(255, "Subject cannot be > 255 characters in length")
                .required('Subject is required'),
            message: Yup
                .string()
                .min(25, "Must be >= 25 characters in length")
                .max(500)
                .required("Must enter a message!"),
            bugReportType: Yup
                .string()
                .oneOf(bugReportTypeOptions)
                .required("Please select an issue type")
        }),
        onSubmit: async (values, helpers) => {
            try {
                await usersApiAdapter.submitUserContact(
                    {
                        issueType: values.bugReportType,
                        message: values.message,
                        imagePid: "",
                        subject: values.subject
                    }
                )
                setAlertOpen('success');
                window.scrollTo(0, 0);
                helpers.resetForm();
            } catch (err: any) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
                window.scrollTo(0, 0);
                setAlertOpen('An error occurred while processing your request. Please try again in 5 minutes.');
            }
        }
    });

    const usersApiAdapter = new UsersApiAdapter();

    const [alertOpen, setAlertOpen] = useState<string | null>();

    const handleClose = () => {
        setAlertOpen(null);
    }

    return (
        <Box>
            {alertOpen && <Box my={2}>
                <Alert severity={alertOpen === 'success' ? 'success' : 'error'} onClose={handleClose}>
                    {alertOpen === 'success' ? 'Your bug report has been received and our development team has been notified. Please allow at least 48 hours for remediation steps to take place. We will reach out for further details if necessary.' :
                    alertOpen}
                </Alert>
            </Box>}
            <br/>
            <Card>
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <InputField
                            sx={{ mb: 3 }}
                            placeholder="Subject"
                            variant='filled'
                            error={Boolean(formik.touched.subject && formik.errors.subject)}
                            fullWidth
                            helperText={formik.touched.subject && formik.errors.subject}
                            label="Subject"
                            name="subject"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.subject}
                        />
                        <InputField
                            fullWidth
                            sx={{ mb: 3 }}
                            onChange={formik.handleChange}
                            label="Issue Type"
                            select
                            name="bugReportType"
                            error={Boolean(formik.touched.bugReportType && formik.errors.bugReportType)}
                            value={formik.values.bugReportType}
                        >
                            <MenuItem
                                key="none"
                                value="none"
                            >Select an issue type</MenuItem>
                            {bugReportTypeOptions.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </InputField>
                        <InputField
                            variant='filled'
                            sx={{ mb: 5 }}
                            error={Boolean(formik.touched.subject && formik.errors.subject)}
                            fullWidth
                            multiline
                            minRows={7}
                            placeholder="Enter a brief description of the bug, including steps to reproduce and any additional information."
                            helperText={formik.touched.message && formik.errors.message}
                            label="Message"
                            name="message"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                        />
                        <FilePicker />
                        <DialogActions>
                            <LoadingButton
                                color="primary"
                                type="submit"
                                disabled={!formik.isValid}
                                variant="contained"
                                loading={formik.isSubmitting}
                            >
                                Send Report
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </CardContent>
            </Card>
        </Box>
    )
}

interface BugReportFormProps {

}

export default BugReportForm