import React from 'react'
import View from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from '../../components/global/Header'
import BugReportForm from '../../components/global/BugReportForm'

/**
 * Allows users to contact support via suggestion form or bug report.
 * @returns {JSX.Element}
 */
const ContactUsScreen = () => {
  return (
    <View>
        <Container maxWidth='lg'>
            <Header 
                title={'Report a Bug'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: '/'
                    },
                    {
                        title: "Report a Bug",
                        path: "/bug-report"
                    }
                ]}
            />
            <BugReportForm/>
        </Container>
    </View>
  )
}

export default ContactUsScreen