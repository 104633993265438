import Container from '@mui/material/Container'
import React from 'react'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * The CreateGalleryScreen is a UI page that allows employees and developers
 * to create galleries. Provides interactive controls to modify the title,
 * description, and the images in the gallery as well as the users
 * who can view the gallery.
 * @author chrisrinaldi
 * @date 28 March, 2022
 * @returns {JSX.Element}
 */
const CreateGalleryScreen = () => {

  const [gallery, setGallery] = React.useState<any | null>(null)
  const [awaitingResponse, setAwaitingResponse] = React.useState<boolean>(false);


  return ( 
  <View>
      <Container maxWidth='lg'>
          <Header 
            title="Create Gallery" 
            breadcrumbs={[
                {
                    title: 'Home',
                    path: '/'
                },
                {
                    title: 'Galleries',
                    path: '/galleries'
                },
                {
                    title: 'Create Gallery',
                    path: '/employee/gallery/create'
                }
            ]}
            />
            <br/>
            <br/>
      </Container>
  </View>
  )
}

export default CreateGalleryScreen