import { Configuration, DefaultConfig } from "ftm-api-client";
import Config from '../application/util/Configuration';


/**
 * Represents the global configuration attributes.
 */
export const globalConfiguration = {
                     ...DefaultConfig,
                     basePath: Config.UsingProductionMode ? Config.ProductionBasePath : Config.DeveloperBasePath,
                     accessToken: localStorage.getItem('token') || 'unauthorized'
}

/**
 * Retrieves the global config.
 * @returns {object} the global config
 */
export const getGlobalConfig = () => {
    return new Configuration({
        ...DefaultConfig,
        basePath: Config.UsingProductionMode ? Config.ProductionBasePath : Config.DeveloperBasePath,
        accessToken: localStorage.getItem('token') || 'unauthorized'
    })
}

/**
 * Represents an instantiation of the global configuration.
 */
export const defaultConfig = new Configuration(globalConfiguration)