import Container from "@mui/material/Container";
import Header from "../../components/global/Header";
import View from "../../components/global/Page";
import React, {useState} from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputField from "../../components/global/InputField";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowPrevious from '@mui/icons-material/ArrowBack'
import ArrowBack from '@mui/icons-material/ArrowBack'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {FtmTasksApiAdapter} from "../../api/impl/adapters";
import {useSnackbar} from "notistack";
import LoadingSection from "../../components/global/LoadingSection";
import {
    FtmTask,
    GetTaskAssignmentRequest,
    GetTaskAssignmentTargetApplicationEnum,
    GetTaskAssignmentTaskStatusEnum,
    KeyValueRecord
} from "ftm-api-client";
import Refresh from "@mui/icons-material/Refresh";
import {ThumbDown, ThumbUp} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const DataQueueScreen = () => {

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'firstName',
            headerName: 'First name',
            width: 150,
            editable: true,
            resizable: true
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 150,
            editable: true,
            resizable: true
        },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            editable: true,
            resizable: true
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
        },
    ];

    const [assignedTask, setAssignedTask] = useState<FtmTask>();
    const [loading, setLoading] = useState(false);
    const [exampleDataColumns, setExampleDataColumns] = useState([]);
    const [totalTasks, setTotalTasks] = useState<number>(0);

    const [assignmentRequest, setAssignmentRequest] = useState<GetTaskAssignmentRequest>(
        {
            offset: 0,
            targetApplication: undefined,
            taskStatus: undefined,
            taskType: undefined
        }
    );

    const tasksApiInstance = new FtmTasksApiAdapter();

    const {enqueueSnackbar} = useSnackbar();

    const handleOutValueSet = () => {
        try {
            // await tasksApiInstance.patch();
        } catch (err) {
            enqueueSnackbar("An error occurred while attempting to complete the task.", {variant: "error"});
        }
    }

    const handleNavigate = async (next=true) => {
        if (!next && assignmentRequest.offset === 0 || next && assignmentRequest.offset === totalTasks) return;
        return await fetchTask({offset: next ? assignmentRequest.offset! + 1 : assignmentRequest.offset! - 1});
    }

    const fetchTask = async (partialRequest: GetTaskAssignmentRequest = assignmentRequest) => {
        try {
            if (!loading) setLoading(true);

            // Update the request, then set state to "remember".
            let newRequest: GetTaskAssignmentRequest = {...assignmentRequest};
            for (const key of Object.keys(partialRequest)) {
                // @ts-ignore
                newRequest[key] = partialRequest[key];
            }

            setAssignmentRequest(newRequest);
            const {task, total} = await tasksApiInstance.getTaskAssignment(
                newRequest
            );

            if (task) {
                setAssignedTask(task);
            } else setAssignedTask(undefined);
            if (total || typeof total === 'number') {
                setTotalTasks(total);
            }
        } catch (err: any) {
            enqueueSnackbar("An error occurred.", {variant: 'error'});
        }
        setLoading(false);
    }

    React.useEffect(() => {
        fetchTask();
    }, []);

    return (
        <View>
            <Container maxWidth={'lg'}>
                <Header
                    title={'Task Queue'}
                    action={<Typography variant={'h6'}>{assignmentRequest.offset! + 1} of {totalTasks}</Typography>}
                    breadcrumbs={[
                        {
                            "title": "Home",
                            "path": "/"
                        },
                        {
                            "title": "Data Queue",
                            "path": "/queue"
                        }
                    ]}
                />
                <br/>
                <Box mb={4}>
                    <InputField
                        fullWidth
                        // disabled={loading || !loading && !assignedTask}
                        disabled
                        placeholder={'Search tasks...'}
                    />
                </Box>
                <Typography component={'p'} variant={'body2'} color={'textSecondary'} fontWeight={700} gutterBottom>
                    Filter
                </Typography>
                <Grid mb={4} mt={2} container justifyContent={'space-between'}>
                    <Grid item>
                        <InputField
                            select
                            disabled={loading}
                            defaultValue={"null"}
                            onChange={async (e: any) => {
                                await fetchTask({"taskType": e.target.value !== "null" ? e.target.value : undefined})
                            }}
                            sx={{minWidth: 200}}
                        >
                            <MenuItem value={"null"}>Filter by task type...</MenuItem>
                            <MenuItem value={'DatasetReview'}>DatasetReview</MenuItem>
                        </InputField>
                    </Grid>
                    <Grid item>
                        <InputField
                            select
                            disabled={loading}
                            defaultValue={"null"}
                            onChange={async (e: any) => {
                                await fetchTask({"targetApplication": e.target.value !== "null" ? e.target.value : undefined})
                            }}
                            sx={{minWidth: 200}}
                        >
                            <MenuItem value={"null"}>Filter by target application...</MenuItem>
                            {(Object.keys(GetTaskAssignmentTargetApplicationEnum) as Array<keyof typeof GetTaskAssignmentTargetApplicationEnum>).map((key) => (
                                <MenuItem value={GetTaskAssignmentTargetApplicationEnum[key]}>{key}</MenuItem>
                            ))}
                        </InputField>
                    </Grid>
                </Grid>
                <Grid mb={4} container justifyContent={'space-between'}>
                    <Grid item>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox />}
                            label="Show Completed"
                        />
                    </Grid>
                </Grid>
                {!loading && assignedTask ? <Box>
                    <Box
                        mb={4}
                    >
                        <Box mb={2}>
                            <Typography
                                variant={'h6'}
                            >
                                Task Type
                            </Typography>
                        </Box>
                        <Chip color={'secondary'} label={assignedTask.taskType}/>
                    </Box>
                    <Box
                        mb={4}
                    >
                        <Typography
                            variant={'h6'}
                        >
                            Task Instructions
                        </Typography>
                        <Typography variant={'body2'} color={'text.secondary'}>
                            {assignedTask.taskDescription}
                        </Typography>
                    </Box>
                    <Typography
                        variant={'h6'}
                        gutterBottom
                    >
                        Task Information
                    </Typography>
                    {!assignedTask.taskMetadata || !assignedTask.taskMetadata.length ? 'No information provided.' : <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignedTask.taskMetadata && assignedTask.taskMetadata.map((meta: KeyValueRecord) => (
                                    <TableRow key={meta.name}>
                                        <TableCell>{meta.name}</TableCell>
                                        <TableCell>{meta.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    <br/>
                    <Typography
                        gutterBottom
                        variant={'h6'}
                    >
                        Example Data
                    </Typography>
                    {assignedTask.dataExample && exampleDataColumns && exampleDataColumns.length ? <Paper>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            checkboxSelection
                            autoHeight
                        />
                    </Paper> : <Typography variant={'body2'} color={'text.secondary'}>No example data available.</Typography>}
                    <Typography variant={'h6'} mt={5}>Approve or Deny</Typography>
                    <Stack mt={2} direction={'row'} spacing={2}>
                        <Tooltip title={'Approve Dataset'}>
                            <IconButton><ThumbUp/></IconButton>
                        </Tooltip>
                        <Tooltip title={'Reject Dataset'}>
                            <IconButton><ThumbDown/></IconButton>
                        </Tooltip>
                    </Stack>
                </Box> : !loading && !assignedTask ?
                    <Box textAlign={'center'} marginY={20}>
                        <CheckCircle/>
                        <Typography gutterBottom variant={'h6'}>All Tasks Completed</Typography>
                        <Typography gutterBottom variant={'body2'} color={'text.secondary'}>You've completed all available tasks. Please check back later for more tasks.</Typography>
                        <br/>
                        <Button onClick={() => { fetchTask({offset: 0}) }} variant={'contained'} startIcon={<Refresh/>}>Refresh</Button>
                    </Box>
                    : <LoadingSection/>}
                <Grid mt={10} container justifyContent={'space-between'}>
                    <Grid item>
                        <Button disabled={assignmentRequest.offset === 0 || loading || !loading && !assignedTask} color={'secondary'} variant={'contained'} onClick={async () => { await handleNavigate(false) }}>Previous</Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!totalTasks || ((assignmentRequest.offset! + 1) === totalTasks) || loading || (!loading && !assignedTask)} color={'secondary'} onClick={async () => { await handleNavigate(true) }} variant={'contained'}>Next</Button>
                    </Grid>
                </Grid>
            </Container>
        </View>
    );
}

export default DataQueueScreen;