import React from 'react'
import {getGlobalConfig} from "../../api/Api";
import {HttpErrorFromJSON, IndustriesApi, Industry, ResponseError} from "ftm-api-client";
import View from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import IndustryForm from "../../components/developer/IndustryForm";
import {useNavigate} from "react-router-dom";
import {useDialog} from "../../components/global/DialogProvider";

/**
 * Represents a screen allowing development users to add a new industry.
 * @constructor
 */
const IndustryAddScreen = () => {

    /**
     * Represents the Industries api instance.
     */
    const industriesApi = new IndustriesApi(getGlobalConfig());

    /**
     * Represents whether the form is loading.
     */
    const [isFormLoading, setIsFormLoading] = React.useState<boolean>(false)

    /**
     * Represents the react-router-dom navigation hook.
     */
    const navigate = useNavigate()

    /**
     * Represents the dialog controller.
     */
    const dialogue = useDialog();

    /**
     * Handles action taken when a new industry is submitted.
     * @param newIndustry
     */
    const handleIndustrySubmit = async (newIndustry: Industry): Promise<void> => {
        setIsFormLoading(true);
        try {
            const response = await industriesApi.addIndustry({
                industry: newIndustry
            })
            if (response && response.pid) {
                setIsFormLoading(false);
                dialogue.openConfirm({
                    title: 'Industry Added',
                    message: 'The industry has been successfully added.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/industries')
                    }
                })
            }
        } catch (e: any) {
            setIsFormLoading(false);
            const newError = await e.response.json();
            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: `${HttpErrorFromJSON(newError).userMessage}`,
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    return (<View>
        <Container maxWidth={'lg'}>
            <Header
                title={'Create Industry'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Industries",
                        path: "/industries"
                    },
                    {
                        title: "Create Industry",
                        path: "/industries/create"
                    },
                ]}
            />
            <br/>
            <IndustryForm
                onSubmit={handleIndustrySubmit}
                loading={isFormLoading}
            />
        </Container>
    </View>)

}

export default IndustryAddScreen;