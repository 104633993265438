import ArrowRight from '@mui/icons-material/ArrowRight'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import React, { useEffect } from 'react'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'
import Authentication from '../../api/Authentication'
import { useDialog } from '../../components/global/DialogProvider'
import { useSnackbar } from 'notistack'
import { Configuration, User, UserProfile, UsersApi } from 'ftm-api-client'
import { globalConfiguration } from '../../api/Api'
import {useAppSelector} from "../../application/redux/hooks";
import useTheme from "@mui/material/styles/useTheme";
import NativeSelect from "@mui/material/NativeSelect";
import Config from '../../application/util/Configuration'

/**
 * Represents a screen that allows a user to
 * specify various different settings for
 * their account and the application.
 * @returns {JSX.Element}
 */
const UserSettingsScreen = () => {

    function saveFile() {
        const filename = "Analytix.url"; // This could be a .url, .desktop, or any other format
        const content = `
    [InternetShortcut]
    URL=https://analytix-ai.com
    IconIndex=0
    IconFile=https://analytix-ai.com/favicon.ico
  `;

        // Create a blob with the file content
        const blob = new Blob([content], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Append the link to the body and click to download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    }

    /**
     * Represents the users API controller.
     */
    let usersApi = new UsersApi(new Configuration(globalConfiguration))

    /**
     * Represents the active user.
     */
    const [user, setUser] = React.useState<UserProfile | null>(null);

    /**
     * Represents the react hook to enqueue a snackbar.
     */
    const { enqueueSnackbar } = useSnackbar();

    const { profile } = useAppSelector(state => state.profile)

    const currentTheme = useTheme();

    /**
     * Represents the dialog controller.
     */
    const dialogue = useDialog();

    /**
     * Represents the styling.
     */
    const styles = {
        grid: {
            padding: '15px'
        }
    }

  return (
     <View>
          <Container maxWidth='lg'>
              <Header
                title="Settings"
                breadcrumbs={[
                    {
                        title: 'Home',
                        path: '/'
                    },
                    {
                        title: 'Settings',
                        path: '/settings'
                    }
                ]}
              />
              <br/>
              {/* <Typography sx={{mb: '10px'}} fontWeight={'700'} variant="body2" color="text.secondary">
                  Security
              </Typography> */}
          <Card variant='outlined'>
              <CardContent>
              <Typography fontWeight='600' gutterBottom variant="h6" component="div" color="primary">
                    Account security
              </Typography>
              <Typography variant="body2" color="text.secondary">
                  Never share your password with anyone. Your photos are accessible only to those with access to your account.
              </Typography>
              </CardContent>
              <CardActions>
                  <Button onClick={() => {
                      dialogue.openMessage({
                            title: 'Account Security',
                            message: 'We do our part to ensure that your content is safe and secure from others. Because of this, we encourage you not to share your password with anyone, as your photos are accessible to anyone with access to your account.',
                            confirmText: 'Got it',
                      })
                  }}>LEARN MORE</Button>
              </CardActions>
          </Card>
          <br/>
          <Typography sx={{mb: '10px'}} fontWeight={'700'} variant="body2" color="text.secondary">
                  Personal Info
              </Typography>
          <Card variant='outlined'>
              <CardContent sx={{padding: 0}}>
                <Grid sx={styles.grid} container alignItems={'center'} justifyContent='space-between'>
                    <Grid item>
                        <Grid direction='row' container columnSpacing={1}>
                        <Grid item>
                            <Avatar src={profile?.imgUrl}>
                                {profile && profile.firstName && profile.lastName && (profile.firstName.charAt(0) + profile!.lastName.charAt(0))}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography>{profile?.firstName + ' ' + profile?.lastName}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Typography>{profile?.email}</Typography>
                        </Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title={Config.AuthenticationMethod !== 'mongo' ? "This setting is managed by your organization." : "Edit your name and avatar"}>
                            <span>
                            <Button disabled={Config.AuthenticationMethod !== 'mongo'} onClick={() => {
                                dialogue.openMessage({
                                    title: 'Edit Personal Info',
                                    maxWidth: 'md',
                                    message: 'You may change your first and last name or avatar below.',
                                    element: <FormControl fullWidth sx={{pr: 2, pl: 2, pb: 2}}>
                                        <FormLabel>First Name</FormLabel>
                                        <TextField defaultValue={profile?.firstName} variant="outlined" placeholder='First name' fullWidth/>
                                        <br/>
                                        <FormLabel>Last Name</FormLabel>
                                        <TextField
                                            defaultValue={profile?.lastName}
                                            variant="outlined" placeholder="Last name" fullWidth/>
                                        <br/>
                                        </FormControl>,
                                })
                            }}>MODIFY</Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid sx={styles.grid} container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography>Phone Number</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {profile?.phone !== '' && profile?.phone !== undefined ? profile!.phone : 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <ArrowRight/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid sx={styles.grid} container justifyContent='space-between'>
                    <Grid item>
                        <Typography>Street Address</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {profile?.addressStreet1 !== '' ? profile?.addressStreet1 : 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <ArrowRight/>
                        </IconButton>
                    </Grid>
                </Grid>
              </CardContent>
          </Card>
          <br/>
          <Typography sx={{mb: '10px'}} fontWeight={'700'} variant="body2" color="text.secondary">
                  Appearance
              </Typography>
          <Card variant='outlined'>
              <CardContent sx={{padding: 0}}>
                {/* <Typography fontWeight='600' gutterBottom variant="h6" component="div" color="primary">
                    Appearance
                </Typography> */}
                <Grid sx={styles.grid} container justifyContent='space-between'>
                    <Grid item>
                        <Typography>Shortcut</Typography>
                        <Typography variant="body2" color="text.secondary">
                            Save shortcut to desktop
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Save a shortcut to your desktop">
                        <IconButton onClick={saveFile}>
                            <ExitToApp/>
                        </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid sx={styles.grid} container justifyContent='space-between'>
                    <Grid item>
                        <Typography>Toggle dark mode</Typography>
                        <Typography variant="body2" color="text.secondary">
                            On/off
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Switch checked={currentTheme.palette.mode === 'dark'}/>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid sx={styles.grid} container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography>Font size</Typography>
                    </Grid>
                    <Grid item>
                        <NativeSelect defaultValue={'regular'} size='small' variant='standard' sx={{width: '300px'}}>
                            <option value={'regular'}>Regular</option>
                        </NativeSelect>
                    </Grid>
                </Grid>
              </CardContent>
              <CardActions>
              </CardActions>
          </Card>
          </Container>
      </View>
  )
}


export default UserSettingsScreen