import React from 'react'
import Authentication from '../../api/Authentication'

/**
 * The Show component is a component that will render a component
 * if and only if the user's privileges match a given privilege in
 * the 'array' props.
 * @returns 
 */
const Show = (props: ShowProps, children: Array<React.ReactNode>) => {

  return (
      <>
        {props.privileges?.includes(Authentication.getPrivileges()) ? (props.children) : null}
      </>
  )
}

/**
 * Represents the type for this component's
 * props.
 */
interface ShowProps {
    privileges?: Array<string>,
    children?: React.ReactNode
}

export default Show