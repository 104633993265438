import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import View from '../../components/global/Page'
import Container from "@mui/material/Container";
import Header from "../../components/global/Header";
import {
    Configuration, HttpErrorFromJSON,
    IndustriesApi, Industry,
    Organization,
    OrganizationsApi, PatchDocumentFromJSON, ResponseFromJSON, ResponseIndustryFromJSON,
    ResponseOrganizationFromJSON
} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import {useDialog} from "../../components/global/DialogProvider";
import AddCircle from '@mui/icons-material/AddCircle'
import AutocompleteSearch from "../../components/global/AutocompleteSearch";
import {useSnackbar} from "notistack";

const OrganizationDetailScreen = () => {

    const params = useParams();
    const config = new Configuration(getGlobalConfig())
    const OrganizationApiInstance = new OrganizationsApi(config)
    const IndustriesApiInstance = new IndustriesApi(config)
    const [organization, setOrganization] = React.useState<Organization | undefined>()
    const [activeTab, setActiveTab] = React.useState('overview')

    /**
     * Retrieves all industries given from the Organization's industry PID list. Cycles through
     * any PID filter groupings 50 at a time.
     * @param setValue
     * @param setOptions
     * @param qFilters
     * @param qFilterIndex
     * @param industries
     */
    const retrieveDefaultIndustries = async (qFilters: string[], qFilterIndex: number=0, industries: Industry[] = []) => {
        if (qFilterIndex >= qFilters.length) {
            return industries;
        }
        let response = await IndustriesApiInstance.getIndustries({
            q: qFilters[qFilterIndex]
        })
        if (response) {
            const industryResponse = ResponseIndustryFromJSON(response)
            if (industryResponse.data) {
                const newIndustries = [...industries,  ...industryResponse.data]
                await retrieveDefaultIndustries(qFilters, qFilterIndex + 1, newIndustries);
            }
        } else return []
    }

    React.useEffect(() => {
        OrganizationApiInstance.getOrganization({pid: params.organizationPid!}).then((result) => {
            const orgResponse = ResponseOrganizationFromJSON(result);
            if (orgResponse.data && orgResponse.data.length > 0) {
                setOrganization(orgResponse.data[0]);
            }
        }, (error) => {})
    }, [])

    const dialogue = useDialog();

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteOpen = () => {
        dialogue.openConfirm({
            title: 'Delete Organization',
            message: 'Are you sure you wish to delete this organization?',
            onConfirm: async () => {
                try {
                    const data = await OrganizationApiInstance.deleteOrganization({pid: params.organizationPid!})
                    if (data) {
                        if (data.statusCode && data.statusCode == 200) {
                            enqueueSnackbar('Organization successfully deleted.')
                            navigate('/organizations')
                        }
                    }
                } catch (e: any) {
                    const errJson = e.response.json();
                    const err = HttpErrorFromJSON(errJson);
                    dialogue.openMessage({
                        title: 'An Error Occurred',
                        message: err.userMessage || "Please wait 5 minutes and re-attempt your request.",
                        onConfirm: () => {},
                        cancelText: 'Cancel',
                    })
                }
            },
            cancelText: 'Cancel',
        })
    }

    /**
     * Represents a function that produces results for the industry select.
     * @param name
     */
    const industrySelectProducerFunction = async (name: string) => {
        try {
            const response = await IndustriesApiInstance.getIndustries({
                q: `{"name": {"$regex": "${name}", "$options": "i"}}`
            })
            const results = ResponseIndustryFromJSON(response);
            if (results && results.data) return results.data;
            else return []
        } catch(error: any) {
            return [];
        }
    }

    const patchOrganizationIndustries = async (newIndustries: Industry[]) => {
        try {
            const industryPids = newIndustries.map(industry => industry.pid);
          await OrganizationApiInstance.patchOrganization({
              pid: params.organizationPid!,
              patchDocument: [
                  PatchDocumentFromJSON({
                      op: 'add',
                      path: '/industryPids',
                      value: industryPids
                  })
              ]
          })
        } catch (e) {

        }
    }

    return (
        <View>
            <Container>
                <Header
                    breadcrumbs={[
                        {
                            title: 'Home',
                            path: '/'
                        },
                        {
                            title: 'Organizations',
                            path: '/organizations'
                        },
                        {
                            title: organization ? organization.name : '',
                            path: `/organizations/${params.organizationPid}`
                        }
                    ]}
                    action={<Stack columnGap={2} direction={'row'}>
                        <Button onClick={handleDeleteOpen} variant={'contained'} startIcon={<Delete/>} color={'error'}>Delete Organization</Button>
                        <Button onClick={() => { navigate(`/organizations/${params.organizationPid}/edit`) }} variant={'contained'} startIcon={<Edit/>} color={'secondary'}>Edit Organization</Button>
                        <Button startIcon={<AddCircle/>} variant={'contained'} color={'secondary'}>New Product</Button>
                    </Stack>}
                    title={organization?.name || ''}/>
                <br/>
                <Typography variant={'body2'} color={'textSecondary'}><strong>About Us</strong></Typography>
                {organization?.about}
                <br/>
                <br/>
                <Card variant={'outlined'}>
                    <TabContext value={activeTab}>
                        <Box pl={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, newValue) => { setActiveTab(newValue) }}>
                                <Tab label="Overview" value="overview" />
                                <Tab label="Products" value="tab3" />
                            </TabList>
                        </Box>
                        <TabPanel value="overview">
                            <Typography variant={'body2'} color={'secondary'}><strong>Description</strong></Typography>
                            <Typography variant={'body2'}>{organization?.description}</Typography>
                            <br/>
                            <Typography variant={'body2'} color={'secondary'}><strong>Address Info</strong></Typography>
                            <Card variant={'outlined'} style={{marginTop: '5px'}}>
                                <CardContent>
                                <Grid container rowGap={2}>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'} color={'textSecondary'}><strong>Street Address</strong></Typography>
                                            <Typography variant={'body2'}>{organization?.addressStreet}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'} color={'textSecondary'}><strong>City</strong></Typography>
                                            <Typography variant={'body2'}>{organization?.addressCity}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'} color={'textSecondary'}><strong>State</strong></Typography>
                                            <Typography variant={'body2'}>{organization?.addressState}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'} color={'textSecondary'}><strong>Zip</strong></Typography>
                                            <Typography variant={'body2'}>{organization?.addressZip as string}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                </CardContent>
                            </Card>
                        </TabPanel>
                        <TabPanel value="tab3">Products</TabPanel>
                    </TabContext>
                </Card>
            </Container>
        </View>
    )

}

export default OrganizationDetailScreen;