import React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import TextField from '@mui/material/TextField'
import AutocompleteSearch from '../global/AutocompleteSearch'
import { AttributesApiAdapter, OrganizationsApiAdapter, ProductTypesApiAdapter } from '../../api/impl/adapters'
import Typography from '@mui/material/Typography'
import { AttributeBooleanValueFromJSON, AttributeDropdownValueFromJSON, AttributeNumberValueFromJSON, AttributeRangeValueFromJSON, AttributeTextValueFromJSON, AttributeValue, AttributeValueFromJSON, Product, ProductFromJSON, ProductType } from 'ftm-api-client'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import AddCircle from '@mui/icons-material/AddCircle'
import FormHelperText from '@mui/material/FormHelperText'
import { useSelector } from 'react-redux'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { v4 as uuidv4 } from 'uuid'
import AttributeInputForm from '../developer/AttributeInputForm'

/**
 * Represents a form allowing a user to create a product based
 * on a product type.
 * @returns {JSX.Element}
 */
const ProductForm = (props: ProductFormProps): JSX.Element => {

    /**
     * Pulls the user's profile out of the Redux store so that the default value
     * of the company may be displayed in the box.
     */
    const userProfile = useSelector((state: any) => state.profile)

    /**
 * The organizations API, to designate which organization the product
 * should belong to.
 */
    const organizationsApi = new OrganizationsApiAdapter();

    /**
     * Allows the user to select from product types.
     */
    const productTypesApi = new ProductTypesApiAdapter();

    /**
     * Represents the instance of the Attributes ApiAdapter class.
     */
    const attributesApi = new AttributesApiAdapter();

    /**
     * Stores the state of the product.
     */
    const [product, setProduct] = React.useState<Product>(props.initialProduct ? props.initialProduct : ProductFromJSON({
        name: "",
        description: "",
        organizationPid: "none",
        attributeValues: [],
        productTypePid: ""
    }))

    /**
     * Mapping of organization names to organization objects.
     */
    const [organizationMap, setOrganizationMap] = React.useState<any>({})

    /**
     * Represents whether the product is valid.
     */
    const [productIsValid, setProductIsValid] = React.useState<boolean>(props.initialProduct ? true : false);

    /**
     * A mapping of UUID to attribute input.
     */
    const [pidToAttributeInput, setPidToAttributeInput] = React.useState<any>({})

    /**
     * Mapping of attribute PID to attribute.
     */
    const [attributePidToAttribute, setAttributePidToAttribute] = React.useState<any>({})

    /**
     * Attribute names to attributes.
     */
    const [attributeNameToAttribute, setAttributeNameToAttribute] = React.useState<any>({});

    /**
     * Mapping of attribute PID to values listed in the dropdown.
     */
    const [attributePidToDropDownValues, setAttributePidToDropDownValues] = React.useState<any>({})

    /**
     * Handles action when a field value changes from within the form.
     * @param fieldName 
     * @param newValue 
     */
    const handleFieldChanged = (fieldName: string, newValue: any) => {
        const newProduct = {...product, [fieldName]: newValue}
        if (fieldName === 'name') {
            checkProductValidity(newValue, pidToAttributeInput, product.productTypePid)
        }
        setProduct(newProduct);
    }

    /**
     * Handles the product type changing.
     * @param newProductType 
     */
    const handleProductTypeChanged = (newProductType: ProductType) => {
        let newProduct: Product = { ...product }
        if (newProductType) newProduct['productTypePid'] = newProductType.pid!
        else if (newProduct.productTypePid) newProduct.productTypePid = ""
        initAttributesForProductType(newProductType);
        setProduct(newProduct);
        checkProductValidity(newProduct.name, pidToAttributeInput, newProduct.productTypePid);
    }

    /**
     * Checks the validity of the product.
     * @param newUuidToAttributeValues uuid to attribute values
     */
    const checkProductValidity = (name: string, newUuidToAttributeValues: any = {}, productTypePid?: any) => {
        const isNameValid = name !== "";
        let isAttributeValid = true;
        Object.values(newUuidToAttributeValues).map((attributeValue: any) => {
            if (!attributeValue.attributeInputIsValid) isAttributeValid = false;
        })
        setProductIsValid(isNameValid && isAttributeValid && productTypePid);
    }

    /**
     * Handles action taken when a new attributeInput is added.
     */
    const handleNewAttributeInput = () => {
        let newAttributeUuidToValue: any = { ...pidToAttributeInput };
        newAttributeUuidToValue[uuidv4()] = {
            attributePid: 'none',
            value: {},
            attributeInputIsValid: false
        }
        setPidToAttributeInput(newAttributeUuidToValue);
        setProductIsValid(false);
    }

    /**
     * Handles action taken when an attribute input form value has changed.
     * @param attributeUuid 
     * @param newValue 
     */
    const handleAttributeInputChanged = (attributeUuid: string, newValue: AttributeValue) => {
        let newAttributeUuidToValue: any = { ...pidToAttributeInput };
        newAttributeUuidToValue[attributeUuid] = newValue;
        newAttributeUuidToValue[attributeUuid].attributeInputIsValid = newValue.attributePid !== 'none';
        setPidToAttributeInput(newAttributeUuidToValue);
        checkProductValidity(product.name, newAttributeUuidToValue, product.productTypePid);
    }

    /**
     * Handles action taken when an attribute input has been deleted.
     * @param attributeUuid the uuid of the attribute input
     */
    const handleAttributeInputDelete = (attributeUuid: string) => {
        let newAttributeUuidToValue: any = { ...pidToAttributeInput };
        delete newAttributeUuidToValue[attributeUuid];
        setPidToAttributeInput(newAttributeUuidToValue);
        checkProductValidity(product.name, newAttributeUuidToValue, product.productTypePid);
    }


    /**
     * Initializes attribute wrapper values, if any. Any attribute values marked as 'required' for the
     * given product type are automatically initialized into attribute inputs.
     */
    const initAttributesForProductType = async (productType: ProductType) => {
        let newAttributePidToAttribute: any = {}, newAttributePidToDropDownValues: any = {}, newPidToAttributeInput: any = {}, newAttributeNameToAttribute: any = {};
        if (!productType) return setPidToAttributeInput(newPidToAttributeInput);
        if (productType.attributeValues) {
            const attributePids = productType.attributeValues.map(attributeValue => attributeValue.attributePid);
            let attributes = await attributesApi.findByPids(attributePids);
            if (attributes) {
                attributes.map(attribute => {
                    newAttributePidToAttribute[attribute.pid] = attribute;
                    newAttributeNameToAttribute[attribute.name] = attribute;
                })
                productType.attributeValues.map(attributeValue => {
                    if (newAttributePidToAttribute[attributeValue.attributePid] && newAttributePidToAttribute[attributeValue.attributePid].type === "dropdown") {
                        newAttributePidToDropDownValues[attributeValue.attributePid] = attributeValue.value.options;
                    }
                })
                productType.attributeValues.map(attributeValue => {
                    if (attributeValue.isRequired) {
                        const uuid = uuidv4();
                        newPidToAttributeInput[uuid] = attributeValue;
                    }
                })
            }
        }
        setAttributePidToDropDownValues(newAttributePidToDropDownValues);
        setAttributeNameToAttribute(newAttributeNameToAttribute);
        setAttributePidToAttribute(newAttributePidToAttribute);
        setPidToAttributeInput(newPidToAttributeInput);
    }

    /**
     * Create an attribute input wrapper to store values of an attribute value in the input component.
     * @param attributePid 
     * @param newAttributePidToAttribute 
     * @param newAttributePidToDropDownValues 
     */
    const constructAttributeValue = (attributePid: string, attributePidToAttribute: any, newAttributePidToDropDownValues: any) => {
        if (attributePidToAttribute && attributePid in attributePidToAttribute) {
            switch (attributePidToAttribute[attributePid].type) {
                case 'text':
                    return AttributeTextValueFromJSON({
                        value: "",
                    })
                case 'dropdown':
                    if (newAttributePidToDropDownValues && attributePid in newAttributePidToDropDownValues) {
                        return AttributeDropdownValueFromJSON({
                            options: newAttributePidToDropDownValues[attributePid],
                            value: 'none'
                        })
                    }
                    return AttributeDropdownValueFromJSON({
                        options: [],
                        value: ""
                    });
                case 'range':
                    return AttributeRangeValueFromJSON({
                        minValue: 0,
                        maxValue: 1
                    });
                case 'number':
                    return AttributeNumberValueFromJSON({
                        numValue: 0
                    });
                case 'boolean':
                    return AttributeBooleanValueFromJSON({
                        value: true
                    });
            }
        }
    }

    /**
     * Initializes the organizations list.
     */
    const initOrganizations = async () => {
        const organizations = await organizationsApi.findAll();
        let organizationNameToOrganization: any = {}
        if (organizations) {
            organizations.map(organization => {
                organizationNameToOrganization[organization.name] = organization;
            })
        }
        setOrganizationMap(organizationNameToOrganization);
    }

    React.useEffect(() => {
        initOrganizations();
    }, [])

    return (
        <Card variant='outlined'>
            <CardContent>
                <Typography variant='body1'>Product</Typography>
                <Typography variant='body2' color='textSecondary'>Specify initial information about your product</Typography>
                <br />
                <TextField
                    error={product.name === ""}
                    variant='filled'
                    fullWidth
                    onBlur={(e) => { handleFieldChanged('name', e.target.value) }}
                    placeholder='Product name'
                />
                <br />
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AutocompleteSearch
                            onValueChange={handleProductTypeChanged}
                            props={{ fullWidth: true }}
                            textFieldProps={{ placeholder: "Search for product types...", error: !product.productTypePid, variant: 'filled' }}
                            apiInstance={productTypesApi} />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            fullWidth
                            variant='filled'
                            placeholder='Organization'
                            onBlur={(e) => { handleFieldChanged('organizationPid', e.target.value) }}
                            defaultValue={'none'}>
                            <MenuItem value={'none'}>Select from your organizations...</MenuItem>
                            {Object.keys(organizationMap).map(organizationName => (
                                <MenuItem key={organizationName} value={organizationMap[organizationName].pid}>{organizationName}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <br />
                <TextField
                    variant='filled'
                    fullWidth
                    onBlur={(e) => { handleFieldChanged('description', e.target.value) }}
                    placeholder='Description'
                    multiline
                    minRows={6}
                />
            </CardContent>
            <br />
            <Divider />
            <CardContent>
                <Typography>Attributes</Typography>
                <Typography variant='body2' color='textSecondary'>Specify attributes about your product. Users will be able to query by these.</Typography>
                <br />
                {Object.entries(pidToAttributeInput).map(([uuid, value]) => (
                    <>
                        <AttributeInputForm
                            key={uuid}
                            isProductForm={true}
                            attributeUuid={uuid}
                            attributePidToAttribute={attributePidToAttribute}
                            attributePidToDropdownValues={attributePidToDropDownValues}
                            attributeNameToAttribute={attributeNameToAttribute}
                            onDelete={handleAttributeInputDelete}
                            onChange={handleAttributeInputChanged}
                            initialAttributeValue={value}
                        />
                        <br />
                    </>
                ))}
                <Button
                    disabled={!product.productTypePid}
                    onClick={handleNewAttributeInput}
                    startIcon={<AddCircle />}
                    variant='contained'
                    color='secondary'>New Attribute</Button>
            </CardContent>
            <CardActions>
                <Button disabled={!productIsValid} fullWidth variant='contained'>
                    Submit
                </Button>
            </CardActions>
        </Card>
    )
}

interface ProductFormProps {

    /**
     * The initial product to be stored.
     */
    initialProduct?: Product,

    /**
     * Callback that is invoked once the user is satisfied with their
     * changes and clicks the submit button.
     * @param newProduct the new product
     * @returns any
     */
    onSubmit?: (newProduct: Product) => any,

}

export default ProductForm