import React from 'react'
import Card from '@mui/material/Card'
import CardContent from "@mui/material/CardContent";
import NativeSelect from '@mui/material/NativeSelect'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { AttributeBooleanValueFromJSON, AttributeDropdownValueFromJSON, AttributeNumberValueFromJSON, AttributeRangeValueFromJSON, AttributeTextValueFromJSON, AttributeValue, AttributeValueFromJSON } from "ftm-api-client";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'

/**
 * Represents a form in which users can input attributes on a particular
 * product type.
 * @param props
 * @constructor
 */
const AttributeInputForm = (props: AttributeInputFormProps) => {


    /**
     * Handles deletion of the attribute.
     */
    const handleAttributeInputDelete = () => {
        if (props.attributeUuid && props.onDelete) props.onDelete(props.attributeUuid);
    }

    /**
     * Handles action when the attribute name changes.
     * @param e 
     */
    const handleAttributeNameInputChanged = (e: any) => {
        if (props.attributeUuid && props.onChange) {
            const newAttributeValue = { attributePid: e.target.value, value: constructAttributeValue(e.target.value) };
            props.onChange(props.attributeUuid, newAttributeValue)
        }
    }

    /**
     * Given an attribute PID, constructs an attribute value for the respective attribute based
     * on its dataType. For a dropdown, if the prop isProductForm is specified, attempts to derive
     * the dropdown values from props.attributePidToDropdownValues.
     * @param attributePid represents the pid of the attribute.
     */
    const constructAttributeValue = (attributePid: string) => {
        if (props.attributePidToAttribute && attributePid in props.attributePidToAttribute) {
            switch (props.attributePidToAttribute[attributePid].type) {
                case 'text':
                    return AttributeTextValueFromJSON({
                        value: ""
                    })
                case 'dropdown':
                    if (props.isProductForm && props.attributePidToDropdownValues && attributePid in props.attributePidToDropdownValues) {
                        return AttributeDropdownValueFromJSON({
                            options: props.attributePidToDropdownValues[attributePid],
                            value: 'none'
                        })
                    }
                    return AttributeDropdownValueFromJSON({
                        options: [],
                        value: ""
                    });
                case 'range':
                    return AttributeRangeValueFromJSON({
                        minValue: 0,
                        maxValue: 1
                    });
                case 'number':
                    return AttributeNumberValueFromJSON({
                        numValue: 0
                    });
                case 'boolean':
                    return AttributeBooleanValueFromJSON({
                        value: true
                    });
            }
        }
    }

    /**
     * Handles action when there is input to the text field.
     */
    const handleAttributeTextInput = () => {

    }

    /**
     * Handles number input.
     */
    const handleAttributeNumberInput = () => {

    }

    /**
     * Handles attribute range input.
     */
    const handleAttributeRangeInput = () => {

    }

    /**
     * Adds a new dropdown value.
     */
    const handleAddDropdownValue = () => {
        if (props.attributeUuid && props.onChange) {
            const newAttributeValue = { ...props.initialAttributeValue, value: { options: [...props.initialAttributeValue.value.options, ""], value: props.initialAttributeValue.value.value ? props.initialAttributeValue.value.value : null } };
            props.onChange(props.attributeUuid, newAttributeValue);
        }
    }

    /**
     * Deletes the dropdown alue.
     * @param index 
     */
    const handleDeleteDropdownValue = (index: number) => {
        if (props.attributeUuid && props.onChange) {
            let newAttributeValue = { ...props.initialAttributeValue }
            newAttributeValue.value.options = newAttributeValue.value.options.splice(index, 1);
            props.onChange(props.attributeUuid, newAttributeValue);
        }
    }

    /**
     * Handles dropdown value input for the non-product form.
     * @param index 
     * @param newValue 
     */
    const handleNonProductFormAttributeDropdownInput = (index: number, newValue: string) => {
        if (props.attributeUuid && props.onChange) {
            let newAttributeValue = { ...props.initialAttributeValue }
            newAttributeValue.value.options[index] = newValue;
            props.onChange(props.attributeUuid, newAttributeValue);
        }
    }

    /**
     * Handles input to one of the two range inputs. Checks that the min is less than
     * the max.
     * @param isMin whether the value provided is the min value
     * @param newValue represents the new value
     */
    const handleProductFormRangeInput = (isMin: boolean, newValue: string) => {
        if (props.attributeUuid && props.onChange) {
            const value = parseInt(newValue);
            let newAttributeValue = { ...props.initialAttributeValue }
            if (isMin) newAttributeValue.value.minValue = value
            else newAttributeValue.value.maxValue = value;
            newAttributeValue.attributeInputIsValid = isNaN(value) ? false : (!newAttributeValue.value.minValue || !newAttributeValue.value.maxValue) ? false : newAttributeValue.value.minValue < newAttributeValue.value.maxValue
            props.onChange(props.attributeUuid, newAttributeValue)
        }
    }

    /**
     * Handles product form number input.
     * @param newValue the new value
     */
    const handleProductFormNumberInput = (newValue: string) => {
        if (props.attributeUuid && props.onChange) {
            const value = parseInt(newValue);
            let newAttributeValue = {...props.initialAttributeValue}
            newAttributeValue.value.numValue = value;
            newAttributeValue.attributeInputIsValid = !isNaN(value);
            props.onChange(props.attributeUuid, newAttributeValue)
        }
    }

    /**
     * Handle text input.
     * @param newValue the new value
     */
    const handleProductFormTextInput = (newValue: string) => {
        if (props.attributeUuid && props.onChange) {
            let newAttributeValue = {...props.initialAttributeValue}
            newAttributeValue.value.value = newValue;
            newAttributeValue.attributeInputIsValid = newValue !== "";
            props.onChange(props.attributeUuid, newAttributeValue)
        }
    }

    /**
     * Handle text input.
     * @param newValue the new value
     */
     const handleProductFormBooleanInput = (newValue: string) => {
        console.log(newValue)
        if (props.attributeUuid && props.onChange) {
            let newAttributeValue = {...props.initialAttributeValue}
            newAttributeValue.value.value = newValue;
            newAttributeValue.attributeInputIsValid = typeof newValue == 'boolean';
            props.onChange(props.attributeUuid, newAttributeValue)
        }
    }

    return (
        <Card variant={'outlined'}>
            <CardContent>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <Button disabled={
                            props.isProductForm &&
                            props.attributePidToAttribute &&
                            props.initialAttributeValue &&
                            props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                            props.attributePidToAttribute[props.initialAttributeValue.attributePid].isRequired} onClick={handleAttributeInputDelete} variant={'contained'} color={'error'}>Delete Attribute</Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems={'center'}>
                            {/* <Grid item>
                                <IconButton size={'small'}><ArrowUpward/></IconButton>
                            </Grid> */}
                            <Grid item>
                                <FormControlLabel disabled={props.isProductForm} labelPlacement='start' label='Required' control={<Checkbox size={'small'} />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <strong>Attribute</strong>
                <Select error={!props.initialAttributeValue || !props.initialAttributeValue.attributeInputIsValid || false} onChange={handleAttributeNameInputChanged} variant={'filled'} defaultValue={props.initialAttributeValue ? props.initialAttributeValue.attributePid : 'none'} size={'small'} fullWidth>
                    <MenuItem key={'none'} value={'none'}><i>Select attribute...</i></MenuItem>
                    {props.attributeNameToAttribute && Object.keys(props.attributeNameToAttribute).map(attributeName => (
                        <MenuItem key={props.attributeNameToAttribute[attributeName].pid}
                            value={props.attributeNameToAttribute[attributeName].pid}>{attributeName}</MenuItem>
                    ))}
                </Select>
                <br />
                <br />
                <Typography><strong>Type:</strong> {(props.initialAttributeValue && props.initialAttributeValue.attributePid && props.initialAttributeValue.attributePid !== 'none') && props.attributePidToAttribute[props.initialAttributeValue.attributePid] && props.attributePidToAttribute[props.initialAttributeValue.attributePid].type}</Typography>
                <br />
                <strong>Description:</strong>
                <Typography variant='body2' color='textSecondary'>{props.initialAttributeValue && props.initialAttributeValue.attributePid && props.initialAttributeValue.attributePid !== 'none' && props.attributePidToAttribute[props.initialAttributeValue.attributePid] && props.attributePidToAttribute[props.initialAttributeValue.attributePid].description || 'No description provided.'}</Typography>
            </CardContent>
            {
                /**
                 * In general, to keep the UI/UX consistent, components which would pertain to the "Product Type"
                 * version of this form and allow the user to specify values for the user to choose from should
                 * be provided beyond a separator.
                 */
            }
            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'dropdown' &&
                !props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Dropdown Values</strong></Typography>
                        <Typography variant='caption' color='textSecondary'>{!props.isProductForm ? 'Enter a list of values available for the user to select from.' : 'Select a value that corresponds to your choice.'}</Typography>
                        <br />
                        <br />
                        {props.initialAttributeValue && props.initialAttributeValue.value.options.map((option: any, index: number) => (
                            <Grid key={option} container spacing={2}>
                                <Grid item xs={10}>
                                    <TextField
                                        style={{ marginBottom: 10 }}
                                        variant={'outlined'}
                                        size={'small'}
                                        onBlur={(e) => { handleNonProductFormAttributeDropdownInput(index, e.target.value) }}
                                        placeholder={'Enter value'}
                                        fullWidth
                                        defaultValue={option} />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button onClick={() => { handleDeleteDropdownValue(index) }} color='error' variant='contained'>Delete</Button>
                                </Grid>
                            </Grid>
                        ))}
                        <br />
                        <Button onClick={handleAddDropdownValue} size={'small'} variant={'contained'} color={'secondary'}>Add Value</Button>
                    </CardContent></>}

            {
                /**
                 * Product Form Inputs
                 */
            }

            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'range' &&
                props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Range Values</strong></Typography>
                        <Typography variant='body2' color='textSecondary'>Enter a minimum value and a maximum value for this attribute.</Typography>
                        <br />
                        <Grid container columnGap={2}>
                            <Grid item>
                                <TextField
                                    defaultValue={props.initialAttributeValue && props.initialAttributeValue.value.minValue}
                                    onBlur={(e) => { handleProductFormRangeInput(true, e.target.value) }}
                                    type="number"
                                    error={props.initialAttributeValue && !props.initialAttributeValue.attributeInputIsValid}
                                    variant={'filled'} size={'small'} placeholder={'Min Value'} fullWidth />
                            </Grid>
                            <Grid item>
                                <TextField
                                    defaultValue={props.initialAttributeValue && props.initialAttributeValue.value.maxValue}
                                    type={'number'}
                                    onBlur={(e) => { handleProductFormRangeInput(false, e.target.value) }}
                                    error={props.initialAttributeValue && !props.initialAttributeValue.attributeInputIsValid} variant={'filled'} size={'small'} placeholder={'Max Value'} fullWidth />
                            </Grid>
                        </Grid>
                        <br />
                    </CardContent></>}

            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'boolean' &&
                props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Checkbox</strong></Typography>
                        <Typography variant='body2' color='textSecondary'>Use the checkbox to specify whether or not this attribute should apply for this item.</Typography>
                        <br />
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Select the option that applies</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={true}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </CardContent></>}

            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'dropdown' &&
                props.attributePidToDropdownValues &&
                props.attributePidToDropdownValues[props.initialAttributeValue.attributePid] &&
                props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Select an option</strong></Typography>
                        <Typography variant='caption' color='textSecondary'>Select a value that corresponds to your choice.</Typography>
                        <br />
                        <br />
                        <NativeSelect onChange={(e) => { handleProductFormBooleanInput(e.target.value) }} error={!props.initialAttributeValue || props.initialAttributeValue && !props.initialAttributeValue.attributeInputIsValid} defaultValue={'none'} fullWidth>
                            <option value={'none'}>Select an option...</option>
                            {props.attributePidToDropdownValues[props.initialAttributeValue.attributePid].map((option: string) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </NativeSelect>
                    </CardContent></>}

            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'number' &&
                props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Enter a value</strong></Typography>
                        <Typography variant='caption' color='textSecondary'>Enter a numeric value for the attribute.</Typography>
                        <br />
                        <br />
                        <TextField onBlur={(e) => { handleProductFormNumberInput(e.target.value) }} type={'number'} placeholder='Enter a number' size='small' variant='filled' fullWidth />
                    </CardContent></>}

            {props.initialAttributeValue &&
                props.attributePidToAttribute &&
                props.initialAttributeValue.attributePid in props.attributePidToAttribute &&
                props.attributePidToAttribute[props.initialAttributeValue.attributePid].type === 'text' &&
                props.isProductForm &&
                <>
                    <Divider />
                    <CardContent>
                        <Typography><strong>Enter a value</strong></Typography>
                        <Typography variant='caption' color='textSecondary'>Enter a value for the attribute.</Typography>
                        <br />
                        <br />
                        <TextField onBlur={(e) => { handleProductFormTextInput(e.target.value) }} placeholder='Enter text' size='small' variant='filled' fullWidth />
                    </CardContent></>}
        </Card>
    )

}

export default AttributeInputForm;

interface AttributeInputFormProps {
    attributeUuid: string,
    attributeNameToAttribute?: any,
    attributePidToAttribute: any,
    attributePidToDropdownValues?: any,
    initialAttributeValue?: any,
    isProductForm?: boolean,
    onChange?: Function,
    onDelete?: Function,
    loading?: boolean
}