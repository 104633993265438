import React from 'react'
import {getGlobalConfig} from "../../api/Api";
import {
    HttpErrorFromJSON,
    IndustriesApi,
    Industry, PatchDocument, PatchDocumentFromJSON,
    ResponseError,
    ResponseFromJSON,
    ResponseIndustryFromJSON
} from "ftm-api-client";
import View from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import Button from '@mui/material/Button'
import Delete from '@mui/icons-material/Delete'
import IndustryForm from "../../components/developer/IndustryForm";
import {useNavigate, useParams} from "react-router-dom";
import {useDialog} from "../../components/global/DialogProvider";
import {useSnackbar} from "notistack";

/**
 * Represents a screen allowing development users to add a new industry.
 * @constructor
 */
const IndustryAddScreen = () => {

    /**
     * Represents the Industries api instance.
     */
    const industriesApi = new IndustriesApi(getGlobalConfig());

    /**
     * Represents the initial industry.
     */
    const [initialIndustry, setInitialIndustry] = React.useState<Industry | undefined>()

    /**
     * Represents whether the form is loading.
     */
    const [isFormLoading, setIsFormLoading] = React.useState<boolean>(false)

    /**
     * Represents the react-router-dom navigation hook.
     */
    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();

    /**
     * Hooks into the react-router-dom params.
     */
    const params = useParams();

    /**
     * Represents the dialog controller.
     */
    const dialogue = useDialog();

    const fetchIndustry = async () => {
        try {
            const response = await industriesApi.getIndustry({
                pid: params!.industryPid!
            })
            if (response) {
                const data = ResponseIndustryFromJSON(response)
                if (data.data && data.data.length > 0) setInitialIndustry(data.data[0])
            }
        } catch (e) {
            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: 'An error occurred while fetching the industry.',
                confirmText: 'OKAY',
                onConfirm: () => {
                    navigate('/industries')
                }
            })
        }
    }

    /**
     * Handles industry deletion.
     */
    const handleIndustryDelete = async () => {
        dialogue.openConfirm({
            title: 'Delete Industry',
            message: `Are you sure you wish to delete this industry?`,
            confirmText: 'OKAY',
            onConfirm: async () => {
                try {
                  const response = await industriesApi.deleteIndustry({
                      pid: initialIndustry!.pid!
                  })
                    const resp = ResponseFromJSON(response);
                  if (resp) {
                      enqueueSnackbar("Industry has been successfully deleted.", {
                          title: "Industry delete",
                      })
                      navigate('/industries')
                  }
                } catch (e: any) {
                    const err = await e.response.json()
                    const error = HttpErrorFromJSON(err)
                    if (error && error.userMessage) {
                        dialogue.openConfirm({
                            title: 'An Error Occurred',
                            message: error.userMessage,
                            confirmText: 'OKAY',
                            onConfirm: () => { }
                        })
                    } else {
                        dialogue.openConfirm({
                            title: 'An Error Occurred',
                            message: "Please re-attempt your request.",
                            confirmText: 'OKAY',
                            onConfirm: () => { }
                        })
                    }
                }
            }
        })
    }

    React.useEffect(() => {
        fetchIndustry();
    }, [])

    /**
     * Handles action taken when a new industry is submitted.
     * @param newIndustry
     */
    const handleIndustrySubmit = async (newIndustry: Industry): Promise<void> => {
        setIsFormLoading(true);
        let patchDocumentList: PatchDocument[] = []
        delete newIndustry["pid"]
        Object.keys(newIndustry).map(industryField => {
            if ((newIndustry as any)[industryField] !== (initialIndustry as any)[industryField]) {
                patchDocumentList.push(PatchDocumentFromJSON({
                    op: "add",
                    path: `/${industryField}`,
                    value: (newIndustry as any)[industryField]
                }))
            }
        })
        try {
            const response = await industriesApi.patchIndustry({
                pid: initialIndustry!.pid!,
                patchDocument: patchDocumentList
            })
            if (response) {
                setIsFormLoading(false);
                dialogue.openConfirm({
                    title: 'Industry Updated',
                    message: 'The industry has been successfully updated.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/industries')
                    }
                })
            }
        } catch (e: any) {
            setIsFormLoading(false);
            const newError = await e.response.json();
            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: `${HttpErrorFromJSON(newError).userMessage}`,
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    return (<View>
        <Container maxWidth={'lg'}>
            <Header
                title={'Edit Industry'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Industries",
                        path: "/industries"
                    },
                    {
                        title: "Edit Industry",
                        path: "/industries/create"
                    },
                ]}
                action={<Button
                    onClick={handleIndustryDelete}
                    variant={'contained'} startIcon={<Delete/>} color={'error'}>Delete Industry</Button>}
            />
            <br/>
            {initialIndustry && <IndustryForm
                onSubmit={handleIndustrySubmit}
                loading={isFormLoading}
                initialIndustry={initialIndustry}
            />}
        </Container>
    </View>)

}

export default IndustryAddScreen;