import React from 'react'
import View from '../../components/global/Page'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import QuestionMark from '@mui/icons-material/Help'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

/**
 * Screen presented to the user when the destination
 * path was not found.
 * @returns {JSX.Element}
 */
const NotFoundScreen = () => {

    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/')
    }

  return (
    <View>
            <Box sx={{textAlign: 'center', paddingTop: '22%', height: '100%', verticalAlign: 'center'}}>
                <QuestionMark color='secondary' fontSize='large'/>
                <Typography sx={{mb: 1}} variant='h4'>
                    Page Not Found
                </Typography>
                <Typography 
                    sx={{mb: 2}}
                    color="textSecondary"
                    variant="body2"
                    >The page requested does not exist.</Typography>
                <Button
                    onClick={handleHome}
                >Take me home</Button>
            </Box>
    </View>
  )
}

export default NotFoundScreen