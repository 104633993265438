import { useMsal } from '@azure/msal-react'
import React from 'react'
import Button from '@mui/material/Button'
import microsoftVector from '../../microsoft-logo-vector.png'
import { loginRequest } from '../../application/util/Configuration'
import {Navigate, useNavigate} from 'react-router-dom'

/**
 * Allows the user to login using single-sign-on.
 * @returns {JSX.Element}
 */
const SsoLoginButton = () => {

    /**
     * Create a ref to the MSAL API.
     */
    const {instance} = useMsal();

    const [disabled, setDisabled] = React.useState(false);

    const [token, setToken] = React.useState<string | null>(null);

    const navigate = useNavigate();

    /**
     * Handle user login action.
     */
    const handleLogin = async () => {
        if (!disabled) setDisabled(true);
        try {
            await instance.loginPopup(loginRequest);
            navigate('/redirect-sso')
            // const token = await instance.acquireTokenSilent(loginRequest);
            // localStorage.setItem('token', token.accessToken);
            // setToken(token.accessToken);
        } catch (error) {
            setDisabled(false);
        }
    }

    return (
        token ? <Navigate to={'/redirect-sso'}/> : <div style={{textAlign: 'center'}}>
              <Button disabled={disabled} onClick={handleLogin} startIcon={<img width={20} height={20} src={microsoftVector}/>} variant='contained' color='secondary'>Sign in via Azure AD</Button>
        </div>
    )

}

export default SsoLoginButton;