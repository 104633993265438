import React, {useEffect, useState} from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import InputAdornment from '@mui/material/InputAdornment'
import Avatar from '@mui/material/Avatar'
import View from '../../components/global/Page'
import Header from '../../components/global/Header'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridSelectionModel,
    GridRenderCellParams
} from '@mui/x-data-grid'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Edit from '@mui/icons-material/Edit'
import Search from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import {useSnackbar} from 'notistack'
import { Configuration, UserResponse, UsersApi } from 'ftm-api-client'
import { defaultConfig } from '../../api/Api'

/**
 * The UserListScreen provides an interactive interface for employees
 * to manage all active clients. The view contains a table of employees that
 * automatically paginates and, when clicked, can display a detail view of
 * the client.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns 
 */
const UserListScreen = () => {

    /**
     * Query the users API.
     */
    let usersApi = new UsersApi(new Configuration(defaultConfig));

    /**
     * Represents the snackbar dispatch.
     */
    let {enqueueSnackbar} = useSnackbar();


    /**
     * Represents whether the table is loading.
     */
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {

        usersApi.getUsers({
            "includeTotals": true,
        }).then((result) => {
            setClients(result.data!)
            setFilteredClients(result.data!)
            setLoading(false);
        }, (error) => {

        })
        
        
    }

    /**
     * Represents the clients.
     */
    const [clients, setClients] = useState<Array<UserResponse>>([])

    /**
     * Represents the filtered clients.
     */
    const [filteredClients, setFilteredClients] = useState<Array<UserResponse>>([])

    /**
     * Represents the array of selected rows.
     */
    const [gridRowSelection, setGridRowSelection] = useState<GridSelectionModel>([])

    const navigate = useNavigate();

    /**
     * Handles action taken when the search field query
     * changes.
     * @param e the event
     */
    function handleSearchFieldChanged(e: any) {

        if (e.target.value === '') setFilteredClients(clients);

        let filter = clients.filter(client => (client.firstName.concat(' ' + client.lastName).toLowerCase().includes(e.target.value.toLowerCase())))
        setFilteredClients(filter);
    }

    useEffect(() => {
        fetchData();
    }, [])

    let columns = [
        {
            field: 'profileImage',
            headerName: 'Avatar',
            width: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Avatar src={params.row.imgUrl}/>
            ),
        },
          {
            field: 'firstName',
            headerName: 'First Name',
            width: 140,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 140,
        },
        {
            field: 'email',
            headerName: 'Email Address',
            width: 140,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Grid container display='flex' justifyContent='center'>
                    <Typography fontSize='small'>
                        <Link href={`mailto:${params.value}`}>
                            {params.value}
                        </Link>
                    </Typography>
                </Grid>
            )
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            width: 140,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Grid container display='flex' justifyContent='center'>
                    <Typography fontSize='small'>
                        <Link href={`tel:${params.value}`}>
                            {params.value}
                        </Link>
                    </Typography>
                </Grid>
            )
        },
        {
            field: 'addressStreet',
            headerName: 'Address Street',
            width: 140,
        },
        {
            field: 'addressCity',
            headerName: 'City',
            width: 140,
        },
        {
            field: 'addressState',
            headerName: 'State',
            width: 140,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 140,
            renderCell: (params: GridRenderCellParams<string>) => (
                    <IconButton color='primary' onClick={() => {
                        navigate(`/employee/clients/${params.row.pid}`)
                    }}>
                        <Edit/>
                    </IconButton>
            )
        },
    ]

    /**
     * Handles action taken when the user
     * chooses to delete. Recursively iterates through
     * an array to process elements for deletion against
     * the API.
     * @param {string} index the index of the row to delete
     */
    async function handleDelete(index: number) {

        if (index >= gridRowSelection.length) {
            enqueueSnackbar(`Successfully deleted ${gridRowSelection.length} users.`, {
                variant: 'success'
            });
            setGridRowSelection([])
            fetchData();
            return;
        }

        usersApi.deleteUser({
            "pid": gridRowSelection[index] as string
        }).then((result) => {
            handleDelete(++index);
        })

    }
    

  return (
      <View>
          <Container maxWidth="lg">
            <Header 
                title="Users"
                breadcrumbs={[
                    {
                        title: 'Home',
                        path: '/'
                    },
                    {
                        title: 'Users',
                        path: '/employee/clients'
                    }
                ]}
                action={
                <Button variant="contained" color='secondary' startIcon={<AddCircleIcon/>} onClick={() => {navigate('/employee/client/add')}}>
                    ADD USER
                </Button>}
            />
            <br/>
            <TextField 
                onChange={handleSearchFieldChanged} 
                fullWidth 
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                                    <Search opacity={0.5}/>
                                    </InputAdornment>)
                }}
                placeholder='Search for users...'/>
            <br/>
            <br/>
            <Paper variant='outlined' sx={{height: '550px'}}>
                <DataGrid
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    rows={filteredClients}
                />
            </Paper>
          </Container>
      </View>
  )
}

export default UserListScreen