import React from 'react'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import NativeSelect from '@mui/material/NativeSelect'

/**
 * A selection form allowing selection/modification of industry PIDs
 * within the organization form.
 * @returns {JSX.Element}
 */
const IndustrySelectForm = (props: IndustrySelectFormProps) => {


    /**
     * Stores the value of the current industry PID, for potential use when
     * deleting the industry select form.
     */
    const [industryPid, setIndustryPid] = React.useState<string>(props.defaultValue || 'none')


    /**
     * Handles action taken when the industry changes.
     * @param e represents the native event fired when changing the industry target
     */
    const handleOnIndustryChanged = (e: any) => {
        setIndustryPid(e.target.value)
        if (props.onIndustryChanged) props.onIndustryChanged(props.industrySelectId, e.target.value);
    }

    /**
     * Handles action taken when the industry should be deleted.
     */
    const handleOnIndustryDeleted = () => {
        if (props.onIndustryDeleted) props.onIndustryDeleted(props.industrySelectId)
    }

  return (<Box marginTop={5} marginBottom={5} border='solid 1px black'>
    <CardContent>
    <Button disabled={props.disabled} onClick={handleOnIndustryDeleted} variant='contained' color='error'>Delete Industry</Button>
    <br/>
    <br/>
    <NativeSelect defaultValue={props.defaultValue} error={props.defaultValue && props.industryPidToIndustry && props.industryPidToIndustry[props.defaultValue] ? false : industryPid === 'none'} disabled={props.disabled} onChange={handleOnIndustryChanged} fullWidth>
        <option value='none'>Select an industry</option>
        {props.industryPidToIndustry && Object.keys(props.industryPidToIndustry).map((industryPid) => (
            <option key={industryPid} value={industryPid}>{props.industryPidToIndustry[industryPid].name}</option>
        ))}
    </NativeSelect>
    </CardContent>
  </Box>
  )
}

export interface IndustrySelectFormProps {
    industrySelectId: string,
    defaultValue?: string,
    disabled?: boolean,
    industryPidToIndustry?: any
    onIndustryChanged?: (industrySelectId: string, industryPid: string) => any,
    onIndustryDeleted?: (industrySelectId: string) => any,
}


export default IndustrySelectForm