import { configureStore } from '@reduxjs/toolkit'
import {usersProfileSlice} from "./slices/users-profile";

/**
 * Represents the Global application store, which manages slices.
 */
export const store = configureStore({
    reducer: {
        profile: usersProfileSlice.reducer,
        // notifications: notificationsReducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch