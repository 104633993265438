import Container from '@mui/material/Container';
import { GetOrganizationsRequest, Organization, OrganizationsApi, ResponseOrganizationFromJSON } from 'ftm-api-client'
import React from 'react'
import {defaultConfig, getGlobalConfig} from '../../api/Api';
import View from '../../components/global/Page';
import Header from '../../components/global/Header';
import Button from '@mui/material/Button'
import AddCircle from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import InputField from '../../components/global/InputField';

/**
 * Represents a screen allowing the user to view the organizations.
 * @returns {JSX.Element}
 */
const OrganizationsScreen = () => {


    const OrganizationsApiInstance = new OrganizationsApi(getGlobalConfig());

    const [organizationsRowsPerPage, setOrganizationsRowsPerPage] = React.useState<number>(10)

    const [organizations, setOrganizations] = React.useState<Organization[]>([])

    const [organizationsTotal, setOrganizationsTotal] = React.useState<number>(0)

    const [page, setPage] = React.useState(0)

    const [searchQuery, setSearchQuery] = React.useState<string>('')

    const navigate = useNavigate();

    const handleSearchQueryChange = (e: any) => {
        setSearchQuery(e.target.value);
    }

    const fetchOrganizationsByPage = (page: number = 0, rowsPerPage: number = organizationsRowsPerPage, nameFilter?: string) => {
        let query: GetOrganizationsRequest = {
            sort: `^name`,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            includeTotals: true
        }
        if (nameFilter) {
            query['q'] = `{"name": {"$regex": "${nameFilter}", "$options": "i"}}`
        }

        OrganizationsApiInstance.getOrganizationsRaw(query).then((response) => {
            const totals = response.raw.headers.get('x-total-count')
            if (totals) {
                let total: number = 0;
                if (totals) total = parseInt(totals)
                setOrganizationsTotal(total)
            }
            const rawJson = response.raw.json()
            rawJson.then(json => {
                const data = ResponseOrganizationFromJSON(json)
                if (data.data) {
                    setOrganizations(data.data);
                }
            })
        }, (error: any) => {})
    }

    React.useEffect(() => {
        fetchOrganizationsByPage();
    }, [])

  return ( <View>
    <Container maxWidth='lg'>
        <Header
            title="Organizations"
            breadcrumbs={[
                {
                    title: 'Home',
                    path: '/'
                },
                {
                    title: 'Organizations',
                    path: '/organizations'
                }
            ]}
            action={
            <Button variant="contained" color='secondary' startIcon={<AddCircle/>} onClick={() => {navigate('/organizations/create')}}>
                Add Organization
            </Button>}
        />
        <br/>
        <br/>
        <TableContainer variant={'outlined'} component={Paper}>
            <CardContent>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={10}>
                        <InputField onChange={handleSearchQueryChange} fullWidth placeholder={'Search organizations by name...'}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => {fetchOrganizationsByPage(0, organizationsRowsPerPage, searchQuery)}} variant={'contained'}>Search</Button>
                    </Grid>
                </Grid>
            </CardContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>City</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {organizations.map(organization => (
                        <TableRow hover onClick={() => {navigate(`/organizations/${organization.pid}`)}}>
                        <TableCell>{organization.name}</TableCell>
                        <TableCell>{organization.addressStreet}</TableCell>
                        <TableCell>{organization.addressCity}</TableCell> 
                       </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={organizationsTotal}
                    rowsPerPage={organizationsRowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => { 
                        setPage(newPage) 
                        fetchOrganizationsByPage(newPage);
                    }}
                    onRowsPerPageChange={(e) => { 
                        let newRowsPage = parseInt(e.target.value);
                        setOrganizationsRowsPerPage(newRowsPage) 
                        setPage(0);
                        fetchOrganizationsByPage(0, newRowsPage);
                    }}
                />
        </TableContainer>
    </Container>
    </View>
  )
}

export default OrganizationsScreen