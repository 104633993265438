import {createSlice, PayloadAction, ThunkDispatch} from "@reduxjs/toolkit";
import {Configuration, ResponseUserProfileFromJSON, UserProfile, UserProfileFromJSON, UsersApi} from "ftm-api-client";
import {getGlobalConfig} from "../../../api/Api";

/**
 * Represents the initial state of the User's profile.
 */
const initialUsersProfileState = {
    profile: UserProfileFromJSON({})
}

/**
 * Stores a slice of the redux store for the users profile.
 */
export const usersProfileSlice = createSlice({
    name: 'usersProfile',
    initialState: initialUsersProfileState,
    reducers: {
        getUsersProfile: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload;
        },
        logoutUser: (state) => {
            state.profile = UserProfileFromJSON({});
        }
    }
})

/**
 * Initializes the user's profile into the redux store.
 * @param dispatch represents the dispatch action for the state
 * @param callback callback to fire after profile is initialized
 */
export function initProfile(dispatch: ThunkDispatch<any, any, any>, callback: Function = () => {}) {
    const UsersApiInstance = new UsersApi(getGlobalConfig())
    if (localStorage.getItem('token')) {
        UsersApiInstance.usersProfile().then((result) => {
            const data = ResponseUserProfileFromJSON(result);
            if (data) {
                const profile = data!.data![0]
                dispatch(usersProfileSlice.actions.getUsersProfile(profile));
                return callback();
            }
        }, (error) => {
            localStorage.removeItem('token')
        })
    }
}

/**
 * Removes the user and their profile information from localStorage and 
 * from the redux cache.
 * @param dispatch represents the dispatch
 */
export function logoutUser(dispatch: ThunkDispatch<any, any, any>) {
    localStorage.removeItem('token');
    dispatch(usersProfileSlice.actions.logoutUser);
}

export const { getUsersProfile } = usersProfileSlice.actions;