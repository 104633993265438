import React, { useState } from 'react'
import ProductTypeForm from '../../components/developer/ProductTypeForm'
import Page from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from '../../components/global/Header'
import { useDialog } from '../../components/global/DialogProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductType, ProductTypesApi, HttpErrorFromJSON, PatchDocumentFromJSON, PatchDocument } from 'ftm-api-client'
import { getGlobalConfig } from '../../api/Api'
import { AttributesApiAdapter, CategoriesApiAdapter, ProductTypesApiAdapter } from '../../api/impl/adapters'

/**
 * Allows a user to edit an productType.
 * @returns {JSX.Element}
 */
const ProductTypeEditScreen = (): JSX.Element => {

    const productTypesApi = new ProductTypesApiAdapter(), categoriesApi = new CategoriesApiAdapter(), attributesApi = new AttributesApiAdapter();

    const [productType, setProductType] = useState<ProductType | null>();

    const [categoryPidToCategory, setCategoryPidToCategory] = useState<any>();
    
    const [attributePidToAttribute, setAttributePidToAttribute] = useState<any>();

    const [attributeNameToAttribute, setAttributeNameToAttribute] = useState<any>();

    const dialogue = useDialog();

    const navigate = useNavigate();

    const {productTypePid} = useParams();

    const handleSubmit = async (newProductType: ProductType) => {

        try {

            let patchDocumentList: PatchDocument[] = [
                PatchDocumentFromJSON({
                    "op": "replace",
                    "path": "/attributeValues",
                    "value": newProductType.attributeValues
                })
            ]
            
            if (productType!.name !== newProductType.name) {
                patchDocumentList.push(PatchDocumentFromJSON({
                    "op": "add",
                    "path": "/name",
                    "value": newProductType.name
                }))
            }

            if (productType?.description !== newProductType.description) {
                patchDocumentList.push(PatchDocumentFromJSON({
                    "op": "add",
                    "path": "/description",
                    "value": newProductType.description
                }))
            }

            if (productType?.categoryPid !== newProductType.categoryPid) {
                patchDocumentList.push(PatchDocumentFromJSON({
                    "op": "add",
                    "path": "/categoryPid",
                    "value": newProductType.categoryPid
                }))
            }

            await productTypesApi.patch(newProductType.pid!, patchDocumentList);
            
            dialogue.openConfirm({
                title: 'Product Type Updated Successfully',
                message: 'The product type has been successfully updated.',
                confirmText: 'OKAY',
                onConfirm: () => {
                    navigate('/categories');
                }
            })

        } catch (error: any) {

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: error,
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    /**
     * Fetch the productType that the params point to.
     */
    const fetchProductType = async () => {
        try {
            const newCategoryPidToCategory = await categoriesApi.getFieldNameToModel();
            console.log(newCategoryPidToCategory)
            setCategoryPidToCategory(newCategoryPidToCategory);
            const attributes = await attributesApi.findAll();
            let newAttributePidToAttribute: any = {}, newAttributeNameToAttribute: any = {};
            attributes.map(attribute => {
                newAttributeNameToAttribute[attribute.name] = attribute;
                newAttributePidToAttribute[attribute.pid] = attribute;
            })
            setAttributeNameToAttribute(newAttributeNameToAttribute)
            setAttributePidToAttribute(newAttributePidToAttribute)
            const currentProductType = await productTypesApi.findByPid(productTypePid!);
            if (currentProductType) setProductType(currentProductType)   
        } catch (error) {
            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: "An error occurred while processing your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    React.useEffect(() => {
        fetchProductType();
    }, [])

  return (
    <Page>
        <Container maxWidth={'lg'}>
            <Header
                title={'Edit ProductType'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "ProductTypes",
                        path: "/productTypes"
                    },
                    {
                        title: "Edit ProductType",
                        path: "/productTypes/add"
                    }
                ]}
            />
            <br/>
            {productType && <ProductTypeForm 
                                initialProductType={productType} 
                                categoryPidToCategory={categoryPidToCategory}
                                attributePidToAttribute={attributePidToAttribute}
                                attributeNameToAttribute={attributeNameToAttribute}
                                onSubmit={handleSubmit}/>}
        </Container>
    </Page>
  )
}

export default ProductTypeEditScreen