import React, { useState } from 'react'
import AttributeForm from '../../components/developer/AttributeForm'
import Page from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from '../../components/global/Header'
import { useDialog } from '../../components/global/DialogProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { Attribute, AttributesApi, HttpErrorFromJSON } from 'ftm-api-client'
import { getGlobalConfig } from '../../api/Api'
import { AttributesApiAdapter, IndustriesApiAdapter } from '../../api/impl/adapters'

/**
 * Allows a user to edit an attribute.
 * @returns {JSX.Element}
 */
const AttributeEditScreen = (): JSX.Element => {

    const attributesApi = new AttributesApiAdapter();

    const [attribute, setAttribute] = useState<Attribute | null>();

    const dialogue = useDialog();

    const navigate = useNavigate();

    const params = useParams();

    const handleSubmit = async (newAttribute: Attribute) => {

        try {
            
            await attributesApi.update(attribute, newAttribute);
            dialogue.openConfirm({
                title: 'Attribute Updated Successfully',
                message: 'The attribute has been successfully updated.',
                confirmText: 'OKAY',
                onConfirm: () => {
                    navigate('/attributes');
                }
            })

        } catch (error: any) {

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: error,
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    /**
     * Fetch the attribute that the params point to.
     */
    const fetchAttribute = async () => {
        try {
            const currentAttribute = await attributesApi.findByPid(params.pid!);
            if (currentAttribute) setAttribute(currentAttribute)   
        } catch (error) {
            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: "An error occurred while processing your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    React.useEffect(() => {
        fetchAttribute();
    }, [])

  return (
    <Page>
        <Container maxWidth={'lg'}>
            <Header
                title={'Edit Attribute'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Attributes",
                        path: "/attributes"
                    },
                    {
                        title: "Edit Attribute",
                        path: "/attributes/add"
                    }
                ]}
            />
            <br/>
            {attribute && <AttributeForm initialAttribute={attribute} onSubmit={handleSubmit}/>}
        </Container>
    </Page>
  )
}

export default AttributeEditScreen