import React from 'react'
import { Navigate } from 'react-router-dom'
import Authentication from '../../api/Authentication'

/**
 * The Home component fetches the user privileges and, based on the privilege,
 * renders a Redirect that routes the user to a specified location within the 
 * application.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns {JSX.Element}
 */
const Home = () => {

  /**
   * Gets a redirect for the current user's role.
   * @returns {JSX.Element} a Navigate element redirecting the user to the correct page
   */
  function getRedirectForRole(): JSX.Element {
    
    /**
    * Represents the active user credentials.
    */
    let credentials = Authentication.getPrivileges();

    switch (credentials) {

      case 'error':
        return <Navigate to='/login'/>
      case 'user':
        return <Navigate to='/dashboard'/>
      case 'employee':
        return <Navigate to='/dashboard'/>
      case 'developer':
        return <Navigate to='/dashboard'/>
      case 'reviewer':
        return <Navigate to='/dashboard'/>
    
      default:
        return <Navigate to='/login'/>

    }
  }
  

  /**
   * Return a redirect that 
   */
  return (
    getRedirectForRole()
  )
}

export default Home