import React from 'react'
import View from "../../components/global/Page";
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import ProductTypeForm from "../../components/developer/ProductTypeForm";
import { Attribute, AttributesApi, CategoriesApi, Category, HttpErrorFromJSON, ProductType, ProductTypesApi, ResponseAttributeFromJSON, ResponseCategoryFromJSON } from 'ftm-api-client';
import { getGlobalConfig } from '../../api/Api';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../components/global/DialogProvider';

/**
 * Represents a screen at which a user may enter a product type.
 * @constructor
 */
const ProductTypeAddScreen = () => {

    /**
     * Represents the API client config.
     */
    const config = getGlobalConfig();

    /**
     * Represents the attributes API instance.
     */
    const attributesApi = new AttributesApi(config)

    const categoriesApi = new CategoriesApi(config);

    const productTypesApi = new ProductTypesApi(config);

    const dialogue = useDialog();

    const [attributePidToAttribute, setAttributePidToAttribute] = React.useState<any>({});

    const [attributeNameToAttribute, setAttributeNameToAttribute] = React.useState({});

    const [categoryPidToCategory, setCategoryPidToCategory] = React.useState({});

    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    /**
     * Fetches the attributes.
     * @param previousAttributes 
     */
    const fetchAttributes = async (index: number = 0, previousAttributes: Attribute[] = []) => {

        const response = await attributesApi.getAttributesRaw({
            offset: index * 100,
            limit: 100,
            sort: '^name',
            includeTotals: true
        })

        const totalHeader = response.raw.headers.get('x-total-count')

        if (response && totalHeader) {
            const totals = parseInt(totalHeader);
            const rawJson = response.raw.json()
            rawJson.then(async (json) => {
                const attributesList = ResponseAttributeFromJSON(json)
                if (attributesList.data) {
                    if (attributesList.data && !isNaN(totals)) {
                        const newAttributes = previousAttributes.concat(attributesList.data);
                        if (newAttributes.length < totals) {
                            await fetchAttributes(++index, newAttributes);
                        }
                        let newAttributePidToAttribute: any = {}
                        let newAttributeNameToAttribute: any = {}
                        newAttributes.map(attribute => {
                            newAttributeNameToAttribute[attribute.name] = attribute;
                            newAttributePidToAttribute[attribute.pid!] = attribute;
                        })
                        setAttributeNameToAttribute(newAttributeNameToAttribute)
                        setAttributePidToAttribute(newAttributePidToAttribute)
                    }
                }
            })

        }
    }
    
    const fetchCategories = async (index: number = 0, previousCategories: Category[] = []) => {

        const response = await categoriesApi.getCategoriesRaw({
            sort: '^name',
            offset: index * 100,
            limit: 100,
            includeTotals: true
        })

        const totalHeader = response.raw.headers.get('x-total-count')

        if (response && totalHeader) {
            const totals = parseInt(totalHeader);
            const rawJson = response.raw.json()
            rawJson.then(async (json) => {
                const categoryList = ResponseCategoryFromJSON(json);
                if (categoryList.data) {
                    if (categoryList.data && !isNaN(totals)) {
                        const newCategories = previousCategories.concat(categoryList.data);
                        if (newCategories.length < totals) {
                            await fetchCategories(++index, newCategories);
                        }
                        let newCategoryPidToCategory: any = {}
                        newCategories.map(category => {
                            newCategoryPidToCategory[category.pid!] = category;
                        })
                        setCategoryPidToCategory(newCategoryPidToCategory)
                    }
                }  
            })
        }
    }

    React.useEffect(() => {
        fetchAttributes();
        fetchCategories();
    }, [])

    /**
     * Handles action taken on submit.
     * @param productType 
     */
    const handleSubmit = async (productType: ProductType) => {
        setLoading(true);
        try {
            const response = await productTypesApi.addProductType({
                productType: productType
            })
            if (response && response.pid) {
                dialogue.openConfirm({
                    title: 'Product Type Created Successfully',
                    message: 'The product type has been successfully created.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/categories');
                    }
                })
            }
        } catch (error: any) {
            const er = await error.response.json()
            const errResponse = HttpErrorFromJSON(er);

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: errResponse ? errResponse.userMessage : "An unknown error occurred. Please wait 5 minutes and re-attempt your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
        setLoading(false);
    }

    return (
        <View>
            <Container maxWidth={'lg'}>
                <Header 
                    title={'Add Product Type'}
                    breadcrumbs={[
                        {
                            "title": "Home",
                            "path": "/"
                        },
                        {
                            "title": "Product Directory",
                            "path": "/categories"
                        },
                        {
                            "title": "Add Product Type",
                            "path": "/product_types/add"
                        }
                    ]}
                    />
                <br/>
                <ProductTypeForm
                    attributeNameToAttribute={attributeNameToAttribute}
                    attributePidToAttribute={attributePidToAttribute}
                    categoryPidToCategory={categoryPidToCategory}
                    loading={loading}
                    onSubmit={handleSubmit}
                />
            </Container>
        </View>
    )
}

export default ProductTypeAddScreen;