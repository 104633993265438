import {
    AttributesApi,
    AttributeFromJSON,
    IndustriesApi,
    ResponseAttributeFromJSON,
    ResponseIndustryFromJSON,
    IndustryFromJSON,
    CategoriesApi,
    ResponseCategoryFromJSON,
    CategoryFromJSON,
    OrganizationsApi,
    ResponseOrganizationFromJSON,
    OrganizationFromJSON,
    ProductsApi,
    ResponseProductFromJSON,
    ProductFromJSON,
    ProductTypesApi,
    ResponseProductTypeFromJSON,
    ProductTypeFromJSON,
    PrivilegesApi,
    ResponsePrivilegeFromJSON,
    PrivilegeFromJSON,
    ResponseUserResponseFromJSON,
    UserFromJSON,
    UsersApi,
    ReportsApi,
    ProductSearchQuery,
    ProductSearchQueryFromJSON,
    ResponseHitListProductFromJSON,
    HitListProduct,
    SearchApi,
    ResponseSearchHitFromJSON,
    ResponseSearchHit,
    SearchRequest,
    LoginResponse,
    LoginResponseFromJSON, ResponseFromJSON,
    UserContact,
    FtmTasksApi,
    ResponseFtmTaskFromJSON,
    FtmTaskFromJSON, GetTaskAssignmentRequest, UserContactFromJSON, ResponseUserContactFromJSON, UserContactsApi,
} from "ftm-api-client";
import ApiAdapter from "../ApiAdapter";

export class AttributesApiAdapter extends ApiAdapter {
    constructor() {
        super("attributes", "attribute", AttributesApi, ResponseAttributeFromJSON, AttributeFromJSON)
    }
}

export class CategoriesApiAdapter extends ApiAdapter {
    constructor() {
        super("categories", "category", CategoriesApi, ResponseCategoryFromJSON, CategoryFromJSON)
    }
}

export class IndustriesApiAdapter extends ApiAdapter {
    constructor() {
        super("industries", "industry", IndustriesApi, ResponseIndustryFromJSON, IndustryFromJSON)
    }
}

export class OrganizationsApiAdapter extends ApiAdapter {
    constructor() {
        super("organizations", "organization", OrganizationsApi, ResponseOrganizationFromJSON, OrganizationFromJSON)
    }
}

export class PrivilegesApiAdapter extends ApiAdapter {
    constructor() {
        super("privileges", "privilege", PrivilegesApi, ResponsePrivilegeFromJSON, PrivilegeFromJSON)
    }
}

export class UserContactsApiAdapter extends ApiAdapter {
    constructor() {
        super("userContacts", "userContact", UserContactsApi, ResponseUserContactFromJSON, UserContactFromJSON)
    }
}

export class ProductsApiAdapter extends ApiAdapter {
    constructor() {
        super("products", "product", ProductsApi, ResponseProductFromJSON, ProductFromJSON)
    }
}


export class FtmTasksApiAdapter extends ApiAdapter {
    constructor() {
        super("ftmTasks", "ftmTask", FtmTasksApi, ResponseFtmTaskFromJSON, FtmTaskFromJSON);
    }

    async getTaskAssignment(options: GetTaskAssignmentRequest) {
        try {
            // @ts-ignore
            const response = await this.apiInstance.getTaskAssignmentRaw(options);
            const total = this.getTotalsFromResponseRaw(response);
            const result = this.responseJsonParser(await response.value());
            let returnedTask = null;
            if (
                result.data &&
                result.data.length
            ) returnedTask = result.data[0]

            return {
                "task": returnedTask,
                "total": total
            };
        } catch (error) {
            throw new Error(await this.constructException(error));
        }
    }
}

export class ProductTypesApiAdapter extends ApiAdapter {
    constructor() {
        super("productTypes", "productType", ProductTypesApi, ResponseProductTypeFromJSON, ProductTypeFromJSON)
    }
    protected override titleCase(str: string): string {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
          }
        );
    }
}

export class UsersApiAdapter extends ApiAdapter {
    constructor() {
        super("users", "user", UsersApi, ResponseUserResponseFromJSON, UserFromJSON)
    }

    public async loginUserSso(token: string): Promise<LoginResponse> {
        try {
            // @ts-ignore
            const response = await this.apiInstance.loginUserSso({token: token});
            return LoginResponseFromJSON(response);
        } catch (error) {
            console.log(error);
            throw new Error(await this.constructException(error));
        }
    }

    public async submitUserContact(userContact: UserContact): Promise<Response> {
        try {
            // @ts-ignore
            const response = await this.apiInstance.submitUserContact(
                {userContact: userContact}
            );
            // @ts-ignore
            return ResponseFromJSON(response);
        } catch (error) {
            console.log(error);
            throw new Error(await this.constructException(error));
        }
    }

}

export class SearchApiAdapter {

    protected readonly apiInstance: any;
    protected readonly listResponse: any;

    constructor() {
        this.apiInstance = new SearchApi();
        this.listResponse = ResponseSearchHitFromJSON;
    }

    /**
     * 
     * @param {SearchRequest} searchQuery the user search query
     * @returns 
     */
    public async search(searchQuery: SearchRequest): Promise<ResponseSearchHit> {
        let result: ResponseSearchHit;
        try {
            const response = await this.apiInstance.search(searchQuery);
            result = this.listResponse(response);
        } catch (error) {
            result = this.listResponse(
                {
                    statusCode: 200,
                    responseType: "error",
                    description: "Successfully retrieved SearchHit",
                    data: []
                }
            )
        }
        return result;
    }
}

export class ReportsApiAdapter extends ApiAdapter {

    constructor() {
        super("reports", "report", ReportsApi, ResponseHitListProductFromJSON, ProductSearchQueryFromJSON)
    }

    /**
     * Searches products based on a best-fit algorithm, which ranks each {@link Hit} in order
     * of the score returned for that item.
     * @param {ProductSearchQuery} searchProductQuery the query to search by
     * @returns {Promise<HitListProduct[] | null>} the list of hits
     */
    public async searchProducts(searchProductQuery: ProductSearchQuery): Promise<HitListProduct[] | null> {
        try {
            // @ts-ignore
            const response = await this.apiInstance['searchProducts'](searchProductQuery)
            if (response) {
                const result = ResponseHitListProductFromJSON(response);
                if (result && result.data && result.data[0].hits) {
                    return result.data;
                }
            }
            return null;
        } catch (error) {
            throw new Error(await this.constructException(error))
        }
    }
}


