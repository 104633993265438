import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import AddCircle from '@mui/icons-material/AddCircle'
import { Organization, OrganizationFromJSON } from 'ftm-api-client'
import {v4 as uuidv4} from 'uuid'
import IndustrySelectForm from './IndustrySelectForm'

/**
 * Represents a form allowing administrative users to create a new Organization.
 */
const OrganizationForm = (props: OrganizationFormProps) => {

    const [organization, setOrganization] = React.useState<Organization>(props.initialOrganization ? props.initialOrganization : OrganizationFromJSON({
        name: "",
        description: "",
        industryPids: []
    }))

    /**
     * Represents whether the organization name is valid.
     */
    const [isNameValid, setIsNameValid] = React.useState<boolean>(props.initialOrganization ? props.initialOrganization.name !== '' : false)

    /**
     * Represents whether all industry selects are valid.
     */
    const [areIndustrySelectsValid, setAreIndustrySelectsValid] = React.useState<boolean>(true);

    /**
     * Represents whether the organization overall is valid.
     */
    const [isOrganizationValid, setIsOrganizationValid] = React.useState<boolean>(props.initialOrganization ? true : false);

    /**
     * Represents a set of unique UUIDs mapped to industry PIDs, to be grouped together on
     * submission.
     */
    const [uuidToIndustryPid, setUuidToIndustryPid] = React.useState<any>({})

    /**
     * Handles addition to industry. Initialize a new industry selection form.
     */
    const handleAddToIndustry = () => {
        let newUUIDToIndustryPid = {...uuidToIndustryPid, [uuidv4()]: 'none'}
        setUuidToIndustryPid(newUUIDToIndustryPid)
        setAreIndustrySelectsValid(false);
        handleCheckOrganizationValidity(false);
    }

    /**
     * Handles deletion of an industry select form.
     * @param industrySelectId represents the id of the select form to delete
     */
    const handleDeleteIndustry = (industrySelectId: string) => {
        let newUUIDToIndustryPid = {...uuidToIndustryPid}
        if (uuidToIndustryPid[industrySelectId] === 'none'
            || (Object.keys(uuidToIndustryPid).length - 1) === 0) handleCheckOrganizationValidity(true && isNameValid);
        delete newUUIDToIndustryPid[industrySelectId]
        setUuidToIndustryPid(newUUIDToIndustryPid);
    }

    /**
     * Handles actions of changing the industry select values. Checks if all UUID to industry selects are
     * valid.
     * @param industrySelectId 
     * @param newValue 
     */
    const handleIndustrySelectChanged = (industrySelectId: string, newValue: string) => {
        const newUUIDToIndustryPid = {...uuidToIndustryPid, [industrySelectId]: newValue}
        setUuidToIndustryPid(newUUIDToIndustryPid)
        let newIndustrySelectsAreValid = true;
        Object.values(newUUIDToIndustryPid).map(value => {
            if (value === 'none') newIndustrySelectsAreValid = false;
        })
        setAreIndustrySelectsValid(newIndustrySelectsAreValid)
        handleCheckOrganizationValidity(newIndustrySelectsAreValid && isNameValid)
    }

    /**
     * Handles action taken when a field within the organization changes.
     */
    const handleOrganizationFieldChanged = (fieldName: string, newValue: any) => {
        if (fieldName == 'name') {
            const check = newValue !== '';
            setIsNameValid(check)
            handleCheckOrganizationValidity(check && areIndustrySelectsValid);
        }
        let newOrganization = {...organization, [fieldName]: newValue}
        setOrganization(newOrganization);
    }

    /**
     * Handles checking whether the organization is valid, such as when fields blur
     * or when an industry selection is made. Checks all of the necessary fields.
     * @param newValue represents the newValue to check alongside
     */
    const handleCheckOrganizationValidity = (newValue: boolean) => {
        setIsOrganizationValid(newValue);
    }

    const handleSubmit = () => {
        const industryPids: any[] = []
        Object.keys(uuidToIndustryPid).map(uuid => {
            if (uuidToIndustryPid[uuid] !== 'none')
                industryPids.push(uuidToIndustryPid[uuid])
        });
        let newOrganization = {...organization, industryPids: industryPids}
        if (props.onSubmit) props.onSubmit(newOrganization)
    }

    React.useEffect(() => {
        // Initialize any industry PIDs into industry selection forms.
        if (props.initialOrganization) {
            let newUuidToIndustryPid: any = {}
            props.initialOrganization.industryPids!.map(industryPid => {
                newUuidToIndustryPid[uuidv4()] = industryPid;
            })
            setUuidToIndustryPid(newUuidToIndustryPid);
        }
    }, [])

    const industrySelectPidToIndustryPidKeys = Object.keys(uuidToIndustryPid)

    return (<><Card variant={'outlined'}>
        <CardContent>
            <Typography><strong>Organization Name</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('name', e.target.value) }}
                defaultValue={props.initialOrganization?.name}
                error={!isNameValid}
                fullWidth
                placeholder='Name'
            />
            <br/>
            <br/>
            <Typography><strong>Description</strong></Typography>
            <TextField 
                onChange={(e:any) => { handleOrganizationFieldChanged('description', e.target.value) }}
                defaultValue={props.initialOrganization?.description}
                fullWidth
                multiline
                minRows={5}
                placeholder="Enter a description for the organization"
            />
            <br/>
            <br/>
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <Typography gutterBottom><strong>Industries</strong></Typography>
                </Grid>
                <Grid item>
                    <Button onClick={handleAddToIndustry} color='secondary' startIcon={<AddCircle/>} variant='contained'>Add to Industry</Button>
                </Grid>
            </Grid>
            {organization && uuidToIndustryPid && props.industryPidToIndustry && industrySelectPidToIndustryPidKeys.map(uuid => (
                <IndustrySelectForm
                    key={uuid}
                    defaultValue={uuid}
                    industryPidToIndustry={props.industryPidToIndustry}
                    industrySelectId={uuid}
                    onIndustryDeleted={handleDeleteIndustry}
                    onIndustryChanged={handleIndustrySelectChanged}
                />
            ))}
            {organization && industrySelectPidToIndustryPidKeys.length === 0 && <Typography variant='body2' color='textSecondary'>No industries assigned. To add an industry, click 'Add to Industry'.</Typography>}
            <br/>
            <Typography gutterBottom><strong>Logo URL</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('logoUrl', e.target.value) }}
                defaultValue={props.initialOrganization?.logoUrl}
                fullWidth placeholder='Logo url'/>
            <br/>
            <br/>
            <Typography gutterBottom><strong>Street Address</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('addressStreet', e.target.value) }}
                defaultValue={props.initialOrganization?.addressStreet}
                fullWidth placeholder='Street address'/>
            <br/>
            <br/>
            <Typography gutterBottom><strong>City</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('addressCity', e.target.value) }}
                defaultValue={props.initialOrganization?.addressCity}
                fullWidth placeholder='City'/>
            <br/><br/>
            <Typography gutterBottom><strong>State</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('addressState', e.target.value) }}
                defaultValue={props.initialOrganization?.addressState}
                fullWidth placeholder='State'/>
            <br/><br/>
            <Typography gutterBottom><strong>ZIP Code</strong></Typography>
            <TextField
                onBlur={(e:any) => { handleOrganizationFieldChanged('addressZip', e.target.value) }}
                defaultValue={props.initialOrganization?.addressZip}
                fullWidth placeholder='ZIP Code'/>
        </CardContent>
    </Card>
    <br/>
    <Button disabled={!isOrganizationValid} onClick={handleSubmit} variant='contained' color='secondary' fullWidth>Submit</Button>
    </>)

}

// Export the OrganizationForm as the default.
export default OrganizationForm;

/**
 * Represents the Prop types for the OrganizationForm.
 */
interface OrganizationFormProps {
    initialOrganization?: Organization,
    onSubmit: (newOrganization: Organization) => Promise<void>,
    industryPidToIndustry?: any,
}