import {HttpError, HttpErrorFromJSON, PatchDocument, PatchDocumentFromJSON} from "ftm-api-client";
import * as runtime from 'ftm-api-client/dist/runtime';
import { createPidFilterChunks } from "../application/util/utils";
import {getGlobalConfig} from "./Api";

/**
 * Provides a layer of reusable functionality and abstraction between the
 * OpenAPI generated models and the React app.
 * @author chrisrinaldi
 * @since 2 November, 2022
 */
abstract class ApiAdapter {

    protected readonly listFunc: string;
    protected readonly queryLimit: number;
    protected readonly deleteFunc: string;
    protected readonly addFunc: string;
    protected readonly patchFunc: string;
    protected readonly modelConstructor: Function;
    protected readonly apiInstance: runtime.BaseAPI;
    protected readonly responseJsonParser: Function;

    /**
     * Constructs a new {@link ApiAdapter } instance.
     * @param {string} collectionName represents the collection name, i.e. 'categories'
     * @param {string} singularName represents the singular name of the collection, i.e. 'category'
     * @param {runtime.BaseAPI} API the API instance to use
     * @param {Function} responseObjectFromJson a function that parses the raw response to a list
     * @param {Function} modelConstructor the function which constructs raw objects to the model
     */
    protected constructor(
                          collectionName: string,
                          singularName: string,
                          API: any,
                          responseObjectFromJson: any,
                          modelConstructor: Function,
                          ) {

        const titleSingular = this.titleCase(singularName), titlePlural = this.titleCase(collectionName);

        this.queryLimit = 100;
        this.apiInstance = new API(getGlobalConfig())
        this.listFunc = `get${titlePlural}`;
        this.deleteFunc = `delete${titleSingular}`;
        this.patchFunc = `patch${titleSingular}`;
        this.addFunc = `add${titleSingular}`;
        this.modelConstructor = modelConstructor;
        this.responseJsonParser = responseObjectFromJson;
    }

    /**
     * Title-cases the specified string.
     * @param {string} str the string to title-ize
     * @returns {string} a string which is in title case
     */
    protected titleCase2(str: string): string {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    }

    /**
     * Fixed title case.
     * @param str
     * @protected
     */
    protected titleCase(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Gets a mapping of the model PID to the model, for ease of read operations.
     * @param q 
     */
    public async getFieldNameToModel(fieldName="pid", q: string | undefined = undefined) {

        let newPidToModel: any = {};

        const documents = await this.findAll(q);
            documents.map(document => {
                newPidToModel[document[fieldName]] = document;
        })   

        return newPidToModel
    }

    /**
     * Cycles through the array of PID filter chunks until there are no more chunks left to
     * process.
     * @param pidFilterChunksArray 
     * @param callback action to be taken upon completion
     */
    protected async cyclePidFilterChunks(pidFilterChunksArray: string[], index: number = 0, documents: any[] = []): Promise<any[]> {
        
        if (index >= pidFilterChunksArray.length) return documents;

        const results = await this.find({
            q: pidFilterChunksArray[index],
            limit: this.queryLimit
        })

        let newDocuments = [...documents]
        if (results) newDocuments = newDocuments.concat(results);

        return await this.cyclePidFilterChunks(pidFilterChunksArray, ++index, newDocuments);
    }

    /**
     * Retrieves all documents matching the specified query.
     * @param {q} q the query
     * @param {number} index the index of the current API call iteration 
     * @param {any[]} documentList a list of documents in the response
     */
    public async findAll(q: {} = {}, index: number = 0, documentList: any[] = []): Promise<any[]> {
        const query = {
            ...q,
            limit: this.queryLimit,
            offset: index * this.queryLimit,
            includeTotals: true
        }
        const result = await this.find(query)
        if (result) {
            if (result.length === 0) return documentList;
            const newDocumentList = documentList.concat(result);
            return await this.findAll(q, ++index, newDocumentList);
        } else return documentList;
    }

    /**
     * Locates all resources by breaking PIDs into queryable chunks and filtering with the max chunk
     * size available.
     * @param pids the list of pid strings
     * @param filterChunksIndex the index within the filter chunks
     * @param documentList 
     */
    public async findByPids(pids: string[]) {
        const pidChunks = createPidFilterChunks(pids, "pid", this.queryLimit);
        return await this.cyclePidFilterChunks(pidChunks)
    }

    /**
     * Queries the collection by the specified field values, using the param
     * 'fieldName' as the key.
     * @param {any[]} values the values to query for
     * @param {string} fieldName the field name the values correspond to
     * @returns the list of documents matching the criteria
     */
    public async findByFieldValues(values: any[], fieldName: string = "pid") {
        const fieldChunks = createPidFilterChunks(values, fieldName, this.queryLimit);
        return await this.cyclePidFilterChunks(fieldChunks);
    }

    /**
     * Fetch the total count from the response headers.
     * @param responseRaw
     * @private
     */
    protected getTotalsFromResponseRaw(responseRaw: any) {
        if (responseRaw) {
            const total = responseRaw.raw.headers.get('x-total-count')
            if (!isNaN(total)) return parseInt(total);
        }
        return null;
    }

    /**
     * Retrieves the total count for the specified query string.
     * @param q 
     * @returns {number | null} the total, if available, else null
     */
    public async total(q: {}): Promise<number | null> {
        try {
            const query = {...q, limit: 1, includeTotals: true}
            // @ts-ignore
            const response = await this.apiInstance[this.listFunc + 'Raw'](query);
            return this.getTotalsFromResponseRaw(response);
        } catch (error) {
            throw new Error(await this.constructException(error))
        }        
    }

    /**
     * Finds records that match the specified query.
     * @param q 
     * @returns
     */
    public async find(q: any = {}) {
        try {
            // @ts-ignore
            const response = await this.apiInstance[this.listFunc](q);
            if (response) {
                const data = this.responseJsonParser(response)
                if (data.data) return data.data
                return null;
            } else return null;
        } catch (error: any) {
            throw new Error(await this.constructException(error))
        }
    }

    /**
     * Finds documents by page number.
     * @param {object} q the query
     * @param {number} pageNumber the page number
     * @param {number} rowsPerPage number of docs per page
     * @param {boolean} includeTotals whether to include the total results
     */
    async findByPage(q: any = {}, pageNumber: number = 0, rowsPerPage: number = 10, includeTotals: boolean = false) {
        try {
            const query: any = {
                'limit': rowsPerPage,
                'offset': rowsPerPage * pageNumber,
                'includeTotals': includeTotals
            }
            for (const key of Object.keys(q)) {
                if (key !== 'limit' && key !== 'offset' && key !== 'includeTotals') {
                    query[key] = q[key]
                }
            }

            // @ts-ignore
            const response = await this.apiInstance[this.listFunc + 'Raw'](query);

            let total: number | null = null, result: any | null = null;

            if (response) {

                if (includeTotals) {
                    const tempTotal = response.raw.headers.get('x-total-count')
                    if (tempTotal && !isNaN(tempTotal)) total = parseInt(tempTotal);
                }

                const responseReadStream = await response.value();
                const data = this.responseJsonParser(responseReadStream);
                if (data.data) result = data.data;

            }

            return [result, total];

        } catch (e) {
            throw new Error(await this.constructException(e));
        }
    }

    /**
     * Finds a resource using its unique PID value.
     * @param {string} pid the pid to search by
     */
    async findByPid(pid: string) {
        const response = await this.find({
            q: `{"pid": "${pid}"}`,
            limit: 1
        })
        if (response && response.length && response.length > 0) return response[0]
    }

    /**
     * Patches the specified resource using the PID identifier.
     * @param pid the pid of the resource to patch
     * @param patchDocumentList
     */
    public async patch(pid: string, patchDocumentList: PatchDocument[]) {
        try {
            // @ts-ignore
            await this.apiInstance[this.patchFunc]({
                pid: pid,
                patchDocument: patchDocumentList
            });
        } catch (error) {
            throw new Error(await this.constructException(error))
        }
    }

    /**
     * Deletes the specified resource using the PID identifier.
     * @param pid
     */
    public async delete(pid: string) {
        try {
            // @ts-ignore
            await this.apiInstance[this.deleteFunc]({
                pid: pid
            })
        } catch (error) {
            throw new Error(await this.constructException(error))
        }
    }

    /**
     * Parses a raw object into one which is compatible with the API call. Adds the
     * new document.
     * @param {any} newDocument the document to add
     */
    public async add(newDocument: any) {
        const newDocumentParsed = this.modelConstructor(newDocument);
        if (newDocument) {
            try {
                // @ts-ignore
                await this.apiInstance[this.addFunc](newDocumentParsed);
            } catch (error) {
                throw new Error(await this.constructException(error))
            }
        }
    }

    /**
     * Compares two documents and generates a list of PatchDocument to be
     * pushed to the server.
     * @param oldDocument the old document 
     * @param newDocument the new document
     */
    public async update(oldDocument: any, newDocument: any) {
        
        const oldDocumentParsed = this.modelConstructor(oldDocument), newDocumentParsed = this.modelConstructor(newDocument);
        if (oldDocumentParsed && newDocumentParsed && oldDocumentParsed.pid) {
            try {

                let patchDocumentList: PatchDocument[] = [];
                Object.keys(newDocument).map(field => {
                    if (field !== 'pid' && oldDocument[field] !== newDocument[field]) {
                        patchDocumentList.push(PatchDocumentFromJSON({
                            op: "add",
                            path: `/${field}`,
                            value: newDocument[field]
                        }))
                    }
                })
                await this.patch(oldDocument.pid, patchDocumentList);

            } catch (error) {
                throw new Error('An error occurred while processing your request.')
            }
        }
    }

    /**
     * Constructs an exception model using the raw response from the API client. Passes
     * this back to be consumed by UI elements.
     * @param error represents the raw error.
     * @private
     */
    async constructException(error: any) {
        console.log(error)
        const rawError = await error.response.json();
        const errorResponse: HttpError = HttpErrorFromJSON(rawError);
        return (errorResponse && errorResponse.userMessage) ? errorResponse.userMessage : "An unknown error occurred. Please wait 5 minutes and re-attempt" +
            "your request";
    }

}

/**
 * An api accessor function accepts an object containing
 * the parameters of the call. The function is expected
 * to return a promise.
 */

export default ApiAdapter;