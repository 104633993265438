import Container from '@mui/material/Container'
import { Configuration, PrivilegesApi, SignupUserRequest, User, UserResponse, UsersApi } from 'ftm-api-client'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultConfig } from '../../api/Api'
import UserForm from '../../components/employee/UserForm'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * Represents the create client screen
 * @author chrisrinaldi
 * @returns 
 */
const CreateClientScreen = () => {


    const [privilegePidToPrivilege, setPrivilegePidToPrivilege] = React.useState({})

    const [loading, setLoading] = React.useState<boolean>(false);

    const config = new Configuration(defaultConfig)

    const navigate = useNavigate();

    const privilegesApi = new PrivilegesApi(config)
    const usersApi = new UsersApi(config);

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (newUser: any) => {
        setLoading(true);
        usersApi.signupUser({
            "user": newUser
        }).then((result) => {
            enqueueSnackbar('Successfully registered user.', { variant: 'success' })
            navigate('/employee/clients')
        }, (error: any) => {
            setLoading(false);
            enqueueSnackbar("An error occurred. Please double check all fields.", { variant: 'error' })
        })
    }

    const fetchData = () => {
        privilegesApi.getPrivileges().then((result) => {
            if (result && result.data!.length > 0) {
                let newPrivilegePidToPrivilege: any = {}
                result.data!.map((privilege) => {
                    newPrivilegePidToPrivilege[privilege!.pid!] = privilege;
                })
                setPrivilegePidToPrivilege(newPrivilegePidToPrivilege)
            }
        })
    }

    React.useEffect(() => {
        fetchData()
    }, [])

  return ( <View>
      <Container maxWidth='lg'>
          <Header 
            title="Register User" 
            breadcrumbs={[
                {
                    title: 'Home',
                    path: '/'
                },
                {
                    title: 'Users',
                    path: '/employee/clients'
                },
                {
                    title: 'Add',
                    path: `/employee/client/add`
                }
            ]}
            />
            <br/>
          <UserForm
            loading={loading}
            onSubmit={handleSubmit}
            privilegePidToPrivilege={privilegePidToPrivilege}
          />
      </Container>
      </View>
  )
}


export default CreateClientScreen