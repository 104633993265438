import React from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import Folder from '@mui/icons-material/Folder'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CardActionArea from "@mui/material/CardActionArea";
import ProductTypeAccordion from "./ProductTypeAccordion";
import IconButton from '@mui/material/IconButton'
import AddCircle from '@mui/icons-material/AddCircle'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { Category, Product, ProductType } from 'ftm-api-client'
import LoadingSection from '../global/LoadingSection'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import Settings from '@mui/icons-material/Settings'
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputField from '../global/InputField'

const ProductCategoryDetail = (props: ProductCategoryDetailProps) => {

    const handleFolderItemClicked = (folderItem: any) => {
        if (props.onProductTypeClicked && folderItem.attributeValues) {
            props.onProductTypeClicked(folderItem)
        } else if (props.onCategoryClicked && !folderItem.attributeValues) props.onCategoryClicked(folderItem);
    }

    const [view, setView] = React.useState('list');

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setView(nextView);
  };

    return (<Card>
        <CardContent>
            <InputField
                style={{marginBottom: 30}}
                placeholder="Search categories..."
                fullWidth
            />
    <Grid container justifyContent={'space-between'}>
            <Grid item>
                <Button onClick={() => { props.onGoBack && props.onGoBack() }} disabled={props.loading || !props.onGoBack || !props.activeCategory} variant='outlined' startIcon={<ArrowLeft/>}>Go Back</Button>
            </Grid>
            <Grid item>
            <ToggleButtonGroup
            size='small'
      value={view}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="list" aria-label="list">
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="module" aria-label="module">
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
            </Grid>
        </Grid>
        {props.categoriesAndTypes && <>
            <br/>
            <Typography variant='body2' color={'textSecondary'}><strong>Sub-categories ({props.categoriesAndTypes?.length})</strong></Typography>
            <br/>
            {props.loading ? <LoadingSection/> : <Grid container spacing={2}>
                {props.categoriesAndTypes && props.categoriesAndTypes.length > 0 ? props.categoriesAndTypes?.map((categoryOrType: any) => (
                    <Grid item xs={12} sm={6} md={4}>
                    <Card style={{width: '100%'}} variant={'outlined'}>
                        <CardActionArea onClick={() => { handleFolderItemClicked(categoryOrType) }}>
                            <List style={{padding: 0}}>
                                <ListItem>
                                    <ListItemIcon>
                                        {categoryOrType.attributeValues ? <Settings/> : <Folder/>}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={categoryOrType.name}
                                        secondary={categoryOrType.attributeValues ?  "Product Type" : "Category"}
                                    />
                                </ListItem>
                            </List>
                        </CardActionArea>
                    </Card>
                </Grid>
                )) : <CardContent>
                <Typography>There are no sub-categories for this category.</Typography>
            </CardContent>}
            </Grid>}
            <br/></>}
        {!props.activeCategory && !props.categoriesAndTypes && <>
            <Typography><strong>Empty</strong></Typography>
            <Typography variant={'caption'} color={'textSecondary'}>There are no product types available.</Typography>
        </>}
        </CardContent>
    </Card>)

}

export default ProductCategoryDetail;

interface ProductCategoryDetailProps {

    activeCategory?: Category,

    categoriesAndTypes?: any[],

    loading?: boolean,

    onGoBack?: Function,

    productCategoryDetails?: any,

    onProductCategoryDelete?: any,

    onCategoryClicked?: Function,

    onProductTypeClicked?: Function,

    products?: Product[],

}