import React, {useEffect, useState} from 'react'
import {
    HttpErrorFromJSON,
    IndustriesApi,
    Industry, Organization,
    OrganizationsApi,
    ResponseIndustryFromJSON,
    ResponseOrganizationFromJSON
} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import Page from "../../components/global/Page";
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TabContext from '@mui/lab/TabContext'
import Button from '@mui/material/Button'
import Edit from '@mui/icons-material/Edit'
import Header from "../../components/global/Header";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Business from '@mui/icons-material/Business'
import Divider from "@mui/material/Divider";
import Pagination from '@mui/material/Pagination'
import CardActions from "@mui/material/CardActions";
import grey from "@mui/material/colors/grey";
import ReportProblem from "@mui/icons-material/ReportProblem";

const IndustryDetailScreen = () => {

    let config = getGlobalConfig();

    const industriesApi = new IndustriesApi(config);

    const organizationsApi = new OrganizationsApi(config);

    const [industry, setIndustry] = useState<Industry | undefined>()

    const [activeTab, setActiveTab] = useState('overview')

    const [organizations, setOrganizations] = useState<Organization[]>([])

    const [page, setPage] = useState<number>(1)

    const [totalPages, setTotalPages] = useState<number>(1)

    const params = useParams();

    const navigate = useNavigate();

    /**
     * Fetches the industry.
     */
    const fetchIndustry = async () => {
        try {
            const data = await industriesApi.getIndustry({pid: params.industryPid!})
            if (data) {
                const industryResponse = ResponseIndustryFromJSON(data);
                if (industryResponse.data && industryResponse.data.length > 0) {
                    setIndustry(industryResponse.data[0])
                    fetchOrganizationsForIndustry()
                }
            }
        } catch (e: any) {
            const errResult = await e.response.json()
            const error = HttpErrorFromJSON(errResult)
        }
    }

    const ORG_ROWS_PER_PAGE = 10;

    /**
     * Fetches organizations for the current industry.
     * @param page
     */
    const fetchOrganizationsForIndustry = async (page: number = 0) => {
        try {
            const data = await organizationsApi.getOrganizations({
                q: `{"industryPids": "${params.industryPid!}"}`,
                sort: '^name',
                limit: ORG_ROWS_PER_PAGE,
                offset: page * ORG_ROWS_PER_PAGE,
            })
            const organizationResponse = ResponseOrganizationFromJSON(data);
            if (organizationResponse.data && organizationResponse.data.length > 0) {
                setOrganizations(organizationResponse.data)
            }
        } catch (e: any) {
            const errResult = await e.response.json()
            const error = HttpErrorFromJSON(errResult)
        }
    }

    useEffect(() => {
        fetchIndustry();
    }, [])

    return (<Page>
        <Container maxWidth={'lg'}>
            <Header
                title={industry ? industry.name : ""}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Industries",
                        path: "/industries"
                    },
                    {
                        title: industry ? industry.name : "",
                        path: `/industries/${industry?.pid}`
                    },
                ]}
                action={<Button startIcon={<Edit/>} onClick={() => { navigate(`/industries/${params.industryPid!}/edit`) }} variant={'contained'} color={'secondary'}>Edit Industry</Button>}
            />
            <br/>
            <Card variant={'outlined'}>
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, newValue) => { setActiveTab(newValue) }}>
                                <Tab label="Overview" value="overview" />
                            </TabList>
                        </Box>
                        <TabPanel value="overview">
                            <Typography variant={'body2'} color={'secondary'}><strong>Description</strong></Typography>
                            <Typography variant={'body2'} color={'textSecondary'}>{industry?.description!}</Typography>
                        </TabPanel>
                    </TabContext>
            </Card>
            <br/>
            <Card variant={'outlined'}>
                {organizations.length === 0 && <Container style={{height: 150, textAlign: 'center', opacity: 0.5}} maxWidth={'xl'}>
                    <ReportProblem fontSize={'large'}/>
                    <Typography variant={'h6'}><strong>No Organizations Found</strong></Typography>
                    <Typography variant={'body2'}>No organizations exist within this industry.</Typography>
                </Container>}
                {organizations.map(organization => (
                    <ListItem
                        onClick={() => { navigate(`/organizations/${organization.pid}`) }}
                        sx={{
                            '&:hover': {
                                backgroundColor: grey["100"]
                            }
                        }}>
                        <ListItemIcon><Business/></ListItemIcon>
                        <ListItemText primary={organization.name} secondary={'Organization'}/>
                    </ListItem>
                ))}
                <Divider/>
                <CardActions>
                    <Pagination
                        page={page}
                        count={totalPages}
                    />
                </CardActions>
            </Card>
        </Container>
    </Page>)


}

export default IndustryDetailScreen;