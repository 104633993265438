import { Configuration, PopupRequest } from "@azure/msal-browser";

/**
 * The application configuration is an object containing
 * constant environment variables, and provides global access
 * to the variables by simply accessing the type.
 */
export default {
    DeveloperBasePath: 'http://localhost:8080',
    ProductionBasePath: 'https://ftmcloud-dev.azurewebsites.net',
    UsingProductionMode: true,
    AuthenticationMethod: 'azure' // Either 'mongo' or 'azure'
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "276e6b1a-8464-40a0-ad19-11ead16c30c6",
        authority: "https://login.microsoftonline.com/099abfad-a84a-4238-8928-da23608db3e9",
        // redirectUri: "https://analytix-ai.com/redirect-sso",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["api://276e6b1a-8464-40a0-ad19-11ead16c30c6/.default"],
    extraQueryParameters: {
        'grant_type': 'client_credentials',
    }
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
