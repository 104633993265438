import React from 'react'
import View from '../../components/global/Page'
import Container from '@mui/material/Container'
import Header from '../../components/global/Header'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AttributesApiAdapter, OrganizationsApiAdapter, ProductsApiAdapter, ProductTypesApiAdapter } from '../../api/impl/adapters'
import ParametricSearchFilters from '../../components/global/ParametricSearchFilter'
import { AttributeValue, Product, ProductType } from 'ftm-api-client'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate } from 'react-router-dom'
import Show from '../../components/global/Show'

/**
 * A screen on which users can search for specified items.
 * @returns {JSX.Element}
 */
const ReportsScreen = (): JSX.Element => {

    const location = useLocation(), productTypePid = location.state?.productTypePid;

    const productsApi = new ProductsApiAdapter();

    const organizationsApi = new OrganizationsApiAdapter();

    const productTypesApi = new ProductTypesApiAdapter();

    const attributesApi = new AttributesApiAdapter();

    const navigate = useNavigate();

    const [productResults, setProductResults] = React.useState<Product[]>([])

    const [totalResults, setTotalResults] = React.useState<number>(0);

    const [productType, setProductType] = React.useState<ProductType | null>()

    const [organizationPidToOrganization, setOrganizationPidToOrganization] = React.useState<any>({});

    const [attributePidToProductPidToValueMapping, setAttributePidToProductPidToValueMapping] = React.useState<any>({});

    const [loading, setLoading] = React.useState(false);

    const fetchProducts = async (page: number = 1) => {
        try {
            setLoading(true);
            const pType = await productTypesApi.findByPid(productTypePid)
            if (pType) {
                setProductType(pType);
            }
            let attributePids: string[] = [], newAttributePidToAttribute: any = {}, newAttributePidToProductPidToValueMapping: any = {};
            pType.attributeValues.map((attributeValue: AttributeValue) => {
                attributePids.push(attributeValue.attributePid)
            })
            const attributes = await attributesApi.findByPids(attributePids);
            if (attributes) {
                attributes.map((attribute: any) => {
                    newAttributePidToAttribute[attribute.pid] = attribute;
                })
            }
            const total = await productsApi.total({q: `{"productTypePid": "${productTypePid}"}`})
            setTotalResults(total ? total : 0);
            const products = await productsApi.find({
                q: `{"productTypePid": "${productTypePid}"}`,
                limit: 100,
                offset: (page - 1) * 100,
            })
            if (products && products.length) {
                let orgPids: string[] = [];
                products.map((product: Product) => {
                    orgPids.push(product.organizationPid);
                    product.attributeValues!.map(attributeValue => {
                        if (newAttributePidToAttribute[attributeValue.attributePid]) {
                            if (!newAttributePidToProductPidToValueMapping[attributeValue.attributePid]) newAttributePidToProductPidToValueMapping = {}
                            if (!newAttributePidToProductPidToValueMapping[attributeValue.attributePid][product.pid!]) newAttributePidToProductPidToValueMapping[attributeValue.attributePid][product.pid!] = [attributeValue.value]
                            else newAttributePidToProductPidToValueMapping[attributeValue.attributePid][product.pid!].push(attributeValue.value);
                        }
                    })
                });
                setAttributePidToProductPidToValueMapping(newAttributePidToProductPidToValueMapping);
                const orgs = await organizationsApi.findByPids(orgPids)
                if (orgs && orgs.length) {
                    let newOrganizationPidToOrganization: any = {};
                    orgs.map(organization => {
                        newOrganizationPidToOrganization[organization.pid] = organization;
                    })

                    setOrganizationPidToOrganization(newOrganizationPidToOrganization);
                    setProductResults(products);
                }
            }
            setLoading(false);
        } catch (error) {

        }
    }

    let initialAttributeColumns: any[] = [];

    React.useEffect(() => {
        fetchProducts();
    }, [])

    return (
        <View>
            <Container maxWidth='lg'>
                <Header
                    title={productType?.name || "Loading..."}
                    breadcrumbs={[
                        {
                            title: "Home",
                            path: '/'
                        },
                        {
                            title: "Product Directory",
                            path: '/categories'
                        }
                    ]}
                    action={<Show privileges={['developer']}>
                        <Button onClick={() => { navigate(`/product_types/${productTypePid}/edit`) }} variant='contained' color='secondary'>Edit Product Type</Button>
                    </Show>}
                />
                <Typography variant='body2'>
                    {productType?.description || "No description provided."}
                </Typography>
                <br/>
                    <ParametricSearchFilters
                        productTypePid={productTypePid}
                        resultCount={totalResults}
                        loading={loading}
                    />
                <br/>
                <Paper variant='outlined' style={{height: '400px'}}>
                    <DataGrid
                        components={{Toolbar: GridToolbar}}
                        loading={loading}
                        rows={productResults}
                        checkboxSelection
                        getRowId={(row) => { return row.pid! }}
                        disableSelectionOnClick
                        columns={[
                            {
                                field: 'imgUrl', 
                                headerName: '', 
                                width: 70, 
                                filterable: false,
                                sortable: false,
                                renderCell: (row) => (
                                <Avatar variant='rounded' src={row.row.imgUrl}/>
                            )},
                            { field: 'name', headerName: 'Name', width: 200 },
                            { 
                                field: 'organizationPid', 
                                headerName: 'Organization', 
                                width: 130,
                                renderCell: (row) => (
                                    organizationPidToOrganization[row.value] && organizationPidToOrganization[row.value].name
                                ) 
                            },
                            ...initialAttributeColumns
                        ]}
                    />
                </Paper>
            </Container>
        </View >
    )
}

export default ReportsScreen