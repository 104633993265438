import React from 'react'
import {ProductType, ResponseFromJSON} from "ftm-api-client";
import Page from "../../components/global/Page";
import Header from "../../components/global/Header";
import Container from '@mui/material/Container'
import {useNavigate, useParams, Link as RouterLink} from "react-router-dom";
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Delete from '@mui/icons-material/Delete'
import {useDialog} from "../../components/global/DialogProvider";
import {useSnackbar} from "notistack";
import Edit from '@mui/icons-material/Edit'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { AttributesApiAdapter, ProductTypesApiAdapter } from '../../api/impl/adapters';
import Link from '@mui/material/Link'

const AttributeScreen = () => {

    const [attribute, setAttribute] = React.useState<any>({})

    const attributesApi = new AttributesApiAdapter();

    const productTypesApi = new ProductTypesApiAdapter();

    const [displayProductsOpen, setDisplayProductsOpen] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();

    const dialogue = useDialog();

    const navigate = useNavigate();

    const [productTypes, setProductTypes] = React.useState<ProductType[]>([])

    const fetchAttribute = async () => {
        const newAttribute = await attributesApi.findByPid(params.pid!)
        if (newAttribute) {
            setAttribute(newAttribute);
            const newProductTypes = await productTypesApi.findAll({
                q: `{"attributeValues.attributePid": "${params.pid!}"}`
            })
            setProductTypes(newProductTypes);
        }
    }

    const handleEditAttribute = () => {
        navigate(`/attributes/${params.pid}/edit`)
    }

    const handleDeleteAttribute = async () => {
        dialogue.openConfirm({
            title: 'Delete Attribute',
            message: 'Are you sure you wish to delete this attribute?',
            confirmText: 'OKAY',
            onConfirm: async () => {
                try {
                    const response = await attributesApi.delete(params.pid!)
                    const resp = ResponseFromJSON(response);
                    enqueueSnackbar("Attribute has been successfully deleted.", {
                        title: "Attribute delete",
                    })
                    navigate('/attributes')
                } catch (e: any) {
                    if (e) {
                        dialogue.openConfirm({
                            title: 'An Error Occurred',
                            message: e,
                            confirmText: 'OKAY',
                            onConfirm: () => { }
                        })
                    } else {
                        dialogue.openConfirm({
                            title: 'An Error Occurred',
                            message: "Please re-attempt your request.",
                            confirmText: 'OKAY',
                            onConfirm: () => { }
                        })
                    }
                }
            }
        })
    }

    React.useEffect(() => {
        fetchAttribute();
    }, [])

    return (<Page>
        <Container maxWidth={'lg'}>
            <Header
                title={attribute.name}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Attributes",
                        path: "/attributes"
                    },
                    {
                        title: attribute ? attribute.name : "",
                        path: `/attributes/${params.pid}`
                    },
                ]}
                action={
                    <Grid container spacing={1}>
                        <Grid item><Button onClick={handleEditAttribute} variant={'contained'} color={'secondary'} startIcon={<Edit/>}>Edit Attribute</Button></Grid>
                        <Grid item><Button onClick={handleDeleteAttribute} variant={'contained'} color={'error'} startIcon={<Delete/>}>Delete Attribute</Button></Grid>
                    </Grid>
                }
            />
            <br/>
            <Card variant={'outlined'}>
                <CardContent>
                    <Typography variant={'h6'}>Overview</Typography>
                    <Typography variant={'body2'} color={'textSecondary'}>This attribute has the following properties:</Typography>
                    <br/>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={'body2'}><strong>Name</strong></Typography>
                            <Typography variant={'body2'}>{attribute.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'body2'}><strong>Data Type</strong></Typography>
                            <Typography variant={'body2'}>{attribute.type}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'body2'}><strong>Description</strong></Typography>
                            <Typography variant={'body2'}>{attribute.description}</Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                    <Typography variant='h6'>Usage</Typography>
                    <Typography variant='body2' color='textSecondaryh'>This attribute is being used in the following locations: </Typography>
                    <br/>
                    <Typography variant='body2'><strong>Product Types ({productTypes.length})</strong> <IconButton
                    onClick={() => { setDisplayProductsOpen(!displayProductsOpen) }} size='small'>{displayProductsOpen ? <KeyboardArrowUp fontSize='small'/> : <KeyboardArrowDown fontSize='small'/>}</IconButton></Typography>
                    <Collapse in={displayProductsOpen}>
                        <ul>
                            {productTypes.map(productType => (
                                <li><Link color='primary' fontSize={'md'}>{productType.name}</Link></li>
                            ))}
                        </ul>
                    </Collapse>
                </CardContent>
            </Card>
        </Container>
    </Page>)

}

export default AttributeScreen;