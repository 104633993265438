import React from 'react'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingSection = () => {
  return (
    <Card sx={{backgroundColor: (theme) => theme.palette.mode == 'light' ? 'neutral.300' : 'neutral.900', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight: '400px'}}>
                <CircularProgress /><br/>
                Loading...
            </Card>
  )
}

export default LoadingSection