import React from 'react';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Appbar from './components/global/Appbar';
import Navbar from './components/global/Navbar';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import RoutesIndex from './views';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from './components/global/DialogProvider';
import { useThemeMode } from './application/theme/theme';
import {store} from "./application/redux/store";
import { Provider } from 'react-redux'
import {lightTheme, darkTheme} from './application/theme/theme';
import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication';
import { MsalProvider } from '@azure/msal-react';

/**
 * MSAL Configuration
 */
type AppProps = {
  pca: IPublicClientApplication;
};

/**
 * Represents the entry point of the application.
 * @constructor
 */
function App({ pca }: AppProps) {

  const [navbarOpen, setNavbarOpen] = React.useState<boolean>(false);

  const [theme, toggleTheme] = useThemeMode();
  const globalTheme = theme === 'light' ? lightTheme : darkTheme;

  // const navigate = useNavigate();
  // const navigationClient = new CustomNavigationClient(navigate);
  // pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Router>
        <Provider store={store}>
          <ThemeProvider theme={globalTheme}>
            <SnackbarProvider>
            <DialogProvider>
            <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Appbar navOpen={navbarOpen} setNavOpen={setNavbarOpen} darkMode={theme as string} toggleDarkMode={toggleTheme as Function}/>
            <Navbar navOpen={navbarOpen} setNavOpen={setNavbarOpen}/>
            <Box component="main" sx={{ flexGrow: 1 }}>
              <Toolbar />
              <RoutesIndex/>
            </Box>
          </Box>
          </DialogProvider>
          </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </Router>
    </MsalProvider>
  );
}

export default App;
