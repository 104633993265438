import * as React from 'react'
import { useState, useContext } from 'react'
import {
  PropTypes,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'

const DEFAULT_OK_COLOR = 'primary'
const DEFAULT_CANCEL_COLOR = 'secondary'
const DEFAULT_OK_TEXT = 'CONFIRM'
const DEFAULT_CANCEL_TEXT = 'CANCEL'
const DEFAULT_FULL_SCREEN = false
const DEFAULT_FULL_WIDTH = true
const DEFAULT_MAX_WIDTH = 'xs'

const getRGB = (color?: PropTypes.Color) => {
  switch (color) {
    case 'primary':
      return '#3f50b5'
    case 'secondary':
      return '#f44336'
    default:
      return '#000'
  }
}

interface Props {
  context?: (context: IDialogContext) => void
}

/**
 * The DialogProvider is a component that provides a context to all of the
 * dialogs in the application. It is used to provide a consistent API for
 * you to declaratively open dialogs and alert messages.
 * 
 * This component was originally developed by @github / @darrenfang and has
 * been refactored and updated to meet Material-UI's (now MUI's) standards.
 * The biggest change is that MUI's deprecated makeStyles API has been
 * removed from this context provider.
 * @author https://github.com/darrenfang
 * @see https://github.com/darrenfang/use-material-ui-dialog/
 * @returns {React.FunctionComponent<Props>}
 */
export const DialogProvider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ context, children }) => {

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState(DEFAULT_DIALOG_OPTIONS)
  const {
    className,
    title,
    message,
    element,
    confirmColor: okColor,
    cancelColor,
    hideOk,
    hideCancel,
    confirmText: okText,
    cancelText,
    onConfirm,
    onCancel,
    fullScreen,
    fullWidth,
    maxWidth
  } = options

  const html = {
    __html: message || ''
  }

  const showActions = !(hideOk && hideCancel)

  const confirmHandler = (options: IDialogOptions) => {
    setOptions(options)
    setOpen(true)
  }

  const alertHandler = (options: IAlertOptions) => {
    setOptions({
      ...options,
      confirmColor: options.color,
      hideOk: true,
      hideCancel: true
    })
    setOpen(true)
  }

  const messageHandler = (options: IMessageOptions) => {
    setOptions({
      ...options,
      confirmColor: options.color,
      hideCancel: true
    })
    setOpen(true)
  }

  const onConfirmHandler = () => {
    setOpen(false)
    if (onConfirm) {
      onConfirm()
    }
  }

  const onCancelHandler = () => {
    setOpen(false)
    if (onCancel) {
      onCancel()
    }
  }

  const provider: IDialogContext = {
    openConfirm: confirmHandler,
    openAlert: alertHandler,
    openMessage: messageHandler,
    close: onCancelHandler
  }

  if (context) {
    context(provider)
  }

  return (
    <Box>
      <DialogContext.Provider value={provider}>
        {children}

        <Dialog
          fullWidth={fullWidth || DEFAULT_FULL_WIDTH}
          fullScreen={fullScreen || DEFAULT_FULL_SCREEN}
          maxWidth={maxWidth || DEFAULT_MAX_WIDTH}
          open={open}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          {
            title &&
            <DialogTitle
              id="dialog-title"
            >
              {title}
            </DialogTitle>
          }
          {
            message &&
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                <span dangerouslySetInnerHTML={html}/>
              </DialogContentText>
            </DialogContent>
          }
          {
            element &&
            <DialogContent>
              {element}
            </DialogContent>
          }
          <Divider/>
          {
            showActions &&
            <DialogActions>
              {
                !hideCancel &&
                <Button
                  color={DEFAULT_CANCEL_COLOR}
                  onClick={onCancelHandler}
                >
                  {cancelText || DEFAULT_CANCEL_TEXT}
                </Button>
              }
              {
                !hideOk &&
                <Button
                  color={DEFAULT_OK_COLOR}
                  onClick={onConfirmHandler}
                >
                  {okText || DEFAULT_OK_TEXT}
                </Button>
              }
            </DialogActions>
          }
        </Dialog>
      </DialogContext.Provider>
    </Box>
  )
}

export interface IAlertOptions {
    className?: string
    title?: string
    message?: string
    element?: React.ReactElement
    color?: PropTypes.Color
    fullWidth?: boolean
    fullScreen?: boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  }

  export interface IDialogContext {
    openConfirm: (options: IDialogOptions) => void
    openAlert: (options: IAlertOptions) => void
    openMessage: (options: IMessageOptions) => void
    close: () => void
  }
  
  const DEFAULT_CONTEXT: IDialogContext = {
    openConfirm: (options: IDialogOptions) => {
    },
    openAlert: (options: IAlertOptions) => {
    },
    openMessage: (options: IMessageOptions) => {
    },
    close: () => {
    }
  }

  export interface IDialogOptions {
    className?: string
    title?: string
    message?: string
    element?: React.ReactElement
    confirmColor?: PropTypes.Color
    cancelColor?: PropTypes.Color
    hideOk?: boolean
    hideCancel?: boolean
    confirmText?: string
    cancelText?: string
    onConfirm?: () => void
    onCancel?: () => void
    fullWidth?: boolean
    fullScreen?: boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  }
  
  export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {}
  
  export const DialogContext = React.createContext(DEFAULT_CONTEXT)

  export interface IMessageOptions {
    className?: string
    title?: string
    message?: string
    element?: React.ReactElement
    color?: PropTypes.Color
    confirmText?: string,
    cancelText?: string,
    onConfirm?: () => void
    fullWidth?: boolean
    fullScreen?: boolean
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  }

  /**
   * A React-compatible hook that allows us to declaratively open,
   * manage, and close dialogs.
   * @returns {Function}
   */
  export const useDialog = () => {
    return useContext(DialogContext)
  }

