import React from 'react'
import {Attribute, AttributeFromJSON} from "ftm-api-client";
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import NativeSelect from "@mui/material/NativeSelect";
import Box from '@mui/material/Box'

const AttributeForm = (props: AttributeFormProps) => {

    const [isNameValid, setIsNameValid] = React.useState(props.initialAttribute ? props.initialAttribute.name !== "" : false)

    const [attribute, setAttribute] = React.useState(props.initialAttribute || AttributeFromJSON({
        name: "",
        description: "",
        type: "text"
    }))

    const handleFieldChanged = (fieldName: any, newValue: any) => {
        const newAttribute = {...attribute, [fieldName]: newValue}
        setAttribute(newAttribute);
        if (fieldName === 'name') setIsNameValid(newValue !== '')
    }

    const onSubmit = () => {
        if (props.onSubmit) props.onSubmit(attribute);
    }

    return (<>
        <Card>
            <CardContent>
                <Typography><strong>Name</strong></Typography>
                <TextField
                    onBlur={(e) => { handleFieldChanged('name', e.target.value) }}
                    error={!isNameValid}
                    fullWidth
                    defaultValue={props.initialAttribute ? props.initialAttribute.name : ""}
                    placeholder={'Name'}
                />
                <br/>
                <br/>
                <Typography><strong>Data Type</strong></Typography>
                <Box style={{border: '1px solid black'}}>
                    <CardContent>
                    <NativeSelect
                        onBlur={(e) => { handleFieldChanged('type', e.target.value) }}
                        fullWidth
                        defaultValue={props.initialAttribute ? props.initialAttribute.type : "text"}
                    >
                        <option key={'text'} value={'text'}>text</option>
                        <option key={'range'} value={'range'}>range</option>
                        <option key={'dropdown'} value={'dropdown'}>dropdown</option>
                        <option key={'number'} value={'number'}>number</option>
                        <option key={'boolean'} value={'boolean'}>boolean</option>
                    </NativeSelect>
                    </CardContent>
                </Box>
                <br/>
                <Typography><strong>Description</strong></Typography>
                <TextField
                    onBlur={(e) => { handleFieldChanged('description', e.target.value) }}
                    fullWidth
                    multiline
                    minRows={5}
                    defaultValue={props.initialAttribute ? props.initialAttribute.description : ""}
                    placeholder={'Description'}
                />
                <br/>
                <br/>
            </CardContent>
        </Card>
            <br/>
            <Button onClick={onSubmit} disabled={!isNameValid} fullWidth variant={'contained'} color={'secondary'}>Submit</Button>
        </>
    )

}

interface AttributeFormProps {
    initialAttribute?: Attribute,
    onSubmit?: any,
}

export default AttributeForm;