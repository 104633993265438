import React from 'react'
import View from "../../components/global/Page";
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import Button from '@mui/material/Button'
import {HttpErrorFromJSON, ResponseCategoryFromJSON, Category, CategoriesApi, ProductTypesApi, Product, ProductType} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import AddCircle from '@mui/icons-material/AddCircle'
import Delete from '@mui/icons-material/Delete'
import Stack from '@mui/material/Stack'
import {useNavigate} from "react-router-dom";
import { useDialog } from '../../components/global/DialogProvider';
import { CategoriesApiAdapter, ProductTypesApiAdapter, ProductsApiAdapter } from '../../api/impl/adapters';
import ProductCategoryDetail from '../../components/developer/ProductCategoryDetail';
import Typography from '@mui/material/Typography'

const CategoriesScreen = () => {

    const categoriesApiAdapter = new CategoriesApiAdapter();

    const productTypesApiAdapter = new ProductTypesApiAdapter();

    const productsApiAdapter = new ProductsApiAdapter();

    const navigate = useNavigate();

    const dialogue = useDialog();

    const [activeCategory, setActiveCategory] = React.useState<any | null>(null);

    const [activeCategoriesAndTypes, setActiveCategoriesAndTypes] = React.useState<any[]>([]);

    const [products, setProducts] = React.useState<Product[]>([])

    const [loading, setLoading] = React.useState(true);

    const sortProductCategoriesAndTypesAlphabetically = (a: any, b: any): number => {
        const aLower = a.name.toLowerCase(), bLower = b.name.toLowerCase();

        if (aLower[0] < bLower[0]) return -1;

        else if (aLower[0] == bLower[0]) return 0;
        
        return 1;
    }

    React.useEffect(() => {
        initCategoryDetails(null);
    }, [])

    const handleCategoryClicked = async (category: Category) => {
        await initCategoryDetails(null, category);
    }

    const handleProductTypeClicked = (productType: ProductType) => {
        navigate('/products/search', {state:{productTypePid: productType.pid}})
    }

    /**
     * Initializes category details by fetching sub-categories and product types within the
     * specified category. Sorts the results alphabetically, fetches products associated within that
     * level, then sets the state of the title and breadcrumbs.
     * @param categoryPid 
     */
    const initCategoryDetails = async (categoryPid: string | null, newCategory?: Category): Promise<void> => {
        if (newCategory) {
            setActiveCategory(newCategory)
        } else if (categoryPid) {
            const category = await categoriesApiAdapter.find({q: `{"pid": "${categoryPid}"}`})
            if (category && category.length) setActiveCategory(category[0]);
        } else setActiveCategory(undefined);
        const pidOfCategory = newCategory ? `"${newCategory.pid}"` : categoryPid ? `"${categoryPid}"` : "null";
        let categoriesQ = `{"parentCategoryPid": ${pidOfCategory}}`, productTypesQ = `{"categoryPid": ${pidOfCategory}}`;
        const categories = await categoriesApiAdapter.findAll({q: categoriesQ});
            const productTypes = await productTypesApiAdapter.findAll({q: productTypesQ});
            if (!categories && !productTypes) {
                setActiveCategoriesAndTypes([]);
                setProducts([]);
            } else {
                let results: any[] = [], productTypePids: string[] = [];
                if (categories && categories.length) results = results.concat(categories);
                if (productTypes && productTypes.length) {
                    productTypes.map(productType => {
                        results.push(productType);
                        productTypePids.push(productType.pid);
                    })
                }
                results.sort(sortProductCategoriesAndTypesAlphabetically);
                setActiveCategoriesAndTypes(results);
                const products = await productsApiAdapter.findByFieldValues(productTypePids, "productTypePid");
                if (products && products.length) setProducts(products);
        }
        if (loading) setLoading(false);
    }

    const handleGoBack = () => {
        if (activeCategory) initCategoryDetails(activeCategory.parentCategoryPid)
    }

    /**
     * Handles action taken when a service is deleted.
     * @param pid 
     */
    const handleCategoryDelete = async (pid: string) => {
        dialogue.openConfirm({
            title: 'Delete Category?',
            message: 'Are you sure you wish to delete? This action is irreversible.',
            confirmText: 'OKAY',
            onConfirm: async () => {
                try {
                    await categoriesApiAdapter.delete(pid)
                    dialogue.openConfirm({
                        title: 'Category Deleted Successfully',
                        message: 'The category has been deleted.',
                        confirmText: 'OKAY',
                        onConfirm: () => {
                            initCategoryDetails(activeCategory.parentCategoryPid || null);
                        }
                    })
                } catch (e: any) {
                    const errResult = await e.response.json()
                    const error = HttpErrorFromJSON(errResult)
                    dialogue.openMessage({
                        title: "An Error Occurred",
                        message: errResult.userMessage,
                    })
                }      
            }
        })
    }

    return (<View>
        <Container maxWidth={'lg'}>
            <Header
                title={activeCategory ? activeCategory.name : 'Product Directory'}
                breadcrumbs={[
                    {
                        "title": "Home",
                        "path": "/"
                    },
                    {
                        "title": "Product Directory",
                        "path": "/categories"
                    }
                ]}
                action={<Stack direction={'row'} spacing={1}>
                    <Button disabled={!activeCategory || (activeCategory && (!activeCategoriesAndTypes || activeCategoriesAndTypes.length))} startIcon={<Delete/>} onClick={() => { handleCategoryDelete(activeCategory.pid) }} variant={'contained'} color={'error'}>Delete Category</Button>
                    <Button startIcon={<AddCircle/>} onClick={() => { navigate('/categories/add') }} variant={'contained'} color={'secondary'}>Add Category</Button>
                    <Button startIcon={<AddCircle/>} onClick={() => { navigate('/product_types/add') } } variant={'contained'} color={'secondary'}>Add Product Type</Button>
                </Stack>}
            />
            <Typography variant="body2" color='textSecondary'>
                {activeCategory ? activeCategory?.description || "No description provided." : ""}
            </Typography>
            <br/>
            <ProductCategoryDetail
                activeCategory={activeCategory}
                categoriesAndTypes={activeCategoriesAndTypes}
                onCategoryClicked={handleCategoryClicked}
                onProductTypeClicked={handleProductTypeClicked}
                onGoBack={handleGoBack}
                loading={loading}
            />
        </Container>
    </View>)
}

export default CategoriesScreen;