import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import NavigateNextRounded from '@mui/icons-material/NavigateNextRounded'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Info from '@mui/icons-material/InfoOutlined'

/**
 * Represents a re-usable header component that allows the user
 * to specify props for the header, breadcrumbs, and an action
 * button.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns {JSX.Element} 
 */
const Header = (props: HeaderProps): JSX.Element => {

    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = useState(props.alertMessage ? true : false)

    const handleCloseAlert = () => {
        setAlertOpen(false);
    }

    /**
     * Renders the breadcrumbs.
     */
    const renderBreadcrumbs: JSX.Element | undefined = props.breadcrumbs && (
        <Breadcrumbs maxItems={5} separator={<NavigateNextRounded fontSize="small" />}>
            {props.breadcrumbs.map(breadcrumb => (
                <Link onClick={() => {navigate(breadcrumb.path)}} sx={{'&:hover': {
                    cursor: 'pointer'
                }}} key={breadcrumb.title} underline="hover">
                    {breadcrumb.icon && breadcrumb.icon}
                    <Typography variant='body2'>{breadcrumb.title}</Typography>
                </Link>
            ))}
        </Breadcrumbs>
    )

  return (
    <div>
        {renderBreadcrumbs}
        <br/>
        <Grid container justifyContent={'space-between'} alignItems={'center'} maxWidth={'100%'}>
            <Grid item>
            <Typography variant='h4'>
                {props.title}
            </Typography>
            </Grid>
            {props.action && (
                <Grid item>
                    {props.action}
                </Grid>
            )}
        </Grid>
        {props.alertMessage &&  <Alert icon={<Info/>} color='info'>
                {props.alertTitle && <AlertTitle><strong>{props.alertTitle}</strong></AlertTitle>}
                    {props.alertMessage}
        </Alert>}
    </div>
  )

}

/**
 * Represents the props of the Header component.
 */
export interface HeaderProps {
    title: string,
    breadcrumbs?: HeaderBreadcrumbLink[],
    action?: JSX.Element,
    alertMessage?: string,
    alertTitle?: string
}

/**
 * Represents a Header breadcrumb link. To generate
 * breadcrumbs for a header, simply specify an array
 * of type HeaderBreadcrumbLink and pass it in as
 * props to the Header component.
 */
export interface HeaderBreadcrumbLink {
    title: string,
    path: string,
    icon?: JSX.Element
}

export default Header