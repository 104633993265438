import React from 'react'
import {Industry, IndustryFromJSON} from "ftm-api-client";
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from "@mui/material/Typography";
import Send from '@mui/icons-material/Send'

const IndustryForm = (props: IndustryFormProps) => {

    const {
        initialIndustry,
        onSubmit,
        loading
    } = props;

    /**
     * A state variable to store the state of the industry.
     */
    const [industry, setIndustry] = React.useState<Industry>(props.initialIndustry ? props.initialIndustry : IndustryFromJSON({
        name: "",
        description: "",
        naicsCode: null
    }))

    /**
     * Handles the submit action.
     */
    const handleSubmit = () => {
        if (onSubmit && initialIndustry) onSubmit(industry);
        else if (onSubmit) onSubmit(industry);
    }

    /**
     * Handles action when a field changes.
     * @param fieldName
     * @param newValue
     */
    const handleFieldChanged = (fieldName: string, newValue: any) => {
        if (fieldName === 'naicsCode' && newValue === "") {
            newValue = null;
        }
        const newIndustry = {...industry, [fieldName]: newValue}
        setIndustry(newIndustry)
    }

    return (<><Card variant={'outlined'}>
        <CardContent>
            <Typography><strong>Industry Name</strong></Typography>
            <TextField
                onChange={(event => { handleFieldChanged('name', event.target.value) })}
                disabled={loading}
                multiline
                defaultValue={props.initialIndustry ? props.initialIndustry.name : ""}
                fullWidth
                size={'small'}
                variant={'outlined'}
                placeholder={'Industry Name'}/>
            <br/>
            <br/>
            <Typography><strong>NAICS Code</strong></Typography>
            <TextField
                type={'number'}
                onChange={(event => { handleFieldChanged('naicsCode', event.target.value) })}
                disabled={loading}
                multiline
                defaultValue={props.initialIndustry ? props.initialIndustry.naicsCode : ""}
                fullWidth
                size={'small'}
                variant={'outlined'}
                placeholder={'NAICS Code'}/>
            <br/>
            <br/>
            <Typography><strong>Description</strong></Typography>
            <TextField
                onChange={(event => { handleFieldChanged('description', event.target.value) })}
                disabled={loading}
                defaultValue={props.initialIndustry ? props.initialIndustry.description : ""}
                fullWidth
                multiline
                minRows={5}
                size={'small'}
                variant={'outlined'}
                placeholder={'Enter a description for this industry'}/>
        </CardContent>
    </Card>
    <br/>
        <LoadingButton fullWidth loading={loading} onClick={handleSubmit} startIcon={<Send/>} variant={'contained'}>Submit</LoadingButton>
    </>)

}

interface IndustryFormProps {

    initialIndustry?: Industry,

    loading?: boolean,

    onSubmit?: (newIndustry: Industry, industryPid?: string) => Promise<void>,
}

export default IndustryForm;