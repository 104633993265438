import axios from "axios";
import AppConfig from "../application/util/Configuration";

/**
 * The Authentication helper class manages interactions with user credentials
 * and the authenticity of locally stored tokens. 
 */
class Authentication {

    /**
     * Checks local storage from an entry with key 'token'. If it exists,
     * decodes the token and verifies from the payload whether the user
     * credentials are valid.
     * @returns {boolean}
     */
    checkCredentials(): boolean {

        /**
         * Represents the auth credentials.
         */
        let authCredentials: string | null = localStorage.getItem('token')
        

        if (!authCredentials) {
            return false;
        }

        let payload;

        // Verify that the value of the token is a JSON.
        try {
            payload = JSON.parse(window.atob(authCredentials.split('.')[1]))
        } catch (error) {
            this.deAuthenticate();
            return false;
        }

        // console.log('Date now: ' + Date.now())
        // console.log('expiration: ' + (payload['exp'] * 1000))

        // console.log(Date.now() > (payload['exp'] * 1000))

        // Verify the token has not expired.
        if (Date.now() > (payload['exp'] * 1000)) {
            this.deAuthenticate()
            return false;
        }

        return true;
    }

    /**
     * Decodes the auth JWT, returns a JSON containing
     * only the payload.
     * @returns {Object} the decoded token payload
     */
    jwtDecodePayload(token: string): Object {

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        
            return JSON.parse(jsonPayload);
    }

    /**
     * Retrieves the privileges of the active user.
     * @returns {string} the privileges of the user, to a string
     */
    getPrivileges(): string {
        if (!localStorage.getItem('token')) return 'error'
        else {
            try {
                let result: any = this.jwtDecodePayload(this.getToken() as string);
                return result.privilegeName
            } catch (error) {
                return 'error'
            }

            return 'error'
        }
    }

    /**
     * Retrieves the user's id from local storage.
     * @returns {string | 'error'} the id of the user
     */
    getId(): string {
        if (!localStorage.getItem('token')) return 'error'
        else {
            try {
                let result: any = this.jwtDecodePayload(this.getToken() as string);
                return result.id
            } catch (error) {
                return 'error'
            }

            return 'error'
        }
    }

    /**
     * Represents whether the user is authenticated.
     * @returns {boolean}
     */
    isAuthenticated(): boolean {
        // This should 1) check to verify 'token' is in local storage and
        // 2), verify that the 'exp' field in the JWT payload has not expired.
        return this.checkCredentials() || false
    }

    /**
     * De-authenticates the user.
     */
    deAuthenticate(): void {
        localStorage.removeItem('token');
    }

    /**
     * Gets the token from local storage.
     * @returns {string | null} token the token
     */
    getToken(): string | null {
        return localStorage.getItem('token');
    }

}

export default new Authentication();