import React from 'react'
import { HttpErrorFromJSON, IndustriesApi, Industry, Organization, OrganizationsApi, ResponseIndustryFromJSON } from 'ftm-api-client'
import { getGlobalConfig } from '../../api/Api'
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import View from '../../components/global/Page'
import Header from '../../components/global/Header'
import OrganizationForm from '../../components/developer/OrganizationForm'
import { useDialog } from '../../components/global/DialogProvider'

/**
 * Represents a screen allowing an administrative user to add a new
 * organization.
 * @returns {JSX.Element}
 */
const OrganizationAddScreen = () => {

    // Represents the config.
    const config = getGlobalConfig()

    /**
     * Represents an instance of the organizations API instance.
     */
    const organizationsApi = new OrganizationsApi(config);

    const industriesApi = new IndustriesApi(config);

    const [industryPidToIndustry, setIndustryPidToIndustry] = React.useState<any>({})

    /**
     * Stores the react-router-dom navigation hook.
     */
    const navigate = useNavigate();

    const dialogue = useDialog();

    /**
     * Fetches industries in sequence and returns them by setting a PID to industry.
     * @param index index
     * @param previousIndustries 
     */
    const fetchData = async (index: number = 0, previousIndustries: Industry[] = []) => {
        try {
            const data = await industriesApi.getIndustriesRaw({
                sort: '^name',
                includeTotals: true,
                offset: index * 100,
                limit: 100
            })
            if (data) {
                const jsonLock = data.raw.json()
                jsonLock.then((response) => {
                    const industryResponse = ResponseIndustryFromJSON(response)
                    const total = data.raw.headers.get('x-total-count')
                    if (total && industryResponse && industryResponse.data) {
                        const totals = parseInt(total);
                        const newIndustries = previousIndustries.concat(industryResponse.data)
                        if (!isNaN(totals) && newIndustries.length < totals) {
                            fetchData(++index, newIndustries);
                        } else {
                            let newPidToIndustry: any = {}
                            newIndustries.map(industry => {
                                newPidToIndustry[industry.pid!] = industry;
                            })
                            setIndustryPidToIndustry(newPidToIndustry);
                        }
                    }
                })
            }
        } catch (error) {
            
        }
    }

    /**
     * Handles the form submit action.
     */
    const handleSubmit = async (newOrganization: Organization) => {
        try {
            const response = await organizationsApi.addOrganization({
                organization: newOrganization
            })
            if (response && response.pid) {
                dialogue.openConfirm({
                    title: 'Organization Created Successfully',
                    message: 'The organization has been successfully created.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/organizations');
                    }
                })
            }   
        } catch (error: any) {

            const er = await error.response.json()
            const errResponse = HttpErrorFromJSON(er);

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: errResponse ? errResponse.userMessage : "An unknown error occurred. Please wait 5 minutes and re-attempt your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    React.useEffect(() => {
        fetchData();
    }, [])

  return ( <View>
        <Container>
            <Header
                title="Create Organization"
                breadcrumbs={[
                    {
                        "title": "Home",
                        "path": "/"
                    },
                    {
                        "title": "Organizations",
                        "path": "/organizations"
                    },
                    {
                        "title": "Create Organization",
                        "path": "/organizations/create"
                    },
                ]}
            />
            <br/>
            <OrganizationForm
                industryPidToIndustry={industryPidToIndustry}
                onSubmit={handleSubmit}
            />
        </Container>
    </View>
  )
}


export default OrganizationAddScreen