import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import React, { useEffect } from 'react'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'
import ErrorOutlineTwoTone from '@mui/icons-material/ErrorOutlineTwoTone'
import Add from '@mui/icons-material/Add'
import ViewList from '@mui/icons-material/ViewList'
import ViewModule from '@mui/icons-material/ViewModule'
import ViewDay from '@mui/icons-material/ViewDay'
import Show from '../../components/global/Show'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { convertDate } from '../../application/util/utils'
import { useDialog } from '../../components/global/DialogProvider'
import { Configuration } from 'ftm-api-client'
import { defaultConfig } from '../../api/Api'

/**
 * This view displays to the user a galleries screen which allows
 * them to view and manage galleries they've been added to. The
 * view allows them to configure how they wish to view their galleries
 * (e.g. whether they wish to see thumbnails or full-size images) and
 * additionally allows employees to add new galleries.
 * @author chrisrinaldi
 * @date 27 March, 2022
 * @returns {JSX.Element}
 */
const GalleriesScreen = () => {

    /**
     * Represents the user selected view perspective
     * for the galleries (i.e. - list view, grid view).
     */
    const [view, setView] = React.useState<string>('module');


    /**
     * Represents an instance of the snackbar hook, allowing us
     * to push a new snackbar onto the context stack.
     */
    const { enqueueSnackbar } = useSnackbar();

    /**
     * A media-query hook that represents whether the user is viewing in mobile
     * responsive mode or not.
     */
    let mobileResponsive = useMediaQuery('(max-width:875px)');

    /**
     * Handles action when the view changes.
     * @param e 
     */
    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setView(nextView);
    }

    /**
     * Handles action taken when a gallery is selected for
     * deletion.
     * @param {string} id the id of the gallery to delete
     */
    const handleDeleteGallery = async (id: string) => {
        dialogue.openConfirm({
            title: 'Delete Gallery',
            message: 'Are you sure you wish to delete this gallery? This action cannot be undone.',
            confirmText: 'CONFIRM',
            onConfirm: async () => {

            }
        })
    }

    const navigate = useNavigate();

    const theme = useTheme();

    /**
     * Represents the dialog controller.
     */
    const dialogue = useDialog();

    /**
     * Fetches data from the API.
     */
    const fetchData = () => {
    }

    useEffect(() => {
        fetchData();
    }, [])


    /**
     * Represents the JSX displayed when no galleries are
     * found for the user.
     */
    const notFound = (
        <Box textAlign='center' sx={{ opacity: 0.5 }}>
            <ErrorOutlineTwoTone fontSize='large' />
            <Typography variant='h5' fontWeight={600}>Uh oh! Where am I?</Typography>
            <Typography color='text.secondary'>We couldn't find any galleries for your account. Check back later!</Typography>
        </Box>
    )

    const renderDummy = (
        <Grid container columnSpacing={5} rowSpacing={5}>
            {/*{galleries.map(gallery => (*/}
            {/*    <Grid item xs={mobileResponsive ? 12 : 4}>*/}
            {/*        <Card>*/}
            {/*            <CardHeader*/}
            {/*                avatar={*/}
            {/*                    <Avatar sx={{ bgcolor: theme.palette.primary.light }}>*/}
            {/*                        <Folder />*/}
            {/*                    </Avatar>}*/}
            {/*                action={*/}
            {/*                    <IconButton aria-label="settings">*/}
            {/*                        <MoreVert />*/}
            {/*                    </IconButton>*/}
            {/*                }*/}
            {/*                title={gallery.name}*/}
            {/*                subheader={convertDate(gallery!.createdAt!)}*/}
            {/*            />*/}
            {/*            <CardActionArea onClick={() => navigate(`/galleries/${gallery!.pid}`)}>*/}
            {/*                {view === 'module' && (<CardMedia*/}
            {/*                    component="img"*/}
            {/*                    alt={gallery.name}*/}
            {/*                    image={'https://source.unsplash.com/random/800x600'}*/}
            {/*                />)}*/}
            {/*            </CardActionArea>*/}
            {/*            <CardActions>*/}
            {/*                <Tooltip title="Share this gallery">*/}
            {/*                    <IconButton>*/}
            {/*                        <Share />*/}
            {/*                    </IconButton>*/}
            {/*                </Tooltip>*/}
            {/*                <Tooltip title="View gallery">*/}
            {/*                    <IconButton onClick={() => navigate(`/galleries/${gallery!.pid}`)}>*/}
            {/*                        <Visibility />*/}
            {/*                    </IconButton>*/}
            {/*                </Tooltip>*/}
            {/*                <Show privileges={['developer', 'employee']}>*/}
            {/*                <Tooltip title="Edit gallery">*/}
            {/*                        <IconButton onClick={() => { navigate(`/employee/galleries/${gallery!.pid}`) }}>*/}
            {/*                            <Edit />*/}
            {/*                        </IconButton>*/}
            {/*                    </Tooltip>*/}
            {/*                </Show>*/}
            {/*                <Show privileges={['developer', 'employee']}>*/}
            {/*                    <Tooltip title="Delete gallery">*/}
            {/*                        <IconButton onClick={() => { handleDeleteGallery(gallery!.pid!) }}>*/}
            {/*                            <Delete />*/}
            {/*                        </IconButton>*/}
            {/*                    </Tooltip>*/}
            {/*                </Show>*/}
            {/*            </CardActions>*/}
            {/*        </Card>*/}
            {/*    </Grid>*/}
            {/*))}*/}
        </Grid>
    )

    return (

        <View>
            <Container maxWidth='lg'>
                <Header
                    title="My Galleries"
                    breadcrumbs={[
                        {
                            title: 'Home',
                            path: '/'
                        },
                        {
                            title: 'Galleries',
                            path: '/galleries'
                        }
                    ]}
                    action={
                        <Show privileges={['employee', 'developer']}>
                            <Button startIcon={<Add />} color='secondary' variant='contained' onClick={() => navigate('/employee/gallery/create')}>
                                Add Gallery
                            </Button>
                        </Show>
                    }
                />
                <br />
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="module" aria-label="module">
                        <ViewModule />
                    </ToggleButton>
                    <ToggleButton value="list" aria-label="list">
                        <ViewList />
                    </ToggleButton>
                    <ToggleButton value="agenda" aria-label="agenda">
                        <ViewDay />
                    </ToggleButton>
                </ToggleButtonGroup>
                <br />
                <br />
            </Container>
        </View>
    )
}


export default GalleriesScreen