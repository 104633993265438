/**
 * Represents the names of the month - used to parse the created at
 * date to a more readable format.
 */
export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

/**
 * Converts the specified date to 
 * @param date 
 * @returns 
 */
export function convertDate(dateToConvert: Date) {
    let date = new Date(dateToConvert);
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let year = date.getFullYear();
    return month + ' ' + day + ', ' + year;
}

export const MAX_CHUNK_SIZE = 50;

/**
 * Breaks PIDs into chunks. The chunks are groupings of $or filters to be passed to the
 * collection. These can be cycled thru to ensure that any query containing field name filters
 * that are greater than the default query limit can be used. Note that duplicates are not
 * trimmed.
 * @param {string[]} pids represents the pids to pass
 * @param {string} fieldName represents the field name to filter by
 * @param {number} chunkSize represents the size of each PID grouping
 */
export function createPidFilterChunks(pids: string[], fieldName: string = "pid", chunkSize: number = MAX_CHUNK_SIZE): string[] {
    const numberOfChunks = Math.ceil(pids.length / chunkSize);
    let pidFilterChunks = [];
    let qPart = `{"${fieldName}": `
    for (let i = 0; i < numberOfChunks; i++) {
        let qChunk = qPart
        qChunk += `{"$in": [`
        let endIndex: number;
        if (((chunkSize * i) + chunkSize) > pids.length) endIndex = pids.length
        else endIndex = ((chunkSize * i) + chunkSize)
        let pidsArray = pids.slice(i * chunkSize, endIndex)
        for (let j = 0; j < pidsArray.length; j++) {
            qChunk += `"${pidsArray[j]}"`
            if (j < pidsArray.length - 1) qChunk += ','
        }
        qChunk += `]}}`
        pidFilterChunks.push(qChunk);
    }
    return pidFilterChunks;
}