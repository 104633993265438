import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import NativeSelect from '@mui/material/NativeSelect'
import {Category, CategoryFromJSON} from "ftm-api-client";

const ProductCategoryForm = (props: ProductCategoryFormProps) => {

    const [productCategory, setProductCategory] = React.useState(props.initialProductCategory || CategoryFromJSON({
        name: "",
        description: "",
        parentProductCategoryPid: null
    }))

    const [isNameValid, setIsNameValid] = React.useState(props.initialProductCategory ? props.initialProductCategory.name !== "" : false)

    const handleFieldChanged = (fieldName: any, newValue: any) => {
        if (fieldName === 'parentCategoryPid') {
            let newProductCategory: any = {...productCategory, [fieldName]: null}
            if (newValue in props.productCategoryNameToProductCategory) {
                newProductCategory[fieldName] = props.productCategoryNameToProductCategory[newValue].pid
            }
            setProductCategory(newProductCategory)
        } else {
            let newProductCategory = {...productCategory, [fieldName]: newValue}
            setProductCategory(newProductCategory)
            if (fieldName === 'name') {
                setIsNameValid(newValue !== '')
            }
        }
    }

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(productCategory)
        }
    }

    return (<><Card>
        <CardContent>
            <Typography><strong>Name</strong></Typography>
            <TextField
                onBlur={(e) => { handleFieldChanged('name', e.target.value) }}
                error={!isNameValid}
                multiline
                defaultValue={props.initialProductCategory ? props.initialProductCategory.name : ""}
                fullWidth
                size={'small'}
                variant={'outlined'}
                placeholder={'Name'}
            />
            <br/>
            <br/>
            <Typography><strong>Parent Product Category</strong></Typography>
            <Box style={{border: '1px solid black'}}>
                <CardContent>
                    <NativeSelect
                        onBlur={(e) => { handleFieldChanged('parentCategoryPid', e.target.value) }}
                        fullWidth
                    >
                        <option value={'none'} key={'none'}>Select parent product category</option>
                        {Object.keys(props.productCategoryNameToProductCategory).map((productCategoryName) => (
                            <option key={productCategoryName} value={productCategoryName}>{productCategoryName}</option>
                        ))}
                    </NativeSelect>
                </CardContent>
            </Box>
            <br/>
            <Typography><strong>Description</strong></Typography>
            <TextField
                onBlur={(e) => { handleFieldChanged('description', e.target.value) }}
                fullWidth
                multiline
                minRows={5}
                placeholder={"Description"}
            />
        </CardContent>
    </Card>
    <br/>
        <Button onClick={onSubmit} disabled={!isNameValid} fullWidth variant={'contained'} color={'secondary'}>Submit</Button>
    </>)

}

interface ProductCategoryFormProps {
    initialProductCategory?: Category,
    productCategoryNameToProductCategory?: any,
    onSubmit?: any,
}

export default ProductCategoryForm;