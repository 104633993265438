import React from 'react'
import {AttributeDropdownValueFromJSON, AttributeNumberValueFromJSON, AttributeRangeValueFromJSON, AttributeValue, AttributeValueFromJSON, ProductType, ProductTypeFromJSON} from "ftm-api-client";
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import TextField from '@mui/material/TextField'
import AttributeInputForm from './AttributeInputForm';
import AddCircle from '@mui/icons-material/AddCircle'
import Divider from '@mui/material/Divider'
import NativeSelect from '@mui/material/NativeSelect'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { v4 as uuidv4 } from 'uuid'

/**
 * Represents a form in which a user can identify a model for which a product
 * of a certain kind should abide by.
 * @param props
 * @constructor
 */
const ProductTypeForm = (props: ProductTypeFormProps) => {

    /**
     * Stores the state of the product type.
     */
    const [productType, setProductType] = React.useState<ProductType>(props.initialProductType ? props.initialProductType : ProductTypeFromJSON({
        name: "",
        description: "",
        attributeValues: []
    }))

    /**
     * Represents whether the product type is valid for submission.
     */
    const [isProductTypeValid, setIsProductTypeValid] = React.useState(props.initialProductType ? true : false);

    /**
     * Represents a mapping of a UUIDv4 to an attributeValue to be constructed.
     */
    const [attributeUuidToValue, setAttributeUuidToValue] = React.useState({});

    /**
     * Checks whether the product type is valid (i.e., has a name and each
     * of the attribute fields are properly filled out).
     */
    const checkProductTypeValidity = (attributeUuidToValue: any) => {
        if (productType.name === "") return setIsProductTypeValid(false);
        if (attributeUuidToValue) {
            let isFormValid = true;
            Object.values(attributeUuidToValue).map((attributeValue: any) => {
                if (!attributeValue.attributeInputIsValid) {
                    isFormValid = false;
                }
            })
            setIsProductTypeValid(isFormValid);   
        }
    }

    /**
     * Initializes the attribute UUID to value.
     */
    const initAttributeUuidToValue = () => {
        if (props.initialProductType) {
            let newAttributeUuidToValue: any = {}
            if (props.initialProductType.attributeValues) {
                props.initialProductType.attributeValues.map(attributeValue => {
                    if (props.attributePidToAttribute && attributeValue.attributePid in props.attributePidToAttribute) {
                        const uuid = uuidv4()
                        newAttributeUuidToValue[uuid] = attributeValue;
                        newAttributeUuidToValue[uuid].attributeInputIsValid = true
                    }
                })
                setAttributeUuidToValue(newAttributeUuidToValue);
            }
        }
    }

    /**
     * Handles action taken when a new attributeInput is added.
     */
    const handleNewAttributeInput = () => {
        let newAttributeUuidToValue: any = {...attributeUuidToValue};
        newAttributeUuidToValue[uuidv4()] = {
            attributePid: 'none',
            value: {},
            attributeInputIsValid: false
        }
        setAttributeUuidToValue(newAttributeUuidToValue);
        setIsProductTypeValid(false);
    }

    /**
     * Handles action taken when an attribute input form value has changed.
     * @param attributeUuid 
     * @param newValue 
     */
    const handleAttributeInputChanged = (attributeUuid: string, newValue: AttributeValue) => {
        let newAttributeUuidToValue: any = {...attributeUuidToValue};
        newAttributeUuidToValue[attributeUuid] = newValue;
        newAttributeUuidToValue[attributeUuid].attributeInputIsValid = newValue.attributePid !== 'none';
        setAttributeUuidToValue(newAttributeUuidToValue);
        checkProductTypeValidity(newAttributeUuidToValue);
    }

    /**
     * Handles action taken when an attribute input has been deleted.
     * @param attributeUuid the uuid of the attribute input
     */
    const handleAttributeInputDelete = (attributeUuid: string) => {
        let newAttributeUuidToValue: any = {...attributeUuidToValue};
        delete newAttributeUuidToValue[attributeUuid];
        setAttributeUuidToValue(newAttributeUuidToValue);
        checkProductTypeValidity(newAttributeUuidToValue);
    }

    /**
     * Handles action when a field changes.
     * @param fieldName 
     * @param newValue 
     */
    const handleFieldChanged = (fieldName: string, newValue: any) => {
        const newProductType = {...productType, [fieldName]: newValue};
        setProductType(newProductType);
        if (fieldName === 'name') {
            setIsProductTypeValid(newValue !== '');
        }
    }

    /**
     * Handles action taken when submit button was clicked.
     */
    const handleSubmitClicked = () => {
        if (props.onSubmit) {
            let newAttributeValues: any[] = []; 
            let newProductType = {...productType}
            Object.values(attributeUuidToValue).map((attributeValue: any) => {
                if (attributeValue.attributeInputIsValid) {
                    delete attributeValue['attributeInputIsValid'];
                    newAttributeValues.push(attributeValue);
                }
            })
            newProductType.attributeValues = newAttributeValues;
            
            props.onSubmit(newProductType);
        }
    }

    React.useEffect(() => {
        initAttributeUuidToValue();
    }, [])

    // @ts-ignore
    return (
        <>
            <Card variant={'outlined'}>
                <CardContent>
                    <TextField
                    disabled={props.loading} 
                    onChange={(e) => { handleFieldChanged('name', e.target.value) }}
                    defaultValue={props.initialProductType && props.initialProductType.name ? props.initialProductType!.name : ""} error={productType.name === ""} fullWidth label={'Name'}/>
                    <br/>
                    <br/>
                    <TextField disabled={props.loading} multiline minRows={3} fullWidth label={'Description'}/>
                    <br/>
                    <br/>
                    <Typography><strong>Category</strong></Typography>
                    <Box style={{border: '1px solid black'}}>
                        <CardContent>
                            <NativeSelect
                                disabled={props.loading}
                                onBlur={(e) => { handleFieldChanged('categoryPid', e.target.value) }}
                                defaultValue={productType.categoryPid}
                                fullWidth
                            >
                                <option value={'none'} key={'none'}>Select category</option>
                                {Object.keys(props.categoryPidToCategory).map((categoryPid) => (
                                    <option key={categoryPid} value={categoryPid}>{props.categoryPidToCategory[categoryPid].name}</option>
                                ))}
                            </NativeSelect>
                        </CardContent>
                    </Box>
                    <br/>
                    <br/>
                    <strong>Attributes</strong>
                    <br/>
                    <br/>
                    {Object.entries(attributeUuidToValue).map(([attributeUuid, attributeValue]) => (
                        <>
                        <AttributeInputForm
                        attributeUuid={attributeUuid}
                        attributePidToAttribute={props.attributePidToAttribute}
                        attributeNameToAttribute={props.attributeNameToAttribute}
                        initialAttributeValue={attributeValue}
                        onDelete={handleAttributeInputDelete}
                        onChange={handleAttributeInputChanged}
                        isProductForm={false}
                    />
                    <br/>
                    </>
                    ))}
                    <br/>
                    <Button onClick={handleNewAttributeInput} startIcon={<AddCircle/>} color='secondary' variant='contained'>New Property</Button>
                </CardContent>
                <Divider/>
            </Card>
            <br/>
            <LoadingButton loading={props.loading} onClick={handleSubmitClicked} disabled={!isProductTypeValid} fullWidth variant={'contained'}>Submit</LoadingButton>
        </>
    )

}

export default ProductTypeForm;

export interface ProductTypeFormProps {
    initialProductType?: ProductType,
    attributePidToAttribute?: any,
    attributeNameToAttribute?: any,
    categoryPidToCategory?: any,
    onSubmit?: Function,
    loading?: boolean,
}