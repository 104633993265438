import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * The UserProfileScreen is a view that displays an expanded view of the user's
 * profile including their contact information. Additionally, you can view the
 * respective galleries that the user is a member of.
 * @returns {JSX.Element}
 */
const UserProfileScreen = () => {

    /**
     * Represents the current user.
     */
    const [user, setUser] = React.useState<any>();

    /**
     * Represents the privilege associated with the user.
     */
    const [privilege, setPrivilege] = React.useState<any>();

    /**
     * Represents the navigation function.
     */
    const navigate = useNavigate();

    /**
     * Represents the current location.
     */
    const location = useLocation();

    /**
     * Fetches the user whose profile we are attempting
     * to display.
     */
    const fetchUsers = async () => {
    }

    useEffect(() => {
    }, [])

  return ( <View>
      <Container maxWidth="lg">
          <Header title='User Profile' breadcrumbs={[
              {
                    title: 'Home',
                    path: '/'
              },
              {
                    title: 'Employee',
                    path: '/employee/'
              },
                {
                    title: 'Users',
                    path: '/employee/clients'
                }]}/>
                <br/>
      </Container>
  </View>
  )
}


export default UserProfileScreen