import React from 'react'
import { AttributesApiAdapter, ProductTypesApiAdapter } from '../../api/impl/adapters'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import InputField from './InputField'
import Tune from '@mui/icons-material/Tune'
import { useDialog } from './DialogProvider'
import Badge from '@mui/material/Badge'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

/**
 * Allows a user to search by any attribute value and retrieve a set of results.
 * @returns {JSX.Element}
 */
const ParametricSearchFilters = (props: ParametricSearchProps): JSX.Element => {

    const attributesApi = new AttributesApiAdapter();

    const productTypesApi = new ProductTypesApiAdapter();

    const [attributePidToAttribute, setAttributePidToAttribute] = React.useState<any>({})

    const [attributePidToFilter, setAttributePidToFilter] = React.useState<any>({});

    const [attributePidToDropdownValues, setAttributePidToDropdownValues] = React.useState<any>({});

    const [filterCount, setFilterCount] = React.useState(0);

    const dialog = useDialog();

    const fetchData = async () => {
        try {
            const productType = await productTypesApi.findByPid(props.productTypePid);
            console.log(props.productTypePid);
            if (productType) {
                let attributePids: string[] = []
                let newAttributePidToDropdownValues: any = {}
                productType.attributeValues.map((attributeValue: any) => {
                    attributePids.push(attributeValue.attributePid)
                    if (attributeValue.value.options && attributeValue.value.options.length) {
                        newAttributePidToDropdownValues[attributeValue.attributePid] = attributeValue.value.options;
                    }
                })
                const attributesData = await attributesApi.findByPids(attributePids);
                if (attributesData && attributesData.length) {
                    let newAttributePidToAttribute: any = {}, newAttributePidToFilter: any = {}
                    attributesData.map(attribute => {
                        newAttributePidToAttribute[attribute.pid] = attribute;
                        newAttributePidToFilter[attribute.pid] = null;
                    })
                    setAttributePidToAttribute(newAttributePidToAttribute);
                    setAttributePidToFilter(newAttributePidToFilter);
                    setAttributePidToDropdownValues(newAttributePidToDropdownValues);
                }
            }
        } catch (error) {

        }
    }

    const handleDialogOpen = () => {
        dialog.openConfirm({
            title: "Filters",
            maxWidth: 'lg',
            element: (
                <Card variant='outlined' sx={{padding: 2, backgroundColor: (theme) => theme.palette.mode == 'light' ? 'neutral.50' : 'neutral.900',}}>
                <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                    <Grid container spacing={2}>
                        {Object.keys(attributePidToAttribute).map(attributePid => (
                            <Grid
                                xs={12}
                                md={6}
                                lg={4}
                                height={'100%'}
                                item>
                                <Card style={{ height: '100%' }}>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
                                        <Checkbox /><strong>{attributePidToAttribute[attributePid].name}</strong>
                                    </div>
                                    <Divider />
                                    <CardContent>
                                        {renderPropertySearchInputForAttribute(attributePid)}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Card>
            )
        })
    }

    /**
     * Renders a parametric input for the attribute.
     * @param attributePid 
     */
    const renderPropertySearchInputForAttribute = (attributePid: string) => {

        if (attributePidToAttribute[attributePid]) {
            switch (attributePidToAttribute[attributePid].type) {
                case "boolean":
                    return <div>
                        <InputField
                            select
                            fullWidth
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </InputField>
                    </div>
                case "number":
                case "range":
                    return <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField type={'number'} size='small' placeholder='Min value' />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField type={'number'} size='small' placeholder='Max value' />
                        </Grid>
                    </Grid>
                case "dropdown":
                    return <div style={{ maxHeight: '100px', overflow: 'scroll' }}>
                        <InputField
                            select
                            fullWidth
                        >
                            {attributePidToDropdownValues[attributePid] && attributePidToDropdownValues[attributePid].map((dropdownValue: any) => (
                                    <MenuItem key={dropdownValue} value={dropdownValue}>
                                        {dropdownValue}
                                    </MenuItem>
                                ))}
                        </InputField>
                    </div>
            }
        }

    }

    React.useEffect(() => {
        fetchData();
    }, [])


    return (
        <div>
        <Card>
                    <CardContent>
                    <Typography variant='h5'>Results: {props.resultCount || 0}</Typography>
                <br />
            <Grid container spacing={2}>
                <Grid item xs={10}>
                <InputField
                InputProps={{
                    startAdornment: <InputAdornment position='start'><Search/></InputAdornment>
                }}
                style={{marginBottom: 20}}
                fullWidth
                placeholder="Search products..."
            />
                </Grid>
                <Grid item xs={2}>
                    <Button startIcon={<Badge color='error' badgeContent={4}><Tune/></Badge>}>Clear Filters</Button>
                </Grid>
            </Grid>
            <br/>
            <Card variant='outlined' sx={{padding: 2, backgroundColor: (theme) => theme.palette.mode == 'light' ? 'neutral.50' : 'neutral.800',}}>
                <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                    <Grid container spacing={1}>
                        {Object.keys(attributePidToAttribute).map(attributePid => (
                            <Grid
                                xs={12}
                                md={6}
                                lg={4}
                                height={'100%'}
                                item>
                                <Card style={{ height: '100%' }}>
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
                                        <Checkbox /><strong>{attributePidToAttribute[attributePid].name}</strong>
                                    </div>
                                    <Divider />
                                    <CardContent>
                                        {renderPropertySearchInputForAttribute(attributePid)}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Card>
            <br/>
            <Button disabled={props.loading} fullWidth variant='contained' color='secondary'>Search</Button>
        </CardContent>
        </Card>
        </div>
    )
}

interface ParametricSearchProps {
    productTypePid: string,
    resultCount?: number,
    loading?: boolean,
}

export default ParametricSearchFilters;