import Container from '@mui/material/Container'
import React from 'react'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * Represents a screen at which a user may edit an existing
 * event type.
 * @author chrisrinaldi
 * @since 19 June, 2022
 * @returns {JSX.Element}
 */
const EditEventTypeScreen = () => {

    /**
     * Represents the package to edit.
     */
    const [eventType, setEventType] = React.useState<any>();

    /**
     * Parses the URL parameters to retrieve the ID of the package 
     * to edit.
     */
    let packageId = window.location.pathname.split('/')[3];

    React.useEffect(() => {
    }, [])

  return (
    <View>
        <Container maxWidth='lg'>
            <Header title='Edit Package'
            breadcrumbs={[
                {
                    title: 'Home',
                    path: '/'
                },
                {
                    title: 'Employee',
                    path: '/employee/'
                },
                {
                    title: 'Manage Packages',
                    path: '/employee/packages'
                },
                {
                    title: 'Edit Package',
                    path: `/employee/packages/${packageId}`
                }
            ]}
            />
            <br/>
        </Container>
    </View>
  )
  
}


export default EditEventTypeScreen