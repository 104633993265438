import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import AccountBox from '@mui/icons-material/AccountBox'
import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu'
import Authentication from '../../api/Authentication'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "../../application/redux/hooks";
import { initProfile, logoutUser } from "../../application/redux/slices/users-profile";
import Avatar from "@mui/material/Avatar";
import logo from '../../logo.png'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SearchBar from '../../components/global/SearchBar'
import {useMsal} from "@azure/msal-react";
import Configuration from "../../application/util/Configuration";
import LightMode from "@mui/icons-material/LightMode";
import DarkMode from "@mui/icons-material/DarkMode";

/**
 * The AppBar is a controller that appears at the top of the screen to
 * manage user interaction.
 * @author chrisrinaldi
 * @since 20 March, 2022
 **/
const Appbar = (props: { navOpen: boolean, setNavOpen: any, darkMode: string, toggleDarkMode: Function }) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!Authentication.isAuthenticated()) return
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {instance} = useMsal();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(state => state.profile)

  React.useEffect(() => {
    initProfile(dispatch);
  }, [])

  return (
    <AppBar position="fixed" sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'primary.700',
        color: '#FFFFFF'
    }}>
      <Toolbar>
        {isMobile && <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => {
            if (Authentication.isAuthenticated()) {
              props.setNavOpen(!props.navOpen)
            }
          }}
        >
          <MenuIcon />
        </IconButton>}
        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Analytics
          </Typography> */}
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, paddingTop: 'auto', paddingBottom: 'auto' }}>
          <img src={logo} height={25} />
        </div>
        <div>
          <Box
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              ml: 2
            }}
            >
            {profile && profile.pid && <SearchBar disabled={!profile}/>}
            <Box
                onClick={handleMenu}
                sx={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                }}
            >
              <Avatar
                  src={profile.imgUrl!}
                  variant="rounded"
                  sx={{
                    height: 40,
                    width: 40
                  }}
              />
              <Box
                  sx={{
                    alignItems: 'center',
                    display: {
                      md: 'flex',
                      xs: 'none'
                    },
                    flex: 1,
                    ml: 1,
                    minWidth: 120
                  }}
              >
                <div>
                  <Typography
                      sx={{ color: 'primary.contrastText' }}
                      variant="caption"
                  >
                    {profile?.organization?.name}
                  </Typography>
                  <Typography
                      sx={{ color: 'primary.contrastText' }}
                      variant="subtitle2"
                  >
                    {(!profile.pid) ? "Not signed in" : profile?.firstName + ' ' + profile?.lastName}
                  </Typography>
                </div>
                {profile.pid && <ChevronDownIcon
                    sx={{
                      color: 'primary.contrastText',
                      ml: 1
                    }}
                />}
              </Box>
            </Box>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              navigate('/');
              handleClose();
            }}>
              <ListItemIcon>
                <AccountBox fontSize='small' />
              </ListItemIcon>
              Home
            </MenuItem>
            <MenuItem onClick={() => {
              props.toggleDarkMode()
              handleClose()
            }}>
              <ListItemIcon>
                {theme.palette.mode === 'light' ? <DarkMode fontSize='small'/> : <LightMode fontSize={'small'}/>}
              </ListItemIcon>
              Toggle dark theme
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
              handleClose();
              navigate('/settings')
            }}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={() => {
              handleClose();
              logoutUser(dispatch);
              if (Configuration.AuthenticationMethod === 'azure') instance.logout();
              navigate('/')
            }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sign out
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar