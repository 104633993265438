import React from 'react'
import Page from "../../components/global/Page";
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import AddCircle from '@mui/icons-material/AddCircle'
import {Attribute, AttributesApi, ResponseAttributeFromJSON, ResponseIndustryFromJSON} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import {useNavigate} from "react-router-dom";

const AttributesListScreen = () => {

    const attributesApi = new AttributesApi(getGlobalConfig())

    const [attributes, setAttributes] = React.useState<Attribute[]>();

    const [page, setPage] = React.useState(0)

    const [count, setCount] = React.useState(0)

    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)

    const [searchName, setSearchName] = React.useState('')

    const [dataTypeFilter, setDataTypeFilter] = React.useState()

    const navigate = useNavigate();

    const handleSearchFieldChanged = (newValue: any) => {
        setSearchName(newValue);
        fetchAttributes(0, rowsPerPage, newValue, dataTypeFilter)
    }

    const handleDataTypeFilterChanged = (newValue: any) => {
        setDataTypeFilter(newValue)
        fetchAttributes(0, rowsPerPage, searchName, newValue)
    }

    const fetchAttributes = async (page: number = 0, pageRows: number = rowsPerPage, nameFilter?: string, dataFilter?: string[]) => {
        let searchQuery: any = {
            sort: '^name',
            limit: pageRows,
            offset: page * pageRows,
            includeTotals: true
        }
        if (nameFilter || dataFilter) {
            searchQuery['q'] = "{"
            if (nameFilter) searchQuery['q'] += `"name": {"$regex": "${nameFilter}", "$options": "i"}`
            if (nameFilter && dataFilter) searchQuery['q'] += ','
            if (dataFilter) searchQuery['q'] += `"type": {"$in": "${JSON.stringify(dataFilter)}"}`
            searchQuery['q'] += "}"
        }
        const data = await attributesApi.getAttributesRaw(searchQuery)
        const totals = data.raw.headers.get('x-total-count')
        if (totals) {
            let total: number = 0;
            if (totals) total = parseInt(totals)
            setCount(total)
        }
        const rawJson = data.raw.json()
        rawJson.then(json => {
            const data = ResponseAttributeFromJSON(json)
            if (data.data) {
                setAttributes(data.data);
            }
        })
    }

    React.useEffect(() => {
        fetchAttributes();
    }, [])

    return (
        <Page>
            <Container maxWidth={'lg'}>
                <Header
                    title={'Attributes'}
                    breadcrumbs={[
                        {
                            "title": "Home",
                            "path": "/"
                        },
                        {
                            "title": "Attributes",
                            "path": "/attributes"
                        }
                    ]}
                    action={<Button
                        startIcon={<AddCircle/>}
                        onClick={() => { navigate('/attributes/add') }}
                        variant={'contained'}
                        color={'secondary'}>Add Attribute</Button>}
                />
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            label={"Search attributes by name..."}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="google-map-demo"
                            sx={{ width: 300 }}
                            multiple={true}
                            options={['text', 'dropdown', 'range']}
                            autoComplete
                            includeInputInList
                            noOptionsText={'No results found'}
                            isOptionEqualToValue={(option: any, value: any) => option === value}
                            // value={value}
                            onChange={(event: any, newValue: any | null) => {

                            }}
                            onInputChange={(event: any, newInputValue: any) => {
                            }}
                            renderInput={(params) => <TextField {...params} label="Type" />}
                        />
                    </Grid>
                </Grid>
                <br/>
                <TableContainer variant={'outlined'} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Data Type</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attributes && attributes.map(attribute => (
                                <TableRow onClick={() => { navigate(`/attributes/${attribute.pid}`) }} hover key={attribute.pid}>
                                    <TableCell>{attribute.name}</TableCell>
                                    <TableCell>{attribute.type}</TableCell>
                                    <TableCell>{attribute.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => {
                            setPage(newPage)
                            fetchAttributes(newPage);
                        }}
                        onRowsPerPageChange={(e) => {
                            let newRowsPage = parseInt(e.target.value);
                            setRowsPerPage(newRowsPage)
                            setPage(0);
                            fetchAttributes(0, newRowsPage);
                        }}
                    />
                </TableContainer>
            </Container>
        </Page>
    )

}

export default AttributesListScreen;