import React from 'react'
import Page from "../../components/global/Page";
import Container from '@mui/material/Container'
import Header from "../../components/global/Header";
import AttributeForm from "../../components/developer/AttributeForm";
import {Attribute, AttributesApi, HttpErrorFromJSON} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import {useDialog} from "../../components/global/DialogProvider";
import {useNavigate} from "react-router-dom";

const AttributeAddScreen = () => {

    const attributesApi = new AttributesApi(getGlobalConfig());

    const dialogue = useDialog();

    const navigate = useNavigate();

    const handleSubmit = async (newAttribute: Attribute) => {
        try {
            const response = await attributesApi.addAttribute({
                attribute: newAttribute
            })
            if (response && response.pid) {
                dialogue.openConfirm({
                    title: 'Attribute Created Successfully',
                    message: 'The attribute has been successfully created.',
                    confirmText: 'OKAY',
                    onConfirm: () => {
                        navigate('/attributes');
                    }
                })
            }
        } catch (error: any) {

            const er = await error.response.json()
            const errResponse = HttpErrorFromJSON(er);

            dialogue.openConfirm({
                title: 'An Error Occurred',
                message: errResponse ? errResponse.userMessage : "An unknown error occurred. Please wait 5 minutes and re-attempt your request.",
                confirmText: 'OKAY',
                onConfirm: () => {}
            })
        }
    }

    return (<Page>
        <Container maxWidth={'lg'}>
            <Header
                title={'Add Attribute'}
                breadcrumbs={[
                    {
                        title: "Home",
                        path: "/"
                    },
                    {
                        title: "Attributes",
                        path: "/attributes"
                    },
                    {
                        title: "Add Attributes",
                        path: "/attributes/add"
                    }
                ]}
            />
            <br/>
            <AttributeForm onSubmit={handleSubmit}/>
        </Container>
    </Page>)

}

export default AttributeAddScreen;