import React from 'react'
import ProductDirectory from '../../components/employee/ProductDirectory'
import Container from '@mui/material/Container'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'

/**
 * A screen at which users may view the hierarchy of products.
 * @returns {JSX.Element}
 */
const ProductTypeDirectoryScreen = (): JSX.Element => {
  return (
    <View>
        <Container maxWidth='lg'>
            <Header
                    breadcrumbs={[
                        {
                            title: 'Home',
                            path: '/'
                        },
                        {
                            title: 'Product Type Directory',
                            path: '/employee/products/types'
                        },
                    ]}
                    title={"Product Type Directory"}/>
            <br/>
            <ProductDirectory/>
        </Container>
    </View>
  )
}

export default ProductTypeDirectoryScreen