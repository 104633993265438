import React from 'react'
import { Navigate } from 'react-router-dom'
import Authentication from '../../api/Authentication'

/**
 * A ProtectedRoute is a route that is inaccessible without an access token. Various
 * components have methods of validating. You can pass down an array of the privilege 
 * names that are required to access the route.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns 
 */
const ProtectedRoute = (props: ProtectedRouteProps) => {

  return (
    (Authentication.isAuthenticated() && (props.privileges ? props.privileges.includes(Authentication.getPrivileges()) : true)) ?
      props.element : <Navigate to='/' />
  )
}

export interface ProtectedRouteProps {
  element: JSX.Element,
  privileges?: Array<string>,
}

export default ProtectedRoute