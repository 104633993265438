import Container from '@mui/material/Container';
import { GetIndustriesRequest, Industry, IndustriesApi, ResponseIndustryFromJSON, Configuration } from 'ftm-api-client'
import React from 'react'
import { getGlobalConfig} from '../../api/Api';
import View from '../../components/global/Page';
import Header from '../../components/global/Header';
import Button from '@mui/material/Button'
import AddCircle from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Search from '@mui/icons-material/Search'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import InputField from '../../components/global/InputField';

/**
 * Represents a screen allowing the user to view the organizations.
 * @returns {JSX.Element}
 */
const IndustryListScreen = () => {


    const IndustriesApiInstance = new IndustriesApi(getGlobalConfig());

    const [industriesRowsPerPage, setIndustriesRowsPerPage] = React.useState<number>(10)

    const [industries, setIndustries] = React.useState<Industry[]>([])

    const [total, setTotal] = React.useState<number>(0)

    const [page, setPage] = React.useState(0)

    const [searchQuery, setSearchQuery] = React.useState<string>('')

    const navigate = useNavigate();

    const handleSearchQueryChange = (e: any) => {
        setSearchQuery(e.target.value);
    }

    const fetchIndustriesByPage = (page: number = 0, rowsPerPage: number = industriesRowsPerPage, nameFilter?: string) => {
        let query: GetIndustriesRequest = {
            sort: '^name',
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            includeTotals: true
        }
        if (nameFilter) {
            query['q'] = `{"name": {"$regex": "${nameFilter}", "$options": "i"}}`
        }

        IndustriesApiInstance.getIndustriesRaw(query).then((response) => {
            const totals = response.raw.headers.get('x-total-count')
            if (totals) {
                let total: number = 0;
                if (totals) total = parseInt(totals)
                setTotal(total)
            }
            const rawJson = response.raw.json()
            rawJson.then(json => {
                const data = ResponseIndustryFromJSON(json)
                if (data.data) {
                    setIndustries(data.data);
                }
            })
        }, (error: any) => {})
    }

    React.useEffect(() => {
        fetchIndustriesByPage();
    }, [])

    return ( <View>
            <Container maxWidth='lg'>
                <Header
                    title="Industries"
                    breadcrumbs={[
                        {
                            title: 'Home',
                            path: '/'
                        },
                        {
                            title: 'Industries',
                            path: '/industries'
                        }
                    ]}
                    action={
                        <Button variant="contained" color='secondary' startIcon={<AddCircle/>} onClick={() => {navigate('/industries/create')}}>
                            Add Industry
                        </Button>}
                />
                <br/>
                <TableContainer variant={'outlined'} component={Paper}>
                    <CardContent>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                <InputField onKeyPress={((event: any) => { if (event.key === 'Enter') {
                                    fetchIndustriesByPage(0, industriesRowsPerPage, searchQuery)
                                } })} onChange={handleSearchQueryChange} fullWidth placeholder={'Search industries by name...'}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Button startIcon={<Search/>} onClick={() => {fetchIndustriesByPage(0, industriesRowsPerPage, searchQuery)}} variant={'contained'}>Search</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>NAICS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {industries.map(industry => (
                                <TableRow key={industry.pid} hover onClick={() => {navigate(`/industries/${industry.pid}`)}}>
                                    <TableCell style={{minWidth: 300}}>{industry.name}</TableCell>
                                    <TableCell>{industry.naicsCode}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={industriesRowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => {
                            setPage(newPage)
                            fetchIndustriesByPage(newPage);
                        }}
                        onRowsPerPageChange={(e) => {
                            let newRowsPage = parseInt(e.target.value);
                            setIndustriesRowsPerPage(newRowsPage)
                            setPage(0);
                            fetchIndustriesByPage(0, newRowsPage);
                        }}
                    />
                </TableContainer>
            </Container>
        </View>
    )
}

export default IndustryListScreen