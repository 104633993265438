import React from 'react'
import styled from '@mui/material/styles/styled'
import { alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import debounce from '@mui/material/utils/debounce'
import { SearchApiAdapter } from '../../api/impl/adapters'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

/**
 * Gives users the ability to search globally in the app.
 * @returns {JSX.Element}
 */
const SearchBar = (props: SearchBarProps) => {


    /**
     * Initialize an API client instance.
     */
    const apiClient = new SearchApiAdapter();

    /**
     * The maximum number of results retrieveable at a time.
     */
    const queryLimit = 25;

    /**
     * Represents the value currently selected.
     */
    const [value, setValue] = React.useState<any | undefined>(null)

    /**
     * Represents the options available.
     */
    const [options, setOptions] = React.useState<any[]>([]);

    /**
     * The value stored for the user's search query.
     */
    const [inputValue, setInputValue] = React.useState<string>('')

    /**
     * Represents whether data is being loaded from the API or not.
     */
    const [loading, setLoading] = React.useState<boolean>(false);

    /**
     * Memo-izes and throttles the request being sent to the API client to avoid
     * flooding the backend with requests.
     */
    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: any) => void,
                ) => {
                    requestData(request.input)
                },
                400,
            ),
        [],
    );

    /**
     * Send the request to the backend API.
     * @param searchText 
     */
    const requestData = async (searchText: string) => {
        // setLoading(true);
        // const searchField = props.field ? props.field : "name"
        // const data = await apiClient?.find({
        //     q: `{"${searchField}": {"$regex": "${searchText}", "$options": "i"}}`,
        //     limit: queryLimit,
        //     sort: `^${searchField}`
        // })
        // if (data) setOptions(data);
        // else setOptions([]);
        // setLoading(false);
    }

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                disabled={props.disabled || false}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
            />
        </Search>
    )

}

interface SearchBarProps {

    /**
     * Whether or not the SearchBar is disabled.
     */
    disabled?: boolean
}

export default SearchBar;