import React, {useEffect, useState} from "react";
import View from "../../components/global/Page";
import Container from "@mui/material/Container";
import Header from "../../components/global/Header";
import TextField from "@mui/material/TextField";
import {BarChart} from "@mui/x-charts/BarChart";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Gauge, gaugeClasses, LineChart} from "@mui/x-charts";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {FtmTasksApiAdapter} from "../../api/impl/adapters";
import {useSnackbar} from "notistack";
import {FtmTask} from "ftm-api-client";
import InputField from "../../components/global/InputField";
import MenuItem from "@mui/material/MenuItem";

const DashboardScreen = () => {

    const ftmTasksApi = new FtmTasksApiAdapter();

    const { enqueueSnackbar } = useSnackbar();

    const [mostRecentTasks, setMostRecentTasks] = useState<Array<FtmTask>>([]);

    const [lastChecked, setLastChecked] = useState<string>(new Date().toISOString());

    const initDashboard = async () => {
        try {
            const tasks = await ftmTasksApi.findAll(
                {
                    q: '{}',
                    limit: 10,
                    sort: '-createdAt'
                }
            );
            if (tasks) {
                setMostRecentTasks(tasks);
            }
        } catch (error) {
            enqueueSnackbar("Could not initialize dashboard.", {variant: 'error'});
        }
    }

    useEffect(() => {
        initDashboard();
    }, []);

    const data = [
        {
            id: 'data-0',
            x1: 329.39,
            x2: 391.29,
            y1: 443.28,
            y2: 153.9,
        },
        {
            id: 'data-1',
            x1: 96.94,
            x2: 139.6,
            y1: 110.5,
            y2: 217.8,
        }];

    const columns: GridColDef<(FtmTask)>[] = [
        {
            field: 'targetApplication',
            headerName: 'Application Name',
            resizable: true,
            minWidth: 300
        },
        {
            field: 'taskType',
            headerName: 'Task Type',
            resizable: true,
            minWidth: 300
        },
        {
            field: 'taskDescription',
            headerName: 'Description',
            resizable: true,
            minWidth: 300
        }
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    return (
        <View>
            <Container maxWidth={'xl'}>
                <Header
                    title={'Dashboard'}
                    breadcrumbs={[
                        {
                            title: "Home",
                            path: '/'
                        },
                        {
                            title: "Dashboard",
                            path: "/dashboard"
                        }
                    ]}
                />
                <Typography variant={'body2'} color={'textSecondary'}>Last time data received: {lastChecked}</Typography>
                <br/>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card>
                            <CardContent>
                                <BarChart
                                    title={'Tasks Completed by Type'}
                                    height={300}
                                    series={[
                                        { data: [35, 44, 24, 34], label: 'Labelling' },
                                        { data: [51, 6, 49, 30], label: 'Test' },
                                        { data: [15, 25, 30, 50], label: 'Three' },
                                        { data: [60, 50, 15, 25], label: 'Four' },
                                    ]}
                                    xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Card>
                            <CardContent>
                                <BarChart
                                    xAxis={[{ scaleType: 'band', data: ['Number of New Tasks by Type'] }]}
                                    series={[{ data: [4], label: "DatasetReview" }, { data: [1], label: "Test" }, { data: [2], label: "Test2" }]}
                                    height={300}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card>
                            <CardContent>
                                <Gauge
                                    value={75}
                                    startAngle={-110}
                                    endAngle={110}
                                    height={300}
                                    sx={{
                                        [`& .${gaugeClasses.valueText}`]: {
                                            fontSize: 40,
                                            transform: 'translate(0px, 0px)',
                                        },
                                    }}
                                    text={
                                        ({ value, valueMax }) => `${value} / ${valueMax}`
                                    }

                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid mb={2} container justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item>
                                        <Typography fontWeight={'700'} variant="body2" color="text.secondary">
                                            Total Tasks
                                        </Typography>
                                        <Typography variant={'h5'}>
                                            10,000
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            fullWidth
                                            select
                                            defaultValue={'last10'}
                                            name="bugReportType"
                                        >
                                            <MenuItem value={'last10'}>Last 10 days</MenuItem>
                                        </InputField>
                                    </Grid>
                                </Grid>
                                <LineChart
                                    xAxis={[{ data: [1, 2, 3, 4, 5, 6] }]}
                                    series={[
                                        {
                                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                                            label: 'Tasks Completed (Last 10 days)'
                                        },
                                    ]}
                                    grid={{
                                        horizontal: true,
                                        vertical: true
                                    }}
                                    height={300}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <Typography variant={'body2'} mb={2} color={'text.secondary'} fontWeight={'700'}>Recent Tasks</Typography>
                <Paper>
                    <TextField
                        disabled={!mostRecentTasks.length}
                        variant={'outlined'}
                        size={'small'}
                        fullWidth
                        placeholder={'Search tasks...'}
                    />
                </Paper>
                <br/>
                <Card>
                    <DataGrid
                        getRowId={(row) => row.pid!}
                        rows={mostRecentTasks}
                        columns={columns}
                        disableSelectionOnClick
                        autoHeight
                    />
                </Card>
            </Container>
        </View>
    )
}

export default DashboardScreen;