import React, { useEffect } from 'react'
import View from '../../components/global/Page'
import Header from '../../components/global/Header'
import Container from '@mui/material/Container'
import { useLocation } from 'react-router-dom'

/**
 * The EditGalleryScreen is a UI page that allows employees and developers
 * to edit galleries. Provides interactive controls to modify the title,
 * description, and the images in the gallery as well as the users
 * who can view the gallery.
 * @returns {JSX.Element}
 */
const EditGalleryScreen = () => {

  const location = useLocation();

  const [gallery, setGallery] = React.useState<any | null>(null);

  const fetchData = async () => {
  }

  useEffect(() => {
    fetchData();
  }, [])


  return ( <View>
      <Container maxWidth='lg'>
          <Header
            title="Edit Gallery"
            breadcrumbs={[  
                {
                    title: 'Home',
                    path: '/' 
                },
                {
                    title: 'Galleries',
                    path: '/galleries'  
                },
                {
                    title: 'Edit Gallery',
                    path: '/gallery/edit' 
                }
            ]}
          />
          <br/>
      </Container>
  </View>
  )
}


export default EditGalleryScreen