import { createTheme, responsiveFontSizes } from "@mui/material"
import {useState, useEffect} from 'react'
import {darkThemeOptions} from './dark-theme-options'
import {baseThemeOptions} from './base-theme-options'
import {lightThemeOptions} from "./light-theme-options";

/**
 * This theme file makes use of MUI's theming system to provide a consistent
 * styling and look/feel throughout the application.
 * @author chrisrinaldi
 * @since 17 Mar, 2022
 */

/**
 * A hook that modularizes the functionality of the
 * GlobalThemeProvider. You may utilize this hook to
 * toggle the state of the theme.
 * @returns {React.SetStateAction}
 */
 export const useThemeMode = () => {
     
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
      if (theme === 'light') {
        window.localStorage.setItem('theme', 'dark')
        setTheme('dark')
      } else {
        window.localStorage.setItem('theme', 'light')
        setTheme('light')
      }
    };
  
    useEffect(() => {
      const localTheme = window.localStorage.getItem('theme');
      localTheme && setTheme(localTheme);
    }, []);
  
    return [theme, toggleTheme]
  };


// @ts-ignore
const lightTheme = responsiveFontSizes(createTheme(baseThemeOptions, lightThemeOptions))

// @ts-ignore
const darkTheme = responsiveFontSizes(createTheme(baseThemeOptions, darkThemeOptions))


export {
    lightTheme, darkTheme
}