import React from 'react'
import {
    IndustriesApi,
    Organization,
    OrganizationsApi,
    PatchDocumentFromJSON, ResponseIndustryFromJSON,
    ResponseOrganizationFromJSON
} from "ftm-api-client";
import {getGlobalConfig} from "../../api/Api";
import {useNavigate, useParams} from "react-router-dom";
import View from "../../components/global/Page";
import Container from "@mui/material/Container";
import OrganizationForm from "../../components/developer/OrganizationForm";
import {useDialog} from "../../components/global/DialogProvider";
import Header from "../../components/global/Header";

const OrganizationEditScreen = () => {

    const config = getGlobalConfig();

    const organizationApi = new OrganizationsApi(config);

    const industryApi = new IndustriesApi(config)

    const params = useParams();

    const [organization, setOrganization] = React.useState<Organization | undefined>()

    const [industryPidToIndustry, setIndustryPidToIndustry] = React.useState<any>()

    const dialog = useDialog();

    const navigate = useNavigate();

    const fetchOrg = async () => {
        const data = await organizationApi.getOrganization({
            pid: params.organizationPid!
        })
        if (data) {
            const orgResponse = ResponseOrganizationFromJSON(data);
            if (orgResponse && orgResponse.data) {
                setOrganization(orgResponse.data[0])
                const data = await industryApi.getIndustries({
                    sort: '^name'
                })
                if (data) {
                    const industryResponse = ResponseIndustryFromJSON(data);
                    if (industryResponse && industryResponse.data) {
                        let newIndustryPidToIndustry: any = {}
                        industryResponse.data.map(industry => {
                            newIndustryPidToIndustry[industry.pid!] = industry
                        })
                        setIndustryPidToIndustry(newIndustryPidToIndustry)
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        fetchOrg();
    }, [])

    const handleSubmit = async (newOrganization: any) => {

        let patchDocumentList: any = []
        Object.keys(organization!).map(organizationField => {
            if ((organization as any)[organizationField]! !== newOrganization[organizationField] && organizationField !== 'createdAt') {
                patchDocumentList.push(PatchDocumentFromJSON({
                    op: "add",
                    path: `/${organizationField}`,
                    value: newOrganization[organizationField]
                }))
            }
        })

        const data = await organizationApi.patchOrganization({
            pid: params.organizationPid!,
            patchDocument: patchDocumentList
        })

        if (data && data.statusCode === 204) {
            dialog.openConfirm({
                title: "Organization Updated",
                message: "Organization has been successfully updated.",
                onConfirm: () => {
                    navigate('/organizations')
                }
            })
        }
    }

    return (
        <View>
            <Container maxWidth={'lg'}>
                <Header
                    title={'Edit Organization'}
                    breadcrumbs={[
                        {
                            "title": "Home",
                            "path": "/"
                        },
                        {
                            "title": "Organizations",
                            "path": "/organizations"
                        },
                        {
                            "title": "Edit Organization",
                            "path": `/organizations/${params.organizationPid!}`
                        }
                    ]}
                />
                <br/>
                {organization && <OrganizationForm
                    onSubmit={handleSubmit}
                    industryPidToIndustry={industryPidToIndustry}
                    initialOrganization={organization}
                />}
            </Container>
        </View>
    )


}

export default OrganizationEditScreen;