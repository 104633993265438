import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TextField from '@mui/material/TextField'
import TableRow from '@mui/material/TableRow'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Photo from '@mui/icons-material/Photo'
import EditIcon from '@mui/icons-material/Edit'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import MonetizationOn from '@mui/icons-material/MonetizationOn'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { getGlobalConfig } from '../../api/Api'
import InputField from '../../components/global/InputField'
import {UserContactsApiAdapter, UsersApiAdapter} from "../../api/impl/adapters";
import {GetUserContactsRequest, PatchDocumentFromJSON, UserContact} from "ftm-api-client";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Delete from "@mui/icons-material/Delete";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {Undo} from "@mui/icons-material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Represents a screen which allows an employee to manage event types that
 * are available to users.
 * @returns {JSX.Element}
 */
const BugReportAdminScreen = () => {

    /**
     * Stores the result of the contactForms API.
     */
    const [contactForms, setContactForms] = React.useState<Array<any>>([]);

    /**
     * Represents the currently expanded cell.
     */
    const [expandedCell, setExpandedCell] = React.useState<string>();

    /**
     * Represents the rows per page
     */
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);

    /**
     * Represents the current page of the data.
     */
    const [page, setPage] = React.useState<number>(0);

    /**
     * Whether is loading.
     */
    const [loading, setLoading] = React.useState<boolean>(true);

    /**
     * Represents the total count.
     */
    const [totalCount, setTotalCount] = React.useState<number>(0);

    /**
     * Represents the search query.
     */
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    /**
     * Handles filtering for which status to view by.
     */
    const [viewByStatus, setViewByStatus] = React.useState('Pending');

    /**
     * Map of user pid to user.
     */
    const [userPidToUser, setUserPidToUser] = React.useState<any>({});

    /**
     * Mapping of user contact pid to selected.
     */
    const [userContactPidToSelected, setUserContactPidToSelected] = React.useState<any>({});

    /**
     * Represents the snackbar dispatch.
     */
    let { enqueueSnackbar } = useSnackbar();

    /**
     * Contacts API instance.
     */
    const userContactsApi = new UserContactsApiAdapter();

    /**
     * Users API instance.
     */
    const usersApi = new UsersApiAdapter();

    /**
     * Updates the state of user contact pid to whether it is selected.
     * @param pid
     * @param selected
     */
    const handleSelected = (pid: string, selected: boolean = false) => {
        let newUserContactPidToSelected = {...userContactPidToSelected};
        if (
            !selected &&
            pid in newUserContactPidToSelected
        ) {
            delete newUserContactPidToSelected[pid];
        } else {
            newUserContactPidToSelected[pid] = true;
        }
        setUserContactPidToSelected(newUserContactPidToSelected);
    }

    /**
     * Handles selecting all.
     * @param selected
     */
    const handleSelectAll = (selected: boolean = false) => {
        let newUserContactPidToSelected: any = {};
        if (selected) {
            for (const row of contactForms) {
                newUserContactPidToSelected[row.pid] = true;
            }
        }
        setUserContactPidToSelected(newUserContactPidToSelected);
    }

    /**
     * Marks all statuses in userContactPidToSelected to the specified status.
     * @param status
     */
    const handleMarkUpdateStatus = async (status: string) => {
        for (const key of Object.keys(userContactPidToSelected)) {
            const patchDocumentList = [
                PatchDocumentFromJSON(
                    {
                        'op': 'add',
                        'path': '/status',
                        'value': status
                    }
                )
            ]
            await userContactsApi.patch(key, patchDocumentList);
        }
        enqueueSnackbar(`Status of ${numSelected} reports has been updated.`, {variant: 'success'});
        handleReset();
    }

    const handleReset = async () => {
        setUserContactPidToSelected({});
        await fetchUserContacts();
    }

    const handleViewByStatusChange = async (newValue: string) => {
        setViewByStatus(newValue);
        setUserContactPidToSelected({});
        await fetchUserContacts(0, rowsPerPage, "", newValue);
    }

    /**
     * Fetches respective product data as well as the organizations and product types tied to the set of
     * contactForms.
     * @param pageNumber
     * @param rowsPage
     * @param userQuery
     * @param viewByStatus
     */
    const fetchUserContacts = async (pageNumber: number = page, rowsPage: number = rowsPerPage, userQuery: string = searchQuery, status: string = viewByStatus) => {
        let query: GetUserContactsRequest = {
            sort: '-createdAt'
        }
        let queryQ: any = {
            'status': status
        };
        if (userQuery) queryQ['$text'] = {'$search': userQuery};
        query.q = JSON.stringify(queryQ);
        try {
            if (!loading) setLoading(true);
            const [userContacts, total] = await userContactsApi.findByPage(
                query,
                pageNumber,
                rowsPage,
                true
            );

            if (userContacts && total !== null) {
                let userPids = userContacts.map((contact: UserContact) => contact.senderPid);
                const users = await usersApi.findByPids(userPids);
                if (users) {
                    let newUserPidToUser: any = {};
                    for (const user of users) {
                        newUserPidToUser[user.pid] = user;
                    }
                    setUserPidToUser(newUserPidToUser);
                }
                setContactForms(userContacts);
                setTotalCount(total);
            }
            setLoading(false);
        } catch (error) {
            enqueueSnackbar('The server was unable to process your request at this time. Please try again later.');
            setLoading(false);
        }
    }

    /**
     * Represents the react-router-dom navigation hook.
     */
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserContacts();
    }, []);

    const numSelected = Object.keys(userContactPidToSelected).length;

    const statusColor: any = {
        'Resolved': 'success',
        'Archived': 'error',
        'Pending': 'warning'
    }

    return (<View>
            <Container maxWidth='xl'>
                <Header title='Reports Admin Center'
                        action={
                            <Stack direction={'row'} spacing={2}>
                                <LoadingButton
                                    loading={loading}
                                    disabled={!numSelected}
                                    onClick={() => {
                                        handleMarkUpdateStatus('Resolved');
                                    }}
                                    startIcon={<CheckCircle/>} variant={'contained'}>Mark Resolved{numSelected ? ` (${numSelected})` : ''}</LoadingButton>
                                <LoadingButton
                                    loading={loading}
                                    disabled={!numSelected}
                                    onClick={() => {
                                        handleMarkUpdateStatus('Archived');
                                    }}
                                    startIcon={<Delete/>} variant={'contained'}>Archive{numSelected ? ` (${numSelected})` : ''}</LoadingButton>
                                <LoadingButton
                                    loading={loading}
                                    disabled={!numSelected}
                                    onClick={() => {
                                        handleMarkUpdateStatus('Pending');
                                    }}
                                    startIcon={<Undo/>} variant={'contained'}>Revert Pending{numSelected ? ` (${numSelected})` : ''}</LoadingButton>
                            </Stack>
                        }
                        breadcrumbs={[
                            {
                                title: 'Home',
                                path: '/'
                            },
                            {
                                title: 'Bug Reports Admin',
                                path: '/bug-report/admin'
                            }
                        ]} />
                <br/>
                <Stack direction={'row'} spacing={2} mb={3}>
                    <InputField
                        select
                        disabled={loading}
                        onChange={(event: any) => {
                            handleViewByStatusChange(event.target.value)
                        }}
                        defaultValue={'Pending'}
                        name="viewType"
                    >
                        <MenuItem
                            key="Pending"
                            value="Pending"
                        >Select status to view by</MenuItem>
                        <MenuItem
                            key={'Resolved'}
                            value={'Resolved'}
                        >
                            {'Show Resolved'}
                        </MenuItem>
                        <MenuItem
                            key={'Archived'}
                            value={'Archived'}
                        >
                            {'Show Archived'}
                        </MenuItem>
                    </InputField>
                </Stack>
                <TableContainer component={Paper} variant='outlined'>
                    <CardContent>
                        <InputField size='small' onKeyPress={(e: any) => {

                            if (e.key === 'Enter') {
                                setPage(0);
                            }

                        }} onChange={(e: any) => {
                            setSearchQuery(e.target.value)
                            fetchUserContacts(page, rowsPerPage, e.target.value)
                        }} InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon/></InputAdornment>
                        }} placeholder='Search for reports...' fullWidth/>
                    </CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {/*<Checkbox*/}
                                    {/*    size={'small'}*/}
                                    {/*    value={!!(numSelected && numSelected === contactForms.length)}*/}
                                    {/*    checked={!!(numSelected && numSelected === contactForms.length)}*/}
                                    {/*    onChange={(event, checked) => {*/}
                                    {/*        handleSelectAll(checked);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Sender</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box my={15} textAlign={'center'}><CircularProgress/>
                                        </Box>
                                    </TableCell>
                                </TableRow> : contactForms && contactForms.length > 0 ? contactForms.map(userContact => (
                                    <>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    size={'small'}
                                                    checked={userContactPidToSelected[userContact.pid]}
                                                    value={userContactPidToSelected[userContact.pid]}
                                                    onChange={(event, checked) => {
                                                        handleSelected(userContact.pid, checked);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {userContact.subject}
                                            </TableCell>
                                            <TableCell>{userContact.senderPid in userPidToUser ? <Link
                                                href={`mailto:${userPidToUser[userContact.senderPid].email}`}>{userPidToUser[userContact.senderPid].email}</Link> : 'Unknown Sender'}</TableCell>
                                            <TableCell>
                                                {userContact.issueType}
                                            </TableCell>
                                            <TableCell>
                                                <Chip size='small' color={statusColor[userContact.status]}
                                                      label={userContact.status}/>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Tooltip
                                                    title={expandedCell === userContact._id ? 'Hide details' : 'View details'}>
                                                    <IconButton size='small' onClick={() => {
                                                        setExpandedCell(expandedCell === userContact.pid ? '' : userContact.pid)
                                                    }}>
                                                        {expandedCell === userContact.pid ? <ExpandLessIcon/> :
                                                            <ExpandMoreIcon/>}
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                                                <Collapse in={expandedCell === userContact.pid} timeout="auto"
                                                          unmountOnExit>
                                                    <Box sx={{margin: 5}}>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            Report Details
                                                        </Typography>
                                                        <Typography>{userContact.message}</Typography>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )) : <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box my={15} textAlign={'center'}>
                                            <Typography variant={'h5'}>No Reports Found</Typography>
                                            <Typography variant={'body2'} color={'text.secondary'}>Congratulations - no
                                                bug reports have been found! Please check back later.</Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                        </TableFooter>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => {
                            setPage(newPage)
                            fetchUserContacts(newPage, rowsPerPage, searchQuery)
                        }}
                        onRowsPerPageChange={(e) => {
                            const newRowsPage = parseInt(e.target.value);
                            setRowsPerPage(newRowsPage)
                            setPage(0);
                            fetchUserContacts(0, newRowsPage, searchQuery);
                        }}
                    />
                </TableContainer>
            </Container>
        </View>
    )
}


export default BugReportAdminScreen;