import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import Header from '../../components/global/Header'
import React, {useState} from 'react'
import View from '../../components/global/Page'
import { useNavigate } from 'react-router-dom'
import Close from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {Configuration, HttpErrorFromJSON, ResponseUserProfileFromJSON, UsersApi} from 'ftm-api-client'
import { defaultConfig, globalConfiguration } from '../../api/Api'
import CardActions from '@mui/material/CardActions'
import {initProfile} from "../../application/redux/slices/users-profile";
import { useAppDispatch } from '../../application/redux/hooks'
import logo from '../../logo-dark.png'
import Config from '../../application/util/Configuration'
import SsoLoginButton from '../../components/global/SsoLoginButton'

/**
 * The LoginScreen is the entry point to the application and is where
 * users start the interaction with the application.
 * @returns {JSX.Element}
 */
const LoginScreen = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState('Enter your email and password.');

  /**
   * Represents whether we are awaiting a response from the FTM cloud.
   */
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

  const navigate = useNavigate()

  const dispatch = useAppDispatch();

  const userApi = new UsersApi(defaultConfig);

  /**
   * Handles action taken when the login button is clicked.
   * @returns 
   */
  const handleLoginClicked = async () => {    

    if (email === '' || password === '') return;

    setAwaitingResponse(true);

    userApi.loginUser({
      "userSignIn": {
        "email": email,
        "password": password
      }
    }).then((result) => {
      if (result) {
        localStorage.setItem('token', result.accessToken)
        initProfile(dispatch);
        navigate('/');
      }
    }, async (error) => {
      try {
        const errorJson = await error.response.json()
        setAwaitingResponse(false);
        setAlertMessage(HttpErrorFromJSON(errorJson).userMessage)
        setAlertOpen(true); 
      } catch (error) {
        setAlertMessage("Unable to process your request at this time. Please try again later.")
        setAlertOpen(true);
        setAwaitingResponse(false);
      }
    })

  }

  /**
   * Handles value change for the specified field.
   * @param field the field to change
   * @param event the event
   */
  const handleChange = (field: string, event: any) => {
    if (field === 'email') setEmail(event.target.value)
    else if (field === 'password') setPassword(event.target.value)
  }

  return (
    <View>
      <Container maxWidth='sm'>
        <br/>
        <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <img src={logo} height={45} />
          </div>
          <Typography align='center' color={'text.secondary'} variant='h3'>Analytix</Typography>
          <Typography align='center' color={'textSecondary'} variant='subtitle1'>Data Governance Portal</Typography>
          <br/>
          <Collapse in={alertOpen}>
          <Alert
          severity='error'
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}>
            {alertMessage}
          </Alert>
        </Collapse>
        {Config.AuthenticationMethod === 'azure' ? 
            <SsoLoginButton/> :
            <Card variant='outlined'>
            <CardContent>
                <Typography variant='body2' align='center' color='textSecondary'>Please enter your email and password.</Typography>
                <br/>
                <FormControl sx={{width: '100%'}}>
                <TextField style={{marginBottom: 25}} value={email} onInput={e => handleChange('email', e)} placeholder='Email address'/>
                <TextField value={password} onInput={e => handleChange('password', e)} placeholder='Password' type='password'/>
                <br/>
              </FormControl>
              <LoadingButton 
                  fullWidth
                  loading={awaitingResponse}
                  variant='contained' 
                  onClick={handleLoginClicked}>
                  LOGIN
                  </LoadingButton>
            </CardContent>
          </Card>
        }
      </Container>
    </View>
  )
  
}

export default LoginScreen