import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from '../components/global/Home'
import ProtectedRoute from '../components/global/ProtectedRoute'
import CreateClientScreen from './employee/CreateClientScreen'
import CreateGalleryScreen from './employee/CreateGalleryScreen'
import CreatePackageScreen from './employee/CreatePackageScreen'
import EditClientScreen from './employee/EditClientScreen'
import EditEventTypeScreen from './employee/EditEventTypeScreen'
import EditGalleryScreen from './employee/EditGalleryScreen'
import UserListScreen from './employee/UserListScreen'
import ManageProductsScreen from './employee/ManageProductsScreen'
import OrganizationsScreen from './employee/OrganizationsScreen'
import UserProfileScreen from './employee/UserProfileScreen'
import LoginScreen from './user/LoginScreen'
import GalleriesScreen from './developer/GalleriesScreen'
import UserGalleryViewScreen from './user/UserGalleryViewScreen'
import UserHomeScreen from './user/UserHomeScreen'
import UserSettingsScreen from './user/UserSettingsScreen'
import OrganizationDetailScreen from "./employee/OrganizationDetailScreen";
import IndustryScreen from "./developer/IndustryListScreen";
import IndustryAddScreen from "./developer/IndustryAddScreen";
import IndustryEditScreen from "./developer/IndustryEditScreen";
import IndustryDetailScreen from "./developer/IndustryDetailScreen";
import OrganizationAddScreen from './developer/OrganizationAddScreen'
import OrganizationEditScreen from "./developer/OrganizationEditScreen";
import CategoriesScreen from "./developer/CategoriesScreen";
import CategoryAddScreen from "./developer/CategoryAddScreen";
import AttributesListScreen from "./developer/AttributesListScreen";
import AttributeAddScreen from "./developer/AttributeAddScreen";
import AttributeScreen from "./developer/AttributeScreen";
import ProductTypeAddScreen from "./developer/ProductTypeAddScreen";
import AttributeEditScreen from './developer/AttributeEditScreen'
import ProductTypeDirectoryScreen from './employee/ProductTypeDirectoryScreen'
import ProductSearchScreen from './employee/ProductSearchScreen'
import NotFoundScreen from './user/NotFoundScreen'
import ContactUsScreen from './user/ContactUsScreen'
import ProductTypeEditScreen from './developer/ProductTypeEditScreen'
import InitializationScreen from './user/InitializationScreen'
import DashboardScreen from "./user/DashboardScreen";
import DataQueueScreen from "./developer/DataQueueScreen";
import BugReportAdminScreen from "./developer/BugReportAdminScreen";

/**
 * Represents the index route patch for the react router.
 * @author chrisrinaldi
 * @since 20 March, 2022
 * @returns {JSX.Element}
 */
const RoutesIndex = () => {
    return (
        <Routes>

            {/* LOGIN/HOME SCREEN */}
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/' element={<Home />} />
            <Route path='/redirect-sso' element={<InitializationScreen />} />

            {/* USER ROUTES */}
            <Route path='/settings' element={<ProtectedRoute element={<UserSettingsScreen />} />} />
            <Route path='/galleries' element={<ProtectedRoute element={<GalleriesScreen />} privileges={['user', 'employee', 'developer']} />} />
            <Route path='/galleries/:id' element={<ProtectedRoute element={<UserGalleryViewScreen />} privileges={['user', 'employee', 'developer']} />} />
            <Route path='/products/search' element={<ProtectedRoute element={<ProductSearchScreen />} privileges={['user', 'employee', 'developer']} />} />
            <Route path='/bug-report' element={<ProtectedRoute element={<ContactUsScreen />} />} />
            <Route path='/bug-report/admin' element={<ProtectedRoute element={<BugReportAdminScreen />} />} />
            <Route path='/dashboard' element={<ProtectedRoute element={<DashboardScreen />} privileges={['user', 'employee', 'developer', 'reviewer']} />} />

            {/* EMPLOYEE ROUTES */}
            <Route path="/employee/clients" element={<ProtectedRoute element={<UserListScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/clients/:id" element={<ProtectedRoute element={<EditClientScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/client/add" element={<ProtectedRoute element={<CreateClientScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/client/:id" element={<ProtectedRoute element={<UserProfileScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/galleries/:id" element={<ProtectedRoute element={<EditGalleryScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/gallery/create" element={<ProtectedRoute element={<CreateGalleryScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/products" element={<ProtectedRoute element={<ManageProductsScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/products/create" element={<ProtectedRoute element={<CreatePackageScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/products/:id" element={<ProtectedRoute element={<EditEventTypeScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/employee/products/types" element={<ProtectedRoute element={<ProductTypeDirectoryScreen />} privileges={['employee', 'developer']} />} />

            {/* DEVELOPER ROUTES */}
            <Route path="/organizations" element={<ProtectedRoute element={<OrganizationsScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/industries" element={<ProtectedRoute element={<IndustryScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/industries/create" element={<ProtectedRoute element={<IndustryAddScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/industries/:industryPid" element={<ProtectedRoute element={<IndustryDetailScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/industries/:industryPid/edit" element={<ProtectedRoute element={<IndustryEditScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/organizations/:organizationPid" element={<ProtectedRoute element={<OrganizationDetailScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/organizations/:organizationPid/edit" element={<ProtectedRoute element={<OrganizationEditScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/organizations/create" element={<ProtectedRoute element={<OrganizationAddScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/categories" element={<ProtectedRoute element={<CategoriesScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/categories/add" element={<ProtectedRoute element={<CategoryAddScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/product_types/add" element={<ProtectedRoute element={<ProductTypeAddScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/product_types/:productTypePid/edit" element={<ProtectedRoute element={<ProductTypeEditScreen />} privileges={['developer']} />} />
            <Route path="/attributes" element={<ProtectedRoute element={<AttributesListScreen />} privileges={['developer']} />} />
            <Route path="/attributes/add" element={<ProtectedRoute element={<AttributeAddScreen />} privileges={['developer']} />} />
            <Route path="/attributes/:pid" element={<ProtectedRoute element={<AttributeScreen />} privileges={['employee', 'developer']} />} />
            <Route path="/attributes/:pid/edit" element={<ProtectedRoute element={<AttributeEditScreen />} privileges={['developer']} />} />
            <Route path="/queue" element={<ProtectedRoute element={<DataQueueScreen />} privileges={['developer', 'reviewer']} />} />

            {/* DEFAULT ROUTING */}
            <Route path="*" element={<NotFoundScreen/>} />

        </Routes>
    )
}

export default RoutesIndex