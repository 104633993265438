import AddCircle from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TextField from '@mui/material/TextField'
import TableRow from '@mui/material/TableRow'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Header from '../../components/global/Header'
import View from '../../components/global/Page'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Photo from '@mui/icons-material/Photo'
import EditIcon from '@mui/icons-material/Edit'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import MonetizationOn from '@mui/icons-material/MonetizationOn'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { GetProductsRequest, Organization, OrganizationsApi, Product, ProductsApi, ProductType, ProductTypesApi, ResponseOrganizationFromJSON, ResponseProductFromJSON, ResponseProductTypeFromJSON } from 'ftm-api-client'
import { getGlobalConfig } from '../../api/Api'
import { createPidFilterChunks } from '../../application/util/utils'
import InputField from '../../components/global/InputField'

/**
 * Represents a screen which allows an employee to manage event types that
 * are available to users.
 * @returns {JSX.Element}
 */
const ManageProductsScreen = () => {

    /**
     * Stores the result of the products API.
     */
    const [products, setProducts] = React.useState<Array<any>>([]);

    /**
     * Represents the currently expanded cell.
     */
    const [expandedCell, setExpandedCell] = React.useState<string>();

    /**
     * Represents the rows per page
     */
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);

    /**
     * Represents the current page of the data.
     */
    const [page, setPage] = React.useState<number>(0);

    /**
     * Represents the total count.
     */
    const [totalCount, setTotalCount] = React.useState<number>(0);

    /**
     * Represents the search query.
     */
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const [organizationPidToOrganization, setOrganizationPidToOrganization] = React.useState<any>({});

    const [productTypePidToProductType, setProductTypePidToProductType] = React.useState<any>({});

    /**
     * Represents the snackbar dispatch.
     */
    let { enqueueSnackbar } = useSnackbar();

    const config = getGlobalConfig();

    const productsApi = new ProductsApi(config);

    const organizationsApi = new OrganizationsApi(config);

    const productTypesApi = new ProductTypesApi(config);

    /**
     * Fetches respective product data as well as the organizations and product types tied to the set of
     * products.
     * @param pageNumber 
     * @param rowsPage 
     * @param searchQuery 
     */
    const fetchProducts = async (pageNumber: number = page, rowsPage: number = rowsPerPage, searchQuery?: string) => {
        let query: GetProductsRequest = {
            offset: pageNumber * rowsPage,
            limit: rowsPage,
            sort: '^name',
            includeTotals: true
        }
        if (searchQuery) query.q = `{"name": {"$regex": "${searchQuery}", "$options": "i"}}`;
        try {
            const response = await productsApi.getProductsRaw(query);
            const totalHeader = response.raw.headers.get('x-total-count')
            if (response && totalHeader) {
                const totals = parseInt(totalHeader);
                const rawJson = response.raw.json()
                rawJson.then(async (data) => {
                    const productList = ResponseProductFromJSON(data);
                    if (productList.data && !isNaN(totals)) {
                        let organizationPids: any[] = [], productTypePids: any[] = [];
                        productList.data.map((product: Product) => {
                            organizationPids.push(product.organizationPid);
                            productTypePids.push(product.productTypePid);
                        })
                        const organizationResponse = await organizationsApi.getOrganizations({
                            q: createPidFilterChunks(organizationPids)[0]
                        });
                        if (organizationResponse) {
                            const organizationList = ResponseOrganizationFromJSON(organizationResponse);
                            if (organizationList && organizationList.data) {
                                let newOrgPidToOrg: any = {};
                                organizationList.data.map((organization: Organization) => {
                                    newOrgPidToOrg[organization.pid!] = organization;
                                })
                                setOrganizationPidToOrganization(newOrgPidToOrg);
                                const productTypeResponse = await productTypesApi.getProductTypes({
                                    q: createPidFilterChunks(productTypePids)[0]
                                })
                                if (productTypeResponse) {
                                    const productTypeList = ResponseProductTypeFromJSON(productTypeResponse);
                                    if (productTypeResponse && productTypeResponse.data) {
                                        let newProdTypePidToProdType: any = {};
                                        productTypeResponse.data.map((productType: ProductType) => {
                                            newProdTypePidToProdType[productType.pid!] = productType;
                                        })
                                        setProductTypePidToProductType(newProdTypePidToProdType);
                                        setTotalCount(totals);
                                        setProducts(productList.data);
                                    }
                                }
                            }
                        }
                    }
                })
            }
        } catch (error) {
            enqueueSnackbar('The server was unable to process your request at this time. Please try again later.')
        }
    }

    /**
     * Represents the react-router-dom navigation hook.
     */
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
    }, [])

    return (<View>
        <Container maxWidth='xl'>
            <Header title='Products'
                action={<Button onClick={() => { navigate(`/employee/products/create`) }} variant='contained' startIcon={<AddCircle />}>New Product</Button>}
                breadcrumbs={[
                    {
                        title: 'Home',
                        path: '/'
                    },
                    {
                        title: 'Manage Products',
                        path: '/employee/packages'
                    }
                ]} />
            <br/>
            <br/>
            <TableContainer component={Paper} variant='outlined'>
                <CardContent>
                    <InputField size='small' onKeyPress={(e: any) => {

                        if (e.key === 'Enter') {
                            setPage(0);
                        }

                    }} onChange={(e: any) => {
                        setSearchQuery(e.target.value)
                        fetchProducts(page, rowsPerPage, e.target.value)
                    }} InputProps={{startAdornment: <InputAdornment position='start'><SearchIcon/></InputAdornment>}} placeholder='Search for product by name...' fullWidth/>
                </CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Image</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Type</TableCell>
                            <TableCell>Organization</TableCell>
                            <TableCell>Options</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            products && products.length > 0 && products.map(pkg => (
                                <>
                                    <TableRow hover>
                                        <TableCell align='center'>
                                            <Tooltip title={expandedCell === pkg._id ? 'Hide details' : 'View details'}>
                                                <IconButton size='small' onClick={() => { setExpandedCell(expandedCell === pkg.pid ? '' : pkg.pid) }}>
                                                    {expandedCell === pkg.pid ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Avatar src={pkg.imgUrl} style={{ width: '75px', height: '75px' }} variant='rounded'>
                                                <Photo fontSize='large' />
                                            </Avatar>
                                        </TableCell>
                                        <TableCell>{pkg.name}</TableCell>
                                        <TableCell>
                                            <Chip size='small' icon={<MonetizationOn />} color='secondary' label={pkg.productTypePid in productTypePidToProductType && productTypePidToProductType[pkg.productTypePid].name} />
                                        </TableCell>
                                        <TableCell>{pkg.organizationPid in organizationPidToOrganization && organizationPidToOrganization[pkg.organizationPid].name}</TableCell>
                                        <TableCell>
                                            <Button size='small' variant='contained' color='primary' onClick={() => { navigate(`/employee/packages/${pkg._id}`) }} startIcon={<EditIcon />}>EDIT</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                            <Collapse in={expandedCell === pkg.pid} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Card variant='outlined'>
                                                        <CardContent>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                                Product Details
                                                            </Typography>
                                                            <Typography>Description</Typography>
                                                            <Typography variant='body2' color='textSecondary'>{pkg.description ? pkg.description : 'No description provided.'}</Typography>
                                                            <br/>
                                                            Tags: {' '}
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))
                        }
                    </TableBody>
                    <TableFooter>
                    </TableFooter>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => { 
                        setPage(newPage)
                        fetchProducts(newPage, rowsPerPage, searchQuery) 
                    }}
                    onRowsPerPageChange={(e) => { 
                        const newRowsPage = parseInt(e.target.value);
                        setRowsPerPage(newRowsPage) 
                        setPage(0);
                        fetchProducts(0, newRowsPage, searchQuery);
                    }}
                />
            </TableContainer>
        </Container>
    </View>
    )
}


export default ManageProductsScreen